import gql from 'graphql-tag';

export const getPlanById = gql`
  query getPlanById($id: Int!){
    getPlanById(id: $id){
      id
      name
      price
      formatted_price
      disc
      active
      default
      is_promotion
      period
    }
  }
`;
