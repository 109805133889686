
import _ from 'lodash';
import configDefault from './config';
// import configLocal from './local';

let configLocal;
try {
  configLocal = require('./local.js').default;
} catch (e) {
  configLocal = {}
}
const config = _.merge(configDefault, configLocal);

export default config;
