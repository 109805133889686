import gql from 'graphql-tag';

export const getDetailComsumerById = gql`
  query getDetailComsumerById($id: Int!){
    getDetailComsumerById(id: $id){
      id
      avatar
      username
      email
      birthday
      parrainerCode
      location
      confirmed
      phoneNumber
      password
      firstName
      lastName
      wallet{
          id
          balance
          creditLimit
      }
    }
  }
`;
