import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
import { getStoreByAdmin } from './queries';
import { Slide } from 'react-slideshow-image';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Typography from '@material-ui/core/Typography';
import CategoryIcon from '@material-ui/icons/Category';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ListItemText from '@material-ui/core/ListItemText';
// core components
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import styles from './style';

class DetailStore extends Component {
  constructor(props) {
    super(props);
    const { detailStore } = this.props;
    this.state = {
      detailStore,
      openHour: undefined,
      closeHour: undefined
    };
  }

  componentWillReceiveProps = nextProps => {
    const { detailStore } = this.props;
    if (nextProps.detailStore !== detailStore) {
      this.setState({ detailStore: nextProps.detailStore });
    }
  };

  renderCategories = categories => {
    if (categories.length > 1) {
      categories.map(category => {
        return <Typography style={{ color: 'black' }}>{category}</Typography>;
      });
    } else
      return (
        <Typography style={{ color: 'black' }}>No Category Existing</Typography>
      );
  };
  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const { detailStore } = this.state;
    const {
      name,
      phone,
      location,
      openHour,
      closeHour,
      imagesStore,
      email,
      categories
    } = detailStore;
    const openHourDisplay = moment(openHour, 'h:mm A').format('HH:mm');
    const closeHourDisplay = moment(closeHour, 'h:mm A').format('HH:mm');
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true
    };
    return (
      <React.Fragment>
        <GridContainer justify="center">
          <GridItem xs={20} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                    {imagesStore ? (
                      <GridItem xs={12} sm={12} md={12}>
                        {imagesStore.length > 0 ? (
                          <Slide {...properties}>
                            {imagesStore.map((item, index) => (
                              <div className={classes.itemSlide} key={index}>
                                <img
                                  className={classes.img}
                                  src={item.full}
                                  alt={`${index}`}
                                />
                              </div>
                            ))}
                          </Slide>
                        ) : (
                          <Typography className={classes.textVisited}>
                            Not exit Image Of Store
                          </Typography>
                        )}
                      </GridItem>
                    ) : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                    <List>
                      <ListItem>
                        <Typography component="p" className={classes.textTitle}>
                          {name}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography style={{ color: 'black' }}>
                              {location}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <ContactPhoneIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography style={{ color: 'black' }}>
                              {phone}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <ContactMailIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography style={{ color: 'black' }}>
                              {email}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography style={{ color: 'black' }}>
                              {`${openHourDisplay} - ${closeHourDisplay}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemIcon>
                          <CategoryIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={this.renderCategories(categories)}
                        />
                      </ListItem>
                    </List>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </React.Fragment>
    );
  }
}

// Mark: default props
DetailStore.defaultProps = {
  classes: {},
  detailStore: {}
};

// Mark: PropsType
DetailStore.propTypes = {
  classes: PropTypes.object,
  detailStore: PropTypes.object
};

const DetailStoreById = graphql(getStoreByAdmin, {
  props: ({ data }) => ({
    loading: data.loading,
    detailStore: data.getStoreByAdmin
  }),
  options: props => {
    return {
      variables: { id: Number(props.match.params.id) },
      fetchPolicy: 'cache-and-network'
    };
  }
});

//const Update = graphql(updateStoreByAdmin, { name: 'updateStoreByAdmin' });

export default withStyles(styles)(
  compose(
    DetailStoreById
    // Update
  )(DetailStore)
);
