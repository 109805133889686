/* eslint-disable */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { MdStarHalf } from "react-icons/md";
import { FaSignOutAlt } from "react-icons/fa";
// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx';


import sidebarStyle from '../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx';

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }

  function handleLogOut() {
    localStorage.removeItem('token');
  };

  const { classes, color, logo, image, logoText, routes, onOpenSub , openSub} = props;
  const links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null;
        let activePro = ' ';
        let listItemClasses;
        let open = openSub == prop.sidebarName;
        const subComponent = prop.subComponent;
        if (prop.path === '/upgrade-to-pro') {
          activePro = classes.activePro + ' ';
          listItemClasses = classNames({
            [' ' + classes[color]]: true
          });
        } else {
          listItemClasses = classNames({
            [' ' + classes[color]]: activeRoute(prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.path)
        });
        return (
          <div key={key}>
            {
              !subComponent ? 
              <NavLink
                to={prop.path}
                className={activePro + classes.item}
                activeClassName='active'
              >
                <ListItem button className={classes.itemLink + listItemClasses}>
                  <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                    {typeof prop.icon === 'string' ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.sidebarName}
                    className={classes.itemText + whiteFontClasses}
                    disableTypography={true}
                  />
                  {subComponent && open ? <ExpandLess /> : subComponent ? <ExpandMore/> : null }
                </ListItem>
              </NavLink> :
              <ListItem button className={classes.itemLink + listItemClasses} onClick={() => onOpenSub(prop.sidebarName)} >
                <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                  {typeof prop.icon === 'string' ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.sidebarName}
                  className={classes.itemText + whiteFontClasses}
                  disableTypography={true}
                />
                {subComponent && open ? <ExpandLess /> : subComponent ? <ExpandMore/> : null }
              </ListItem>
            }
            
            <Collapse in={open} timeout="auto" unmountOnExit>
              {
                subComponent ? 
                subComponent.map((propSub, keySub) => {
                  if (propSub.redirect) return null;
                  var activePro = ' ';
                  var listItemClasses;
                  if (propSub.path === '/upgrade-to-pro') {
                    activePro = classes.activePro + ' ';
                    listItemClasses = classNames({
                      [' ' + classes[color]]: true
                    });
                  } else {
                    listItemClasses = classNames({
                      [' ' + classes[color]]: activeRoute(propSub.path)
                    });
                  }
                  const whiteFontClasses = classNames({
                    [' ' + classes.whiteFont]: activeRoute(propSub.path)
                  });
                  return (                     
                        <NavLink
                          to={propSub.path}
                          className={activePro + classes.item}
                          activeClassName='active'
                          key={keySub}
                        >
                          <List component="div" disablePadding className={classes.itemLink + listItemClasses}>
                            <ListItem button className={classes.itemIcon + whiteFontClasses}>
                              <ListItemIcon>
                                <MdStarHalf color='#ffffff' size={13} />
                              </ListItemIcon>
                              <ListItemText 
                                inset 
                                primary={propSub.sidebarName} 
                                className={classes.itemText + whiteFontClasses}
                                disableTypography={true}
                              />
                            </ListItem>
                          </List>
                        </NavLink>                     
                  );
                }): null
              }
            </Collapse>
          </div>
        );
      })}
      <NavLink
        to={'/login'}
        className={classes.activePro + classes.item}
        activeClassName='active'
      >
        <List component="div" disablePadding className={classes.itemLink + classes.color} onClick={() => handleLogOut()} >
          <ListItem button className={classes.itemLogout}>
            <ListItemIcon>
              <FaSignOutAlt color='#ffffff' size={28} />
            </ListItemIcon>
            <ListItemText 
              inset 
              primary={'Logout'} 
              className={classes.textLogout}
              disableTypography={true}
            />
          </ListItem>
        </List>
      </NavLink>       
    </List>
  );
  const brand = (
    <div className={classes.logo}>
      <a href='https://opentechiz.com' className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={logo} alt='logo' className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          anchor='left'
          variant='permanent'
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
