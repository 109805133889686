import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function AlertDialog(props) { 
  return (
    <div>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.content}
          </DialogContentText>
          {props.listChildren}
        </DialogContent>
        <DialogActions>        
          {props.onCancel && 
            <Button onClick={props.onCancel} color="secondary" autoFocus>
              {props.textCancelButton ? props.textCancelButton : 'CANCEL'}
            </Button>
          }
          <Button onClick={props.onOk} color="primary" autoFocus>
            {props.textOkButton ? props.textOkButton : 'OK'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
