// Import Modules
import React from 'react';
import ReactSVG from 'react-svg';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

// import material
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import CardFooter from '../../components/Card/CardFooter';
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle';
import GroupIcon from '../../assets/img/attendance.svg';
// import in app
import { getAllOffersByAdmin } from '../Offer/TableOffer/queries';
import { Spiner } from '../../components';
import { listAllUsers } from '../User/Merchant/TableUser/queries';
import { getAllConsumers } from '../User/Consumer/TableConsumer/queries';
import { getAllWishlistsByAdmin, getAllDeals } from './queries';
import {
  IconDolla, IconCoin, IconOffer, IconCloseDeal,
} from '../../components/Icon/Icon';
import { ErrorBoundaries } from '../../highOderComponents';

class Dashboard extends React.Component {
  // -----------Mark: render users--------
  // Number of active merchants
  renderUser = () => {
    const { classes, loadingUser, listAllUsers } = this.props;

    let listData = listAllUsers || [];
    listData = _.filter(listAllUsers, item => item.active || false);
    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <ReactSVG
                  svgStyle={{
                    width: '50px',
                    height: '50px',
                  }}
                  src={GroupIcon}
                />
              </CardIcon>
              <p className={classes.cardCategory}>Merchant Active</p>
              {!loadingUser ? <Typography gutterBottom component="h2">Quantity: {listData.length}</Typography> : <Spiner />}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }

  // Number of active consumers
  renderConsumers = () => {
    const { classes, loadingConsumer, allConsumers } = this.props;

    const listData = allConsumers || [];
    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <ReactSVG
                  svgStyle={{
                    width: '50px',
                    height: '50px',
                  }}
                  src={GroupIcon}
                />
              </CardIcon>
              <p className={classes.cardCategory}>Consumer</p>
              {!loadingConsumer ? <Typography gutterBottom component="h2">Quantity: {listData.length}</Typography> : <Spiner />}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }

  // Amount of DealCoins in the wallet of all consumers
  renderAmountDealCoinOfAllConsumers = () => {
    const { classes, loadingConsumer, allConsumers } = this.props;
    let amountDealCoinOfConsumer = 0;

    const listData = allConsumers || [];
    _.forEach(listData, item => amountDealCoinOfConsumer += (item.wallet.reservationOnly + item.wallet.earnedDealCoin));

    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="warning">
                <IconCoin />
              </CardIcon>
              <p className={classes.cardCategory}>Amount All Wallet Of Consumers</p>
              {!loadingConsumer ? <Typography gutterBottom component="h2">Amount Of DealCoins: {amountDealCoinOfConsumer}</Typography> : <Spiner />}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }

  // Amount of DealCoins owed to CloseDeal by Merchants
  renderAmountOfDealConisOwedToClosedealByMerchants = () => {
    const { classes, loadingUser, listAllUsers } = this.props;
    let amountDealcoinOwedMerchantActive = 0;
    let amountDealcoinOwedMerchantInactive = 0;

    const listData = listAllUsers || [];
    _.forEach(listData, (item) => { if (item.wallet.balance < 0 && item.active) amountDealcoinOwedMerchantActive += Number(item.wallet.balance); });
    _.forEach(listData, (item) => { if (item.wallet.balance < 0 && !item.active) amountDealcoinOwedMerchantInactive += Number(item.wallet.balance); });

    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={5}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="warning">
                <IconCoin />
              </CardIcon>
              <p className={classes.cardCategory}>Amount Of DealCoins Owed By Merchants</p>
              <div className={classes.contentLines}>
                {
                  loadingUser ? 
                  <Spiner /> : 
                  (<>
                    <Typography gutterBottom component="h2">Merchant Active: {amountDealcoinOwedMerchantActive}</Typography>
                    <Typography gutterBottom component="h2">Merchant Inactive: {amountDealcoinOwedMerchantInactive}</Typography>
                  </>)
                }
              </div> 
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }
  //-----------------------

  // --------Mark: render offer--------------
  // Number of offers
  renderOffer = () => {
    const { classes, loadingOffer, allOffersByAdmin } = this.props;
    const listData = allOffersByAdmin || [];

    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="info">
                <IconOffer />
              </CardIcon>
              <p className={classes.cardCategory}>Offer</p>
              {!loadingOffer ? <Typography gutterBottom component="h2">Quantity: {listData.length}</Typography> : null}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }

  // Number of offers on wish list
  renderOfferOnWishList = () => {
    const { classes, loadingWishList, allWishlistsByAdmin } = this.props;

    let listData = allWishlistsByAdmin || [];
    listData = _.groupBy(allWishlistsByAdmin, item => item.offerId);
    listData = Object.keys(listData).map((key) => {
      return [Number(key), listData[key]];
    });

    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="rose">
                <IconOffer />
              </CardIcon>
              <p className={classes.cardCategory}>Offer Wishlist</p>
              {!loadingWishList ? <Typography gutterBottom component="h2">Quantity: {listData.length}</Typography> : null}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }

  // Amount in dollars adding all offers
  renderAmountOfAllOffers = () => {
    const { classes, loadingOffer, allOffersByAdmin } = this.props;
    let amountAllOffer = 0;

    let listData = allOffersByAdmin || [];
    listData = _.filter(listData, item => item.status === 'PUBLISHED');
    _.forEach(listData, item => amountAllOffer += item.offerPrice);
    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="warning">
                <IconDolla />
              </CardIcon>
              <p className={classes.cardCategory}>Amount Of All Offers</p>
              {!loadingOffer ? <Typography gutterBottom component="h2">Amount: {Math.round(amountAllOffer)} $</Typography> : null}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }
  // ----------------------------

  // ------------Mark: Render deal----------------
  // Number of closed deals
  renderNumberOfCloseDeal = () => {
    const { classes, loadingDeal, allDeals } = this.props;

    let listData = allDeals || [];
    listData = _.filter(listData, item => item.state === 'CLOSED');

    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="warning">
                <IconCloseDeal />
              </CardIcon>
              <p className={classes.cardCategory}>Number Of Closed Deals</p>
              {!loadingDeal ? <Typography gutterBottom component="h2">Quantity: {listData.length}</Typography> : null}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }

  // Number of cancelled deals
  renderNumberOfCancelDeal = () => {
    const { classes, loadingDeal, allDeals } = this.props;

    let listData = allDeals || [];
    listData = _.filter(listData, item => item.state === 'CANCELED');

    return (
      <ErrorBoundaries>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="danger">
                <IconCloseDeal />
              </CardIcon>
              <p className={classes.cardCategory}>Number Of Cancel Deals</p>
              {!loadingDeal ? <Typography gutterBottom component="h2">Quantity: {listData.length}</Typography> : null}
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>Just Update</div>
            </CardFooter>
          </Card>
        </GridItem>
      </ErrorBoundaries>
    );
  }
  //-----------------------------

  // ------------Mark: Render main--------------
  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Typography gutterBottom component="h6">Users</Typography>
            <GridContainer>
              {this.renderUser()}
              {this.renderConsumers()}
            </GridContainer>
            <GridContainer>
              {this.renderAmountDealCoinOfAllConsumers()}
              {this.renderAmountOfDealConisOwedToClosedealByMerchants()}
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Typography gutterBottom component="h6">Offer</Typography>
            <GridContainer>
              {this.renderOffer()}
              {this.renderOfferOnWishList()}
              {this.renderAmountOfAllOffers()}
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Typography gutterBottom component="h6">Deal</Typography>
            <GridContainer>
              {this.renderNumberOfCloseDeal()}
              {this.renderNumberOfCancelDeal()}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// ---------Mark: Declare props---------
// Mark: default props
Dashboard.defaultProps = {
  classes: {},
  loadingOffer: false,
  allOffersByAdmin: [],
  loadingUser: false,
  listAllUsers: [],
  loadingConsumer: false,
  allConsumers: [],
  loadingWishList: false,
  allWishlistsByAdmin: [],
  loadingDeal: false,
  allDeals: [],
};
Dashboard.propTypes = {
  classes: PropTypes.object,
  loadingOffer: PropTypes.bool,
  allOffersByAdmin: PropTypes.array,
  loadingUser: PropTypes.bool,
  listAllUsers: PropTypes.array,
  loadingConsumer: PropTypes.bool,
  allConsumers: PropTypes.array,
  loadingWishList: PropTypes.bool,
  allWishlistsByAdmin: PropTypes.array,
  loadingDeal: PropTypes.bool,
  allDeals: PropTypes.array,
};
//------------------

// -----------Mark: Map data to props---------
const AllOffersByAdmin = graphql(getAllOffersByAdmin, {
  props: ({ data }) => ({
    loadingOffer: data.loading,
    allOffersByAdmin: data.getAllOffersByAdmin,
  }),
});

const ListAllUsers = graphql(listAllUsers, {
  props: ({ data }) => ({
    loadingUser: data.loading,
    listAllUsers: data.listAllUsers,
  }),
});

const AllConsumers = graphql(getAllConsumers, {
  props: ({ data }) => ({
    loadingConsumer: data.loading,
    allConsumers: data.getAllConsumers,
  }),
});

const AllWishlistsByAdmin = graphql(getAllWishlistsByAdmin, {
  props: ({ data }) => ({
    loadingWishList: data.loading,
    allWishlistsByAdmin: data.getAllWishlistsByAdmin,
  }),
});

const AllDeals = graphql(getAllDeals, {
  props: ({ data }) => ({
    loadingDeal: data.loading,
    allDeals: data.getAllDeals,
  }),
});

export default withStyles(dashboardStyle)(compose(AllOffersByAdmin, ListAllUsers, AllConsumers, AllWishlistsByAdmin, AllDeals)(Dashboard));
