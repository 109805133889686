import React from 'react';
import { BrowserRouter, Redirect } from 'react-router-dom';
import indexRoutes from './routes/index';
import PrivateRoute from './utilies/PrivateRoute';


const App = () => {
  const token = localStorage.getItem('token');
  return (
    <BrowserRouter>
      <div>
        { !token ? <Redirect to="/login" /> : null }
        {indexRoutes.map((item, index) => (
          <PrivateRoute
            path={item.path}
            component={item.component}
            token={token}
            key={index}
          />
        ))}
      </div>
    </BrowserRouter>
  );
};

export default App;
