import { colors } from "../../constants"

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
        backgroundColor: colors.parimary,
        width: '100px',
    },
    filterLabel: {
        marginTop: "2rem"
    }
});
export default styles;
