import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { FaEdit } from 'react-icons/fa';
// core components
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { getCronJobById } from './queries';
import { getAllCronJobs } from '../TableCronJob/queries';
import { updateCronJob } from './mutation';
import styles from './style';
import { Dialog } from '../../../components';


class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailCronJob } = this.props;
    this.state = {
      edit: false,
      cronkey: detailCronJob ? detailCronJob.cronkey : '',
      schedule: detailCronJob ? detailCronJob.schedule : '',
      testSchedule: detailCronJob ? detailCronJob.testSchedule : '',
      interval: detailCronJob ? detailCronJob.interval : '',
      testInterval: detailCronJob ? detailCronJob.testInterval : '',
      active: detailCronJob ? detailCronJob.active : '',
      order: detailCronJob ? detailCronJob.order : '',
      open: false,
      title: '',
      content: '',
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { detailCronJob } = this.props;
    if (nextProps.detailCronJob !== detailCronJob) {
      const { detailCronJob } = nextProps;
      this.setState({
        cronkey: detailCronJob ? detailCronJob.cronkey : '',
        schedule: detailCronJob ? detailCronJob.schedule : '',
        testSchedule: detailCronJob ? detailCronJob.testSchedule : '',
        interval: detailCronJob ? detailCronJob.interval : '',
        testInterval: detailCronJob ? detailCronJob.testInterval : '',
        active: detailCronJob ? detailCronJob.active : '',
        order: detailCronJob ? detailCronJob.order : '',
      });
    }
  }

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSaveProfile = async () => {
    const {
      cronkey, schedule, testSchedule, interval, testInterval, active, order,
    } = this.state;
    const { updateCronJob, match } = this.props;
    const { id } = match.params;
    updateCronJob({
      variables: {
        input: {
          cronkey, schedule, testSchedule, interval, testInterval, active, order,
        },
      },
      refetchQueries: [{ query: getCronJobById, variables: { id: Number(id) } }, { query: getAllCronJobs }],
    }).then((res) => {
      if (res) {
        this.setState({
          edit: false, open: true, content: 'Completed', title: 'Update CronJob',
        });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', title: 'Erros Update CronJob' });
      }
    });
  }

  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit, cronkey, schedule, testSchedule, interval, testInterval, active, order, title, open, content,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={title}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit CronJob' : 'Detail CronJob'}</h4>
                { edit ? <p className={classes.cardCategoryWhite}>Complete CronJob</p> : null }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Cronkey"
                      id="cronkey"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: cronkey || '',
                        name: 'cronkey',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>

                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Schedule"
                      id="schedule"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: schedule || '',
                        name: 'schedule',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Test Schedule"
                      id="testSchedule"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: testSchedule || '',
                        name: 'testSchedule',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Interval"
                      id="interval"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: interval || '',
                        name: 'interval',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Test Interval"
                      id="testInterval"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: testInterval || '',
                        name: 'testInterval',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Order"
                      id="order"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: order || '',
                        name: 'order',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.boxCheck}>
                      <GridContainer>
                        <Typography component="p" className={classes.textDisable}>Active</Typography>
                        <Checkbox
                          checked={active}
                          onChange={() => this.setState({ active: !active })}
                          disabled={!edit}
                        />
                      </GridContainer>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              {
                edit
                  ? (
                    <CardFooter>
                      <Button className={classes.button} onClick={this.onSaveProfile}>Update CronJob</Button>
                    </CardFooter>
                  ) : null
              }
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>CronJob</h6>
                <h4 className={classes.cardTitle}>{cronkey.toUpperCase()}</h4>
                <Button round className={classes.button} onClick={this.onEdit}>
                  <Typography component="p" className={classes.text}>{!edit ? 'Edit' : 'Cancel'}</Typography>
                  <FaEdit color="#ffffff" size={16} />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  updateCronJob: () => {},
  detailCronJob: {},
  match: {},
  loading: false,
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  updateCronJob: PropTypes.func,
  detailCronJob: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool,
};

// Mark: Map data grapql to props
const DetailCronJob = graphql(getCronJobById, {
  props: ({ data }) => ({
    loading: data.loading,
    detailCronJob: data.getCronJobById,
  }),
  options: (props) => {
    return ({ variables: { id: Number(props.match.params.id) }, fetchPolicy: 'cache-and-network' });
  },
});

const Update = graphql(updateCronJob, { name: 'updateCronJob' });

export default withStyles(styles)(compose(DetailCronJob, Update)(Detail));
