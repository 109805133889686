import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, compose, Query } from "react-apollo";
import * as rx from "rxjs";
import * as rxOps from "rxjs/operators";
import _ from "lodash";
import moment from "moment";
import { Formik } from "formik";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
// material
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider, InlineDatePicker } from "material-ui-pickers";

import TransactionDetail from "./TransactionDetail";
import FilterFromTo from "../../../../components/Filter/FilterFromTo";

import { getTransactionsOfAdmin, getTransactionByIdOfAdmin } from "./queries";
import { Dialog, Spiner } from "../../../../components";
import styles from "./styles";
import { colors } from "../../../../constants";
import { Typography } from "@material-ui/core";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const formikInitialVal = {
  balanceFrom: "",
  balanceTo: "",
  amountFrom: "",
  amountTo: "",
  dateFrom: null,
  dateTo: null,
  transType: "",
};

class TableTransactions extends Component {
  state = {
    open: false,
    title: "TRANSACTION DETAIL",
    content: "",
    search: "",
    page: 0,
    limit: 10,

    transactionId: null,
    transactionType: null,

    debounceSubmit: null,
    amountFrom: null,
    amountTo: null,
  };

  onOk = () => {
    this.setState({ open: false });
  };

  onTransactionDetailClick = ({ transactionId, transactionType }) => {
    this.setState({ open: true, transactionId, transactionType });
  };

  renderDialogContent = () => {
    const transactionTypes = {
      CLOSED_DEAL: "CLOSED_DEAL",
      CASHED_DEAL: "CASHED_DEAL",
      TRANSFER_SENT: "TRANSFER_SENT",
      TRANSFER_RECEIVED: "TRANSFER_RECEIVED",
      PAYMENT_METHOD: "PAYMENT_METHOD",
      TDC_CREDIT: "TDC_CREDIT",
      CANCEL: "CANCEL",
      COUPON: "COUPON",
    };

    return (
      <Query
        query={getTransactionByIdOfAdmin}
        variables={{ id: this.state.transactionId }}
      >
        {({ loading, data, error }) => {
          if (loading) {
            return <Spiner />;
          }
          if (error) {
            return (
              <Typography color={"error"}>
                {error.message ? error.message : JSON.stringify(error)}
              </Typography>
            );
          }

          data = data.getTransactionByIdOfAdmin;
          switch (this.state.transactionType) {
            case transactionTypes.CLOSED_DEAL:
            case transactionTypes.CASHED_DEAL:
              return this.renderDealDialogContent(data);

            case transactionTypes.TRANSFER_SENT:
            case transactionTypes.TRANSFER_RECEIVED:
              return this.renderTransferDialogContent(data);

            case transactionTypes.CANCEL:
              return this.renderCanceledDealDetail(data);
            case transactionTypes.TDC_CREDIT:
                //return this.renderTransferDialogContent(data);
            case transactionTypes.PAYMENT_METHOD:
            
            case transactionTypes.COUPON:
              return this.renderDefaultDetail(data);

            default:
              return "Nothing to show";
          }
        }}
      </Query>
    );
  };

  renderDealDialogContent = (data) => {
    return (
      <TransactionDetail data={data}>
        <Typography style={{ fontSize: 14 }} variant="caption">
          Offer Title: {data.deal.offer.title}
        </Typography>
        <Typography style={{ fontSize: 14 }} variant="caption">
          Offer Quantities: {data.deal.qty}
        </Typography>
        <Typography style={{ fontSize: 14 }} variant="caption">
          Deal ID: {data.deal.sku}
        </Typography>
        <Typography style={{ fontSize: 14 }} variant="caption">
          Consumer Username: {data.deal.consumer.username}
        </Typography>
      </TransactionDetail>
    );
  };

  renderTransferDialogContent = (data) => {
    return (
      <TransactionDetail data={data}>
        <Typography style={{ fontSize: 14 }} variant="caption">
          From: {data.transfered.name}
        </Typography>
        <Typography style={{ fontSize: 14 }} variant="caption">
          To: {data.received.name}
        </Typography>
      </TransactionDetail>
    );
  };

  renderCanceledDealDetail = (data) => {
    return (
      <TransactionDetail data={data}>
        <Typography style={{ fontSize: 14 }} variant="caption">
          Deal ID: {data.deal.sku}
        </Typography>
        <Typography style={{ fontSize: 14 }} variant="caption">
          Cancel reason:{" "}
          {data.deal.customCancelReason || data.deal.cancelReason}
        </Typography>
      </TransactionDetail>
    );
  };

  renderDefaultDetail = (data) => {
    return (
      <TransactionDetail data={data}>
        <Typography style={{ fontSize: 14 }} variant="caption">
          merchant: {data.merchant.username}
        </Typography>
      </TransactionDetail>
    );
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  };

  dataFilter = (transactions, filters) => {
    const { from } = rx;
    const { filter } = rxOps;
    const {
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      balanceFrom,
      balanceTo,
      transType,
    } = filters;
    let finalData = [];
    from(transactions)
      .pipe(
        filter((trans) =>
          dateFrom === null
            ? true
            : moment(new Date(trans.createdDate)).isSameOrAfter(dateFrom)
        ),
        filter((trans) =>
          dateTo === null
            ? true
            : moment(new Date(trans.createdDate)).isSameOrBefore(dateTo)
        ),
        filter((trans) =>
          amountFrom !== "" ? trans.amount >= amountFrom : true
        ),
        filter((trans) => (amountTo !== "" ? trans.amount <= amountTo : true)),
        filter((trans) =>
          balanceFrom !== "" ? trans.balance >= balanceFrom : true
        ),
        filter((trans) =>
          balanceTo !== "" ? trans.balance <= balanceTo : true
        ),
        filter((trans) => (transType === "" ? true : trans.type === transType))
      )
      .subscribe((e) => {
        finalData.push(e); // synchronous itself
      });
    return finalData;
  };

  debounceSubmit = null;
  data = null;

  render() {
    const { classes, transactionsOfAdmin } = this.props;
    const { title, content, open, search, page, limit } = this.state;
    if (!this.data || !this.data.length) {
      this.data = _.orderBy(transactionsOfAdmin, (action) => action.id, "desc");
    }

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <Dialog
            title={title}
            listChildren={this.renderDialogContent()}
            open={open}
            onOk={() => this.onOk()}
          />
          <Formik
            initialValues={formikInitialVal}
            onSubmit={(values, { setSubmitting }) => {
              console.log(`values`, values);
              this.data = this.dataFilter(transactionsOfAdmin, values);
              setSubmitting(false);
            }}
          >
            {({ submitForm, handleChange, setFieldValue, values }) => {
              if (!this.debounceSubmit) {
                this.debounceSubmit = _.debounce(submitForm, 500);
              }

              return (
                <React.Fragment>
                  <FilterFromTo
                    label="Balance"
                    fromField={
                      <TextField
                        id="standard-number"
                        label="From"
                        type="number"
                        value={values.balanceFrom}
                        onChange={(e) => {
                          handleChange("balanceFrom")(e);
                          this.debounceSubmit();
                        }}
                      />
                    }
                    toField={
                      <TextField
                        id="standard-number"
                        label="To"
                        type="number"
                        value={values.balanceTo}
                        onChange={(e) => {
                          handleChange("balanceTo")(e);
                          this.debounceSubmit();
                        }}
                      />
                    }
                    resetFn={() => {
                      setFieldValue("balanceFrom", "");
                      setFieldValue("balanceTo", "");
                      this.debounceSubmit();
                    }}
                  />
                  <FilterFromTo
                    label="Amount"
                    fromField={
                      <TextField
                        id="standard-number"
                        label="From"
                        type="number"
                        value={values.amountFrom}
                        onChange={(e) => {
                          handleChange("amountFrom")(e);
                          this.debounceSubmit();
                        }}
                      />
                    }
                    toField={
                      <TextField
                        id="standard-number"
                        label="To"
                        type="number"
                        value={values.amountTo}
                        onChange={(e) => {
                          handleChange("amountTo")(e);
                          this.debounceSubmit();
                        }}
                      />
                    }
                    resetFn={() => {
                      setFieldValue("amountFrom", "");
                      setFieldValue("amountTo", "");
                      this.debounceSubmit();
                    }}
                  />
                  <FilterFromTo
                    label="Date"
                    fromField={
                      <InlineDatePicker
                        label="From"
                        id="date-from"
                        onChange={(e) => {
                          handleChange("dateFrom")(
                            moment(e).format("MM/DD/YYYY")
                          );
                          this.debounceSubmit();
                        }}
                        format="MM/dd/yyyy"
                        value={values.dateFrom}
                      />
                    }
                    toField={
                      <InlineDatePicker
                        label="To"
                        id="date-to"
                        onChange={(e) => {
                          handleChange("dateTo")(
                            moment(e).format("MM/DD/YYYY")
                          );
                          this.debounceSubmit();
                        }}
                        format="MM-dd-yyyy"
                        value={values.dateTo}
                      />
                    }
                    resetFn={() => {
                      setFieldValue("dateFrom", null);
                      setFieldValue("dateTo", null);
                      this.debounceSubmit();
                    }}
                  />
                  <FilterFromTo
                    label={"Choose transaction type"}
                    fromField={
                      <Select
                        name="Transaction type"
                        displayEmpty
                        value={values.transType}
                        inputProps={{
                          name: "Transaction Type",
                          id: "transaction-type",
                        }}
                        onChange={(e) => {
                          handleChange("transType")(e);
                          submitForm();
                        }}
                      >
                        <MenuItem defaultChecked value="">
                          None
                        </MenuItem>
                        {_.uniqBy(transactionsOfAdmin, "type").map(
                          (trans, key) => (
                            <MenuItem key={key} value={trans.type}>
                              {trans.type}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    }
                    resetFn={() => {
                      setFieldValue("transType", "");
                      submitForm();
                    }}
                  />
                  <Paper className={classes.root}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <CustomTableCell>Username</CustomTableCell>
                          <CustomTableCell
                            align="right"
                            className={classes.columnRow}
                          >
                            Amount{" "}
                          </CustomTableCell>
                          <CustomTableCell
                            align="right"
                            className={classes.columnRow}
                          >
                            Type
                          </CustomTableCell>
                          <CustomTableCell
                            align="right"
                            className={classes.columnRow}
                          >
                            Balance
                          </CustomTableCell>
                          <CustomTableCell
                            align="right"
                            className={classes.columnRow}
                          >
                            Detail
                          </CustomTableCell>
                          <CustomTableCell
                            align="right"
                            className={classes.columnRow}
                          >
                            Created Date
                          </CustomTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.data
                          .slice(page * limit, page * limit + limit)
                          .map((row) => {
                            const account = row.consumer || row.merchant || {};
                            const username = account.username || "";
                            return (
                              <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell component="th" scope="row">
                                  {username}
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  className={classes.colTable}
                                >
                                  {row.amount}
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  className={classes.colTable}
                                >
                                  {row.type}
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  className={classes.colTable}
                                >
                                  {row.balance}
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  className={classes.colTable}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    style={{ margin: 0 }}
                                    onClick={() =>
                                      this.onTransactionDetailClick({
                                        transactionId: row.id,
                                        transactionType: row.type,
                                      })
                                    }
                                  >
                                    Detail
                                  </Button>
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  className={classes.colTable}
                                >
                                  {row.createdDate}
                                </CustomTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={this.data.length || 0}
                      rowsPerPage={limit}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "Previous Page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "Next Page",
                      }}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </Paper>
                </React.Fragment>
              );
            }}
          </Formik>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

// Mark: default props
TableTransactions.defaultProps = {
  classes: {},
  transactionsOfAdmin: [],
};

// Mark: PropsType
TableTransactions.propTypes = {
  classes: PropTypes.object,
  transactionsOfAdmin: PropTypes.array,
};

const TransactionsOfAdmin = graphql(getTransactionsOfAdmin, {
  props: ({ data }) => ({
    loading: data.loading,
    transactionsOfAdmin: data.getTransactionsOfAdmin,
  }),
  options: (props) => {
    const { merchantId, consumerId } = props.match.params;
    let input;
    if (merchantId) input = { merchantId: Number(merchantId) };
    if (consumerId) input = { consumerId: Number(consumerId) };
    return { variables: { input }, fetchPolicy: "cache-and-network" };
  },
});

export default withStyles(styles)(
  compose(TransactionsOfAdmin)(TableTransactions)
);
