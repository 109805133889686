import gql from 'graphql-tag';

export const getAllPlans = gql`
  query getAllPlans{
    getAllPlans{
      id
      name
      price
      formatted_price
      disc
      active
      default
      is_promotion
      period
    }
  }
`;
