import gql from 'graphql-tag';

export const activeAdmin = gql`
  mutation activeAdmin($id: Int!){
    activeAdmin( id: $id){
      id
    }
  }
`;

export const deactivateAdmin = gql`
  mutation deactivateAdmin($id: Int!){
    deactivateAdmin( id: $id){
      id
    }
  }
`;
