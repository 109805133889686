import gql from 'graphql-tag';

export const getCategoryById = gql`
  query getCategoryById($id: Int!){
    getCategoryById(id: $id){
      id
      name
      reservationPercentage
      bonusPercentage
      sharePercentage
      closedealPercentage
    }
  }
`;
