import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { CSVLink } from "react-csv";
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Search from '@material-ui/icons/Search';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {
  MuiPickersUtilsProvider,
  InlineDatePicker
} from 'material-ui-pickers';

import { FaFileExport } from 'react-icons/fa';

import { getAllConsumerActions } from './queries';
import { Dialog } from '../../../../components';
import ActionDetailDialog from "./ActionDetail";
import CustomInput from '../../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../../constants';

// assets
import ExpandLessOutlined from '@material-ui/icons/ExpandLessOutlined';
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined';
import { filterByDate } from './../../../../utilies/index';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const ArrowUp = withStyles(theme => ({
  root: {
    marginBottom: -7
  }
}))(ExpandLessOutlined);

const ArrowDown = withStyles(theme => ({
  root: {
    marginBottom: -7
  }
}))(ExpandMoreOutlined);

class TableAction extends Component {
  state = {
    open: false,
    title: '',
    content: '',
    search: '',
    type: '',
    action: '',
    searchmerchant: '',
    page: 0,
    limit: 10,
    data: null,
    sortByNameOrder: null,
    sortByDateOrder: null,
    fromDate: null,
    toDate: null,
    isActionDetailBoxShown: false,
    dealId: null
  };

  // mark: method
  onOk = () => {
    this.setState({ open: false });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }
  
  onSortByName = () => {
    let { data, sortByNameOrder } = this.state;
    if (data) {
      sortByNameOrder = sortByNameOrder === "asc" ? "desc" : "asc"
      const newData = _.orderBy(data, obj => obj.consumer.username, sortByNameOrder);
      this.setState({ data: newData, sortByNameOrder, sortByDateOrder: null })
    }
  }

  onSortByDate = () => {
    let { data, sortByDateOrder } = this.state;
    if (data) {
      sortByDateOrder = sortByDateOrder === "desc" ? "asc" : "desc"
      const newData = _.orderBy(data, obj => obj.createdDate, sortByDateOrder);
      this.setState({ data: newData, sortByDateOrder, sortByNameOrder: null })
    }
  }

  onExportFile = () => {
    this.csvLinkRef.link.click();
  }

  onFilterType = (e) => {
    this.setState({ type: e.target.value })
  }

  onFilterAction = (e) => {
    this.setState({ action: e.target.value })
  }

  onFilterMerchant = (e) => {
    this.setState({ searchmerchant: e.target.value })
  }

  // onFilterMerchant

  resetFilterByDate = () => {
    this.setState({
      fromDate: null,
      toDate: null
    })
  }

  closeActionDetailBox = () => {
    this.setState({ isActionDetailBoxShown: false });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }
  
  handleOpenDetailAction = (dealId) => {
    this.setState({ isActionDetailBoxShown: true, dealId })
  }

  componentDidUpdate() {
    if (this.state.data === null) {
      let data = this.props.listAllAction;
      data = _.orderBy(data, obj => obj.createdDate, "desc");
      this.setState({ data, sortByDateOrder: "desc" });
    }
  }

  render() {
    const { classes } = this.props;
    let data = this.state.data || [];
    console.log(data)
    const {
      title, content, open, search, type, action, searchmerchant, page, limit, fromDate, toDate
    } = this.state;

    // filter
    if (search) data = _.filter(data, obj => obj.consumer.username.toLowerCase().match(search.toLowerCase()));
    if (type) data = _.filter(data, obj => obj.objectType.toLowerCase().match(type.toLowerCase()));
    if (action) data = _.filter(data, obj => obj.action.toLowerCase().match(action.toLowerCase()));
    if (searchmerchant) {
      data = _.filter(data, obj => {
        if (obj.merchant && obj.merchant.username) {
          return obj.merchant.username.toLowerCase().match(searchmerchant.toLowerCase());
        }
        //return false;
      });
    }

    if (fromDate)
      data = _.filter(data, obj => {
        const actionDate = new Date(obj.createdDate);
        return moment(actionDate).isSameOrAfter(fromDate, "day");
      })

    if (toDate)
      data = _.filter(data, obj => {
        const actionDate = new Date(obj.createdDate);
        return moment(actionDate).isSameOrBefore(toDate, "day");
      })

    const dataToExport = _.map(data, obj => ({ ...obj, consumer: obj.consumer.username }))

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <Dialog
            title={title}
            content={content}
            open={open}
            onOk={() => this.onOk()}
          />
          {
            this.state.dealId && 
              <ActionDetailDialog 
                title="Action Dialog"
                dealId={this.state.dealId}
                open={this.state.isActionDetailBoxShown}
                onOk={() => this.closeActionDetailBox()}
              />
          }
          <div className={classes.searchWrapper}>
            <Typography variant="body1" className={classes.filterLabel}>Name Filter</Typography>
            <CustomInput
              formControlProps={{
                className: `${classes.margin} ${classes.search}`,
                style: { margin: 0 }
              }}
              inputProps={{
                value: search,
                onChange: this.onSearch,
                placeholder: 'Search',
                inputProps: {
                  'aria-label': 'Search',
                },
              }}
            />
            <Button aria-label="edit" disabled>
              <Search />
            </Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={(e) => this.onExportFile()}>
              <Typography component="p" className={classes.text}>Export</Typography>
              <FaFileExport color="#ffffff" size={16} />
            </Button>
            <CSVLink
              style={{ display: "none" }}
              data={dataToExport}
              filename="consumer-action.csv"
              ref={(r) => this.csvLinkRef = r}
            >
              Export
            </CSVLink>
          </div>
          <div>
            <Typography variant="body1" className={classes.filterLabel}>Date Filter</Typography>
            <Grid key="date-filter" container>
              <Grid key="from" container item xs={12} md={8}>
                <Grid item xs={12} sm={6}>
                  <InlineDatePicker
                    // keyboard
                    label="From"
                    id="date-from"
                    onChange={(date) => this.setState({ fromDate: date })}
                    format="yyyy-MM-dd"
                    value={this.state.fromDate}
                  />
                </Grid>
                <Grid key="to" item xs={12} sm={6}>
                  <InlineDatePicker
                    // keyboard
                    label="To"
                    id="date-to"
                    onChange={(date) => this.setState({ toDate: date })}
                    format="yyyy-MM-dd"
                    value={this.state.toDate}
                  />
                </Grid>
              </Grid>
              <Grid key="reset-date-filter" container item xs={12} md={4}>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.resetFilterByDate}>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="body1" className={classes.filterLabel}>Action {'&'} Type Filter</Typography>
            <Grid key="type-filter" container alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <CustomInput
                  formControlProps={{
                    className: `${classes.margin} ${classes.search}`,
                    style: { margin: 0 }
                  }}
                  inputProps={{
                    value: type,
                    onChange: this.onFilterType,
                    placeholder: 'Type',
                    inputProps: {
                      'aria-label': 'Type',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                    formControlProps={{
                      className: `${classes.margin} ${classes.search}`,
                      style: { margin: 0 }
                    }}
                    inputProps={{
                      value: action,
                      onChange: this.onFilterAction,
                      placeholder: 'Action',
                      inputProps: {
                        'aria-label': 'Action',
                      },
                    }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                    formControlProps={{
                      className: `${classes.margin} ${classes.search}`,
                      style: { margin: 0 }
                    }}
                    inputProps={{
                      value: searchmerchant,
                      onChange: this.onFilterMerchant,
                      placeholder: 'Merchant',
                      inputProps: {
                        'aria-label': 'Merchant',
                      },
                    }}
                />
              </Grid>
            </Grid>
          </div>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell style={{cursor: "pointer"}} onClick={this.onSortByName}>
                    Username
                    {this.state.sortByNameOrder !== null ?
                      (this.state.sortByNameOrder === "asc" ? <ArrowUp /> : <ArrowDown />)
                      : null
                    }
                  </CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Type</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Merchant name</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Action</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Detail</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow} style={{cursor: "pointer"}} onClick={this.onSortByDate}>
                    Created Date
                    {this.state.sortByDateOrder !== null ? 
                      (this.state.sortByDateOrder === "asc" ? <ArrowUp /> : <ArrowDown />)
                      : null 
                    }
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * limit, page * limit + limit).map(row => (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.consumer.username}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.objectType}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.merchant? row.merchant.username:''}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.action}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        style={{ margin: 0 }} 
                        className={classes.button}
                        onClick={() => this.handleOpenDetailAction(row.objectId)}>
                        Detail
                      </Button>
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.createdDate}</CustomTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={data.length}
              rowsPerPage={limit}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
          </Paper>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

// Mark: defaultProps
TableAction.defaultProps = {
  classes: {},
  listAllAction: [],
};

// Mark: PropsType
TableAction.propTypes = {
  classes: PropTypes.object,
  listAllAction: PropTypes.array,
};

const ListAll = graphql(getAllConsumerActions, {  
  props: ({ data }) => ({
    loading: data.loading,
    listAllAction: data.getAllConsumerActions,
  }),
});


export default withStyles(styles)(compose(ListAll)(TableAction));
