import gql from 'graphql-tag';

export const switchOffer = gql`
  mutation switchOffer($offerId: Int!, $statusOffer: Boolean){
    switchOffer( offerId: $offerId, statusOffer: $statusOffer ){
      offerId
    }
  }
`;

export const changeActive = gql`
  mutation changeActive($id: Int!, $active: Boolean){
    changeActive( id: $id, active: $active )
  }
`;
