import gql from 'graphql-tag';

export const listAllUsers = gql`
 query listAllUsers{
    listAllUsers{
      id
      username
      email
      role
      mobile
      active
      confirmed
      createdAt
      wallet{ 
          id
          balance
          balanceTDC
      }
      deactivationReason
      deleteAccount
    }
  }
`;
