import gql from 'graphql-tag';

export const getOfferByAdmin = gql`
  query getOfferByAdmin($id: Int!){
    getOfferByAdmin(id: $id){
      id
      title
      description
      quantities
      offerStartDetail
      offerEndDetail
      offerPrice
      offerTimer
      regularPrice
      saleOff
      wishlistCount
      viewCount
      active
      status
      category{
        id
        name
      }
      images {
        id
        full
      }
      stores{
        id
        name
        location
        phone
        pictures
      }
      merchant{
        id
        username
      }
    }
  }
`;

export const getAllCategories = gql`
  query getAllCategories{
    getAllCategories{
      id
      name
    }
  }
`;
