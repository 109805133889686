import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';


import { getAllSharedOffer } from './queries';
import { Dialog } from '../../../../components';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../../constants';
import moment from "moment";
import { Grid } from '@material-ui/core';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TableSharedOffer extends Component {
  state={
    open: false,
    title: '',
    content: '',
    search: '',
    searchUsername: '',
    page: 0,
    limit: 10,
    showList: false,
    listConsumer: [],
    fromDate: null,
    toDate: null,
  }

  resetFilterByDate = () => {
    this.setState({
      fromDate: null,
      toDate: null
    })
  }


  onOk = () => {
    this.setState({ open: false, showList: false });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  onSearchUsername = (event) => {
    this.setState({ searchUsername: event.target.value });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }


  _rederShowListConsumer = () => {
    const { showList, listConsumer } = this.state;
    const title = 'List Consumer Used';
    const data = _.orderBy(listConsumer, obj => Number(obj.createdAt), 'desc');    
    
    const children = (
      <div>
        {
          data.length > 0
          && data.map((item, i) => 
          {  
            /*const date = moment(Number(item.createdAt)).format("MM/DD/YYYY");
            const time = moment(Number(item.createdAt)).format("LT");*/
            return (
            
            <div key={i}>{item.username} <b> Used:</b> {item.usageCount}</div>
          )})
        }
      </div>
    );
    return (
      <Dialog
        title={title}
        listChildren={children}
        open={showList}
        onOk={() => this.onOk()}
      />
    );
  }

  _renderToolBar = () => {
    const { classes } = this.props;
    const { search, searchUsername } = this.state;
    return (
      <>
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`,
          }}
          inputProps={{
            value: search,
            onChange: this.onSearch,
            placeholder: 'Title',
            inputProps: {
              'aria-label': 'Title',
            },
          }}
        />
        <Button disabled>
          <Search />
        </Button>

        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`,
          }}
          inputProps={{
            value: searchUsername,
            onChange: this.onSearchUsername,
            placeholder: 'Username',
            inputProps: {
              'aria-label': 'Username',
            },
          }}
        />
        <Button disabled>
          <Search />
        </Button>

      </div>
      <div>
      <Typography variant="body1" className={classes.filterLabel}>Date Filter</Typography>
      <Grid key="date-filter" container>
        <Grid key="from" container item xs={12} md={8}>
          <Grid item xs={12} sm={6}>
            <InlineDatePicker
              // keyboard
              label="From"
              id="date-from"
              onChange={(date) => this.setState({ fromDate: date })}
              format="yyyy-MM-dd"
              value={this.state.fromDate}
            />
          </Grid>
          <Grid key="to" item xs={12} sm={6}>
            <InlineDatePicker
              // keyboard
              label="To"
              id="date-to"
              onChange={(date) => this.setState({ toDate: date })}
              format="yyyy-MM-dd"
              value={this.state.toDate}
            />
          </Grid>
        </Grid>
        <Grid key="reset-date-filter" container item xs={12} md={4}>
          <Button variant="contained" color="primary" className={classes.button} onClick={this.resetFilterByDate}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
    </>
      
    );
  }

  render() {
    const { classes, getAllSharedOffer } = this.props;
    let data = getAllSharedOffer || [];    
    data = _.orderBy(data, obj => Number(obj.createdAt), 'desc');
    
    const {
      title, content, open, search, searchUsername, page, limit, fromDate, toDate
    } = this.state;

    try {
      if (search) data = _.filter(data, obj => obj.offer.title.toLowerCase().match(search.toLowerCase()));
      
      if (searchUsername) {
        data = _.filter(data, function (obj) {
          const username = (obj.consumer && obj.consumer.username) 
                            || (obj.merchant && obj.merchant.username);          
          return username ? username.toLowerCase().includes(searchUsername.toLowerCase()) : false;
        });
      }

    } catch (error) {
      console.log('Filtered error: ',error)
    }
    
    if (fromDate)
      data = _.filter(data, obj => {
        const actionDate = new Date(Number(obj.createdAt));
        return moment(actionDate).isSameOrAfter(fromDate, "day");
      })

    if (toDate)
      data = _.filter(data, obj => {
        const actionDate = new Date(Number(obj.createdAt));
        return moment(actionDate).isSameOrBefore(toDate, "day");
      })
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        {this._rederShowListConsumer()}
        {this._renderToolBar()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell align="right" className={classes.columnRow}>Title</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Username</CustomTableCell>                              
                <CustomTableCell align="right" className={classes.columnRow}>Count Used</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Created At</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>List Used</CustomTableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.offer.title}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.consumer ? row.consumer.username: row.merchant.username }</CustomTableCell>                                     
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.listUserEnterCode.length > 0?row.listUserEnterCode[0].totalUsageCount:0}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {moment(Number(row.createdAt)).format("MM/DD/YYYY")}
                    </CustomTableCell>
                    
                    <CustomTableCell align="right" className={classes.colTable}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonActive}
                        onClick={() => this.setState({ showList: true, listConsumer: row.listUserEnterCode })}
                      >
                        <Typography component="p" className={classes.text}>Show</Typography>
                      </Button>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

// Mark: default props
TableSharedOffer.defaultProps = {
  classes: {},
  getAllSharedOffer: [],
};

// Mark: PropsType
TableSharedOffer.propTypes = {
  classes: PropTypes.object,
  getAllSharedOffer: PropTypes.array,
};

// Mark: Map data grapql to props
const AllReferralCodeByAdmin = graphql(getAllSharedOffer, {
  props: ({ data }) => ({
    loading: data.loading,
    getAllSharedOffer: data.getAllSharedOffer,
  }),
});


export default withStyles(styles)(compose(AllReferralCodeByAdmin)(TableSharedOffer));
