import gql from 'graphql-tag';

export const updateCronJob = gql`
  mutation updateCronJob($input: CronJobInput!){
    updateCronJob(input: $input){
      id
      cronkey
      schedule
      testSchedule
      interval
      testInterval
      active
      order
    }
  }

`;
