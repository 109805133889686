import React from 'react';
import { Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
// @material-ui/icons
// import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from '../CustomButtons/Button.jsx';
// import NotificationList from "./NotificationList.jsx";
import headerLinksStyle from '../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx';

import { getCurrentAdmin } from '../../views/Profile/queries';

const imageDefault =
  'https://cdn3.vectorstock.com/i/1000x1000/26/87/user-icon-man-profile-human-person-avatar-vector-10552687.jpg';

class HeaderLinks extends React.Component {
  state = {
    avatar: this.props.detailAdmin
      ? this.props.detailAdmin.avatar
      : imageDefault,
    openPro: false
  };

  componentWillReceiveProps = nextProps => {
    if (nextProps.detailAdmin !== this.props.detailAdmin) {
      const detailAdmin = nextProps.detailAdmin;
      this.setState({
        avatar: imageDefault
      });
    }
  };

  handleToggle = name => () => {
    this.setState(state => ({ [name]: !state[name] }));
  };

  handleClose = name => event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ [name]: false });
  };

  handleLogOut = async () => {
    localStorage.removeItem('token');
  };

  render() {
    const { classes } = this.props;
    const { openPro } = this.state;

    return (
      <div>
        {/* <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search"
              }
            }}
          />
          <Button color="white" aria-label="edit" justIcon round>
            <Search />
          </Button>
        </div> */}
        {/* <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="Dashboard"
          className={classes.buttonLink}
        >
          <Dashboard className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Dashboard</p>
          </Hidden>
        </Button> */}

        <div className={classes.manager}>
          <Link to="/profile">
            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}
              color={window.innerWidth > 959 ? 'transparent' : 'white'}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openPro ? 'menu-list-grow' : null}
              aria-haspopup="true"
              onClick={this.handleToggle('openPro')}
              className={classes.buttonLink}
            >
              <Avatar
                alt="Avartar"
                src={this.state.avatar}
                className={classes.avatarHead}
              />
              <Hidden mdUp implementation="css">
                <p onClick={this.handleClick} className={classes.linkText}>
                  Profile
                </p>
              </Hidden>
            </Button>
          </Link>
          {/* <Poppers
            open={openPro}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openPro }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose("openPro")}>
                    <MenuList role="menu">
                      <Link to="/settings">
                          <MenuItem className={classes.dropdownItem}>
                            <MdStarHalf color='#ffffff' size={13} />
                            Profile
                          </MenuItem>
                      </Link>

                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers> */}
        </div>
      </div>
    );
  }
}

const DetailAdmin = graphql(getCurrentAdmin, {
  props: ({ data }) => ({
    loading: data.loading,
    detailAdmin: data.getCurrentAdmin
  }),
  options: props => {
    return { fetchPolicy: 'cache-and-network' };
  }
});

export default withStyles(headerLinksStyle)(compose(DetailAdmin)(HeaderLinks));
