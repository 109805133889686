import gql from 'graphql-tag';

export const signupAdmin = gql`
  mutation signupAdmin($input: signupAdminInput!){
    signupAdmin(input: $input){
      id
      username
      password
    }
  }
`;
