import React from "react";
import _ from "lodash";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

export default function AddTDCDialogContent({ data, children }) {
  const tdc = data.wallet.balanceTDC;
  const edc = data.wallet.balance;

  return (
    <Grid container={true} direction="column" style={{width:300}} >
      <Grid item container direction="row">
        <Typography component="p">
              Status wallet {_.capitalize(data.username.replace("_", " "))}
        </Typography>                
      </Grid>
      <Table style={{width: '100%', marginBottom:20}}>
        <TableCell>EDC</TableCell>
        <TableCell>TDC</TableCell>
        <TableBody>
          <TableRow>
            <TableCell align="right">
              {edc}
            </TableCell>
            <TableCell align="right">
              {tdc}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>      
      <Grid item container direction="column">        
        <div>{children}</div>
      </Grid>
    </Grid>
  );
}
