import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import styles from "./stylesV2";
import CleanIcon from "@material-ui/icons/Close";

function FilterFromToV2(props) {
  const { label, fromField, toField, resetFn, classes, smallButton = false } = props;
  return (
    <div>
      {typeof label === "string" ? (
        <span className={classes.filterLabel}>
          {label}
        </span>
      ) : (
        label
      )}
      <div className={classes.container}>
        <div key="from" className={classes.row}>
          {fromField && (
            <div className={classes.col}>
              {fromField}
            </div>
          )}
          {toField && (
            <div key="to" className={classes.col}>
              {toField}
            </div>
          )}
        </div>
        {resetFn && (
          <div className={classes.row}>
            {smallButton ? (
              <div className={classes.buttonContainer}>
                <button
                  className={classes.smallButton}
                  onClick={() => resetFn && resetFn()}
                >
                  <CleanIcon className={classes.icon}/>
                </button>
              </div>
            ) : (
              <button
                className={classes.button}
                onClick={() => resetFn && resetFn()}
              >
                Reset
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

FilterFromToV2.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  fromField: PropTypes.element,
  toField: PropTypes.element,
  resetFn: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterFromToV2);
