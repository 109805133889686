import gql from 'graphql-tag';

export const archiveConsumer = gql`
  mutation archiveConsumer($id: Int!){
    archiveConsumer(id: $id){
      id
      username
    }
  }
`;

export const unarchiveConsumer = gql`
  mutation unarchiveConsumer($id: Int!){
    unarchiveConsumer(id: $id){
      id
      username
    }
  }
`;
