export const {
  REACT_APP_URL_API, 
  REACT_APP_URL_GRAPHQL, 
  REACT_APP_WS_SUSCRIPTION,
  REACT_APP_URL_UPLOAD_FILES
} = process.env

const configDefault = {
  uri: REACT_APP_URL_API || 'http://localhost:8080',
  graphql: REACT_APP_URL_GRAPHQL || 'http://localhost:8080/graphql',
  subscription: REACT_APP_WS_SUSCRIPTION || 'ws://localhost:8080/graphql',
  upload: REACT_APP_URL_UPLOAD_FILES || 'http://localhost:8080/upload',
};

// /////////////dev//////////
// const configDefault = {
//   uri: 'https://apd.closedeal.app',
//   graphql: 'https://apd.closedeal.app/graphql',
//   subscription: 'ws://apd.closedeal.app/graphql',
//   upload: 'https://apd.closedeal.app/upload',
// };

// ////////////production///////////
/*const configDefault = {
  uri: 'https://api.closedeal.app',
  graphql: 'https://api.closedeal.app/graphql',
  subscription: 'ws://api.closedeal.app/graphql',
  upload: 'https://api.closedeal.app/upload',
};*/

export default configDefault;
