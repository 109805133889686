import gql from 'graphql-tag';

export const getAllPaymentTransactions = gql`
 query getAllPaymentTransactions{
  getAllPaymentTransactions{
      id
      payment_method{
        id
        name
      }
      user{
        id
        username
      }
      consumer{
        id
        username
      }
      amount
      payment_status
      braintree_transaction_id
    }
  }
`;
