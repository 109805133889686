import gql from 'graphql-tag';

export const getAdminById = gql `
  query getAdminById($id: Int!){
    getAdminById(id: $id){
      id
      avatar
      username
      email
      location
      telephone
      firstName
      lastName
      role
    }
  }
`;