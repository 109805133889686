import React, { Component } from "react";
import { graphql, compose } from "react-apollo";
import { Slide } from "react-slideshow-image";
import PropTypes from "prop-types";
import moment from "moment";
import * as _ from "lodash";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import { Link } from "react-router-dom";

import { FaEdit, FaSave } from "react-icons/fa";
// core components
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardAvatar from "../../../components/Card/CardAvatar";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import { getOfferByAdmin, getAllCategories } from "./queries";
import { updateOfferByAdmin } from "./mutation";
import styles from "./style";
import { Dialog } from "../../../components";

const OfferStatus = {
  PUBLISHED: "PUBLISHED",
  PAUSED: "PAUSED",
};

class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailOffer, listCategory } = this.props;
    this.state = {
      edit: false,
      detailOffer,
      categoryId: undefined,
      listCategory,
      seeMore: false,
      open: false,
      titleDialog: "",
      content: "",
      offerEndDetail: undefined,
      offerStartDetail: undefined,
      active: undefined,
      status: undefined,
    };
  }

  // Ham nay chay khi edit
  componentWillReceiveProps = (nextProps) => {
    const { detailOffer, listCategory } = this.props;
    const isDiff = !_.isEqual(nextProps.detailOffer, detailOffer);
    // if (nextProps.detailOffer !== detailOffer) {
    if (isDiff) {
      this.setState({ detailOffer: nextProps.detailOffer });
    }
    if (nextProps.listCategory !== listCategory) {
      this.setState({ listCategory: nextProps.listCategory });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSwitchChange = (name) => (_event, checked) => {
    this.setState({ [name]: checked });
  };

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  };

  onSave = () => {
    const {
      detailOffer,
      edit,
      categoryId,
      offerEndDetail,
      offerStartDetail,
      active,
      status,
    } = this.state;
    const { updateOfferByAdmin } = this.props;
    const { id } = detailOffer;
    this.setState({ edit: !edit });
    updateOfferByAdmin({
      variables: {
        input: {
          id,
          categoryId,
          offerEnd: offerEndDetail,
          offerStart: offerStartDetail,
          active,
          status:
            status === undefined
              ? undefined
              : status
              ? OfferStatus.PUBLISHED
              : OfferStatus.PAUSED,
        },
      },
      refetchQueries: [{ query: getOfferByAdmin, variables: { id } }],
    })
      .then((res) => {
        if (res) {
          this.setState({
            edit: false,
            open: true,
            content: "Completed",
            titleDialog: "Update Offer",
          });
          this.props.refetchDetailOffer();
        }
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: "Value invalid",
            titleDialog: "Erros Update Offer",
          });
        }
      });
  };

  onChangeTime = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  _renderStore = () => {
    const { classes } = this.props;
    const { detailOffer, seeMore } = this.state;
    const { stores } = detailOffer;
    return (
      <GridItem xs={12} sm={12} md={10}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Typography component="p" className={classes.textTitleStores}>
              Stores
            </Typography>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {stores && (
              <GridContainer>
                {!seeMore
                  ? stores.slice(0, 4).map((item) => (
                      <GridItem xs={12} sm={12} md={3} key={item.id}>
                        <Link to={{ pathname: `/detailStore/${item.id}` }}>
                          <Card profile>
                            <CardAvatar profile>
                              <Avatar
                                alt="Avartar"
                                src={item.pictures || ""}
                                className={classes.avatar}
                              />
                            </CardAvatar>
                            <CardBody profile>
                              <h6 className={classes.cardCategory}>Store</h6>
                              <Typography
                                component="p"
                                className={classes.cardTitle}
                              >
                                {item.name.toUpperCase()}
                              </Typography>
                              <Typography
                                component="p"
                                className={classes.textStore}
                              >
                                {item.location}
                              </Typography>
                              <Typography
                                component="p"
                                className={classes.textStore}
                              >
                                Phone: {item.phone}
                              </Typography>
                            </CardBody>
                          </Card>
                        </Link>
                      </GridItem>
                    ))
                  : stores.map((item) => (
                      <GridItem xs={12} sm={12} md={3} key={item.id}>
                        <Link to={{ pathname: `/detailStore/${item.id}` }}>
                          <Card profile>
                            <CardAvatar profile>
                              <Avatar
                                alt="Avartar"
                                src={item.pictures || ""}
                                className={classes.avatar}
                              />
                            </CardAvatar>
                            <CardBody profile>
                              <h6 className={classes.cardCategory}>Store</h6>
                              <Typography
                                component="p"
                                className={classes.cardTitle}
                              >
                                {item.name.toUpperCase()}
                              </Typography>
                              <Typography
                                component="p"
                                className={classes.textStore}
                              >
                                {item.location}
                              </Typography>
                              <Typography
                                component="p"
                                className={classes.textStore}
                              >
                                Phone: {item.phone}
                              </Typography>
                            </CardBody>
                          </Card>
                        </Link>
                      </GridItem>
                    ))}
              </GridContainer>
            )}
          </GridItem>
          {stores && stores.length > 4 && (
            <GridItem xs={12} sm={12} md={12}>
              {!seeMore && (
                <Typography
                  component="p"
                  className={classes.seeMore}
                  onClick={() => this.setState({ seeMore: true })}
                >
                  See More
                </Typography>
              )}
            </GridItem>
          )}
        </GridContainer>
      </GridItem>
    );
  };

  render() {
    const { classes, loading, loadingListCategory } = this.props;
    if (loading || loadingListCategory) return null;
    const {
      detailOffer,
      edit,
      categoryId,
      listCategory,
      open,
      titleDialog,
      content,
    } = this.state;
    console.log(`detailOffer`, detailOffer);
    const {
      images,
      title,
      quantities,
      offerPrice,
      offerTimer,
      saleOff,
      category,
      merchant,
      active, // boolean,
      status,
    } = detailOffer;
    let { offerStartDetail, offerEndDetail } = detailOffer;
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
    };
    let { description } = detailOffer || "";
    if (description) description = description.replace(/<(?:.|\n)*?>/gm, "");

    offerStartDetail = moment.utc(offerStartDetail).format("YYYY-MM-DD");
    offerEndDetail = moment.utc(offerEndDetail).format("YYYY-MM-DD");

    return (
      <div>
        <GridContainer justify="center">
          <Dialog
            title={titleDialog}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                    {images ? (
                      <GridItem xs={12} sm={12} md={12}>
                        {images.length > 0 ? (
                          <Slide {...properties}>
                            {images.map((item, index) => (
                              <div className={classes.itemSlide} key={index}>
                                <img src={item.full} alt={`${index}`} />
                              </div>
                            ))}
                          </Slide>
                        ) : (
                          <Typography className={classes.textVisited}>
                            Not exit Image Of Offer
                          </Typography>
                        )}
                      </GridItem>
                    ) : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={7}>
                    <List>
                      <ListItem>
                        <Typography component="p" className={classes.textTitle}>
                          {title}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <GridContainer style={{ width: "100%" }}>
                          <GridItem xs={12} sm={12} md={6}>
                            <Typography
                              component="p"
                              className={classes.textOfferPrice}
                            >
                              ${offerPrice || ""}
                            </Typography>
                          </GridItem>
                        </GridContainer>
                      </ListItem>
                      <ListItem>
                        <ExpansionPanel className={classes.boxDescription}>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={classes.headDescription}
                          >
                            <Typography className={classes.textVisited}>
                              Description
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails
                            className={classes.bodyDescription}
                          >
                            <Typography> {description || ""}</Typography>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </ListItem>
                      <ListItem>
                        <GridContainer style={{ width: "100%" }}>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Quantity: {quantities || ""}
                            </Typography>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Offer Timer: {offerTimer || ""}
                            </Typography>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Sale: {saleOff || ""}
                            </Typography>
                          </GridItem>
                        </GridContainer>
                      </ListItem>
                      <ListItem>
                        <GridContainer style={{ width: "100%" }}>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Offer Start:
                            </Typography>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              label="Next appointment"
                              type="date"
                              defaultValue={offerStartDetail}
                              className={classes.textField}
                              onChange={this.onChangeTime("offerStartDetail")}
                              disabled={!edit}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </ListItem>
                      <ListItem>
                        <GridContainer style={{ width: "100%" }}>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Offer End:
                            </Typography>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <TextField
                              label="Next appointment"
                              type="date"
                              defaultValue={offerEndDetail}
                              className={classes.textField}
                              onChange={this.onChangeTime("offerEndDetail")}
                              disabled={!edit}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </ListItem>
                      <ListItem>
                        {!edit ? (
                          <Typography
                            component="p"
                            className={classes.textOfferQuantity}
                          >
                            Category: {category ? category.name : ""}
                          </Typography>
                        ) : (
                          <GridContainer style={{ width: "100%" }}>
                            <GridItem xs={12} sm={12} md={4}>
                              <Typography
                                component="p"
                                className={classes.textOfferQuantity}
                              >
                                Select Category:{" "}
                              </Typography>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8}>
                              <form className={classes.root} autoComplete="off">
                                <FormControl className={classes.formControl}>
                                  <Select
                                    value={categoryId || category.id}
                                    onChange={this.handleChange}
                                    inputProps={{
                                      name: "categoryId",
                                      id: "categoryId-simple",
                                    }}
                                  >
                                    {listCategory.length > 0
                                      ? listCategory.map((item, i) => (
                                          <MenuItem key={i} value={item.id}>
                                            {item.name}
                                          </MenuItem>
                                        ))
                                      : null}
                                  </Select>
                                </FormControl>
                              </form>
                            </GridItem>
                          </GridContainer>
                        )}
                      </ListItem>
                      <ListItem>
                        <Typography
                          component="p"
                          className={classes.textOfferQuantity}
                        >
                          Merchant: {merchant ? merchant.username : ""}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <GridContainer style={{ width: "100%" }}>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Offer Active:
                            </Typography>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Switch
                              classes={{ root: classes.switchRoot }}
                              defaultChecked={active}
                              disabled={!edit}
                              onChange={this.handleSwitchChange("active")}
                              name="active"
                            />
                          </GridItem>
                        </GridContainer>
                      </ListItem>
                      <ListItem>
                        <GridContainer style={{ width: "100%" }}>
                          <GridItem xs={12} sm={12} md={4}>
                            <Typography
                              component="p"
                              className={classes.textOfferQuantity}
                            >
                              Offer Public:
                            </Typography>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6}>
                            <Switch
                              classes={{ root: classes.switchRoot }}
                              defaultChecked={status === OfferStatus.PUBLISHED}
                              disabled={!edit}
                              onChange={this.handleSwitchChange("status")}
                              name="status"
                            />
                          </GridItem>
                        </GridContainer>
                      </ListItem>
                      <ListItem className={classes.buttonEdit}>
                        {!edit ? (
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.onEdit}
                          >
                            <Typography
                              component="p"
                              className={classes.textEdit}
                            >
                              Edit
                            </Typography>
                            <FaEdit color="#ffffff" size={16} />
                          </Button>
                        ) : (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => this.setState({ edit: false })}
                              >
                                <Typography
                                  component="p"
                                  className={classes.textEdit}
                                >
                                  Cancel
                                </Typography>
                              </Button>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.onSave}
                              >
                                <Typography
                                  component="p"
                                  className={classes.textEdit}
                                >
                                  Save
                                </Typography>
                                <FaSave color="#ffffff" size={16} />
                              </Button>
                            </GridItem>
                          </GridContainer>
                        )}
                      </ListItem>
                    </List>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          {this._renderStore()}
        </GridContainer>
      </div>
    );
  }
}

// Khai bao PropsType

// Mark: default props
Detail.defaultProps = {
  classes: {},
  listCategory: [],
  detailOffer: {},
  loading: false,
  loadingListCategory: false,
  updateOfferByAdmin: () => {},
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  listCategory: PropTypes.array,
  detailOffer: PropTypes.object,
  loading: PropTypes.bool,
  loadingListCategory: PropTypes.bool,
  updateOfferByAdmin: PropTypes.func,
};

// Mark: Map data grapql to props
const DetailOfferById = graphql(getOfferByAdmin, {
  props: ({ data, ownProps }) => ({
    loading: data.loading,
    detailOffer: data.getOfferByAdmin,
    refetchDetailOffer: () => {
      data.refetch({ id: Number(ownProps.match.params.id) });
    },
  }),
  options: (props) => {
    return {
      variables: { id: Number(props.match.params.id) },
      fetchPolicy: "cache-and-network",
    };
  },
});

const ListCategory = graphql(getAllCategories, {
  props: ({ data }) => ({
    loadingListCategory: data.loading,
    listCategory: data.getAllCategories,
  }),
});

const Update = graphql(updateOfferByAdmin, { name: "updateOfferByAdmin" });

export default withStyles(styles)(
  compose(
    DetailOfferById,
    ListCategory,
    Update
  )(Detail)
);
