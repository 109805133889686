import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Grid from '@material-ui/core/Grid';

// core components
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import Error from '../../../../components/Error/Error';
import { Dialog } from '../../../../components';

import { deacticeUser } from './mutations';
import styles from './styles';

class ActiveUser extends Component {
  state = {
    username: '',
    active: false,
    error: false,
    showDialog: false,
    contentActive: '',
  };

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSubmit = () => {
    const { username } = this.state;
    const { deacticeUser } = this.props;
    deacticeUser({
      variables: {
        username,
      },
    }).then((res) => {
      if (res) this.setState({ contentActive: 'Completed', showDialog: true });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ loading: false, contentActive: graphQLErrors[0].message, showDialog: true });
      }
    });
  };

  againActive = () => {
    this.setState({ showDialog: false });
  }

  continuesActive = () => {
    this.setState({ showDialog: false, active: true });
  }

  render() {
    const { classes } = this.props;
    const {
      username, loading, error, contentActive, showDialog, active,
    } = this.state;
    return (
      <GridContainer justify="center" alignItems="center">
        <Dialog
          title="Active User"
          content={contentActive}
          open={showDialog}
          again={this.againActive}
          continues={this.continuesActive}
        />
        { active ? <Redirect to="/user" /> : null }
        <GridItem xs={10} sm={8} md={6} lg={4}>
          <Card>
            <CardHeader>Active</CardHeader>
            <CardBody>
              <Error error={error} />
              <ValidatorForm
                ref="form"
                onSubmit={() => this.handleSubmit()}
              >
                <fieldset disabled={loading} style={{ border: 0 }}>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <AccountCircle className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Username"
                        fullWidth
                        onChange={this.handleChange('username')}
                        name="username"
                        value={username}
                        validators={['required']}
                        errorMessages={[
                          'this field is required',
                          'username is not valid',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justify="center">
                    <Grid item>
                      <Button
                        type="submit"
                        className={classes.submitButton}
                      >
                            Active
                      </Button>
                    </Grid>
                  </Grid>
                </fieldset>
              </ValidatorForm>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

// Mark: default props
ActiveUser.defaultProps = {
  classes: {},
  deacticeUser: () => {},
};

// Mark: PropsType
ActiveUser.propTypes = {
  classes: PropTypes.object,
  deacticeUser: PropTypes.func,
};

// Mark: Map data grapql to props
export default withStyles(styles)(graphql(deacticeUser, { name: 'activeUser' })(ActiveUser));
