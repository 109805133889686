import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import { FaEdit } from 'react-icons/fa';
// core components
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import { Dialog } from '../../../components';

import { addCategory } from './mutations';
import { getAllCategories } from '../TableCategory/queries';
import styles from './styles';

class Register extends Component {
  state = {
    name: '',
    reservationPercentage: '',
    bonusPercentage: '',
    sharePercentage: '',
    closedealPercentage: '',
    isAdded: false,
    open: false,
    title: '',
    content: '',
  };

  onSubmit = () => {
    const {
      name, reservationPercentage, bonusPercentage, sharePercentage, closedealPercentage,
    } = this.state;
    const { addCategory } = this.props;
    addCategory({
      variables: {
        input: {
          name,
          categoryGroupId: 1,
          reservationPercentage: Number(reservationPercentage),
          bonusPercentage: Number(bonusPercentage),
          sharePercentage: Number(sharePercentage),
          closedealPercentage: Number(closedealPercentage),
        },
      },
      refetchQueries: [{ query: getAllCategories }],
    }).then(async (res) => {
      if (res.data.addCategory) {
        this.setState({ isAdded: true });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: graphQLErrors[0].message, title: 'Error' });
      }
    });
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };


  render() {
    const { classes } = this.props;
    const {
      name, reservationPercentage, bonusPercentage, sharePercentage, closedealPercentage, isAdded, open, content, title,
    } = this.state;
    return (
      <GridContainer justify="center" alignItems="center">
        { isAdded ? <Redirect to="/categories" /> : null }
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <GridItem xs={10} sm={8} md={6} lg={4}>
          <Card>
            <CardHeader>Add Category</CardHeader>
            <CardBody>
              <ValidatorForm
                ref="form"
                onSubmit={() => this.onSubmit()}
                // onError={errors => console.log(errors)}
              >
                <fieldset style={{ border: 0 }}>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Name Category"
                        fullWidth
                        onChange={this.handleChange('name')}
                        name="name"
                        value={name}
                        validators={['required']}
                        errorMessages={[
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Reservation Percentage (%)"
                        fullWidth
                        onChange={this.handleChange('reservationPercentage')}
                        name="reservationPercentage"
                        value={reservationPercentage}
                        validators={['required', 'isFloat']}
                        errorMessages={[
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.wrapper}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Bonus Percentage (%)"
                        fullWidth
                        onChange={this.handleChange('bonusPercentage')}
                        name="bonusPercentage"
                        value={bonusPercentage}
                        validators={['required', 'isFloat']}
                        errorMessages={['this field is required']}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.wrapper}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Share Percentage (%)"
                        fullWidth
                        onChange={this.handleChange('sharePercentage')}
                        name="sharePercentage"
                        value={sharePercentage}
                        validators={['required', 'isFloat']}
                        errorMessages={[
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.wrapper}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Closedeal Percentage (%)"
                        fullWidth
                        onChange={this.handleChange('closedealPercentage')}
                        name="closedealPercentage"
                        value={closedealPercentage}
                        validators={['required', 'isFloat']}
                        errorMessages={[
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Button
                        type="submit"
                        className={classes.submitButton}
                      >
                        Add Category
                      </Button>
                    </Grid>
                  </Grid>
                </fieldset>
              </ValidatorForm>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

// Mark: default props
Register.defaultProps = {
  classes: {},
  addCategory: () => {},
};

// Mark: PropsType
Register.propTypes = {
  classes: PropTypes.object,
  addCategory: PropTypes.func,
};

export default withWidth()(withStyles(styles)(graphql(addCategory, { name: 'addCategory' })(Register)));
