import gql from 'graphql-tag';

export const getAllConsumerActions = gql`
  query getAllConsumerActions{
    getAllConsumerActions{
      id
      action
      objectType
      objectId
      merchant {
        username
      }
      consumer{
        id
        username
      }
      createdDate
    }
}
`;

export const getDealById = gql`
  query getDealById($id: Int!){
    getDealById(id: $id){
      id
      consumer {
        username
      }
      merchant {
        username
      }
      cancelReason
      customCancelReason
      isMerchantCancel
    }
  }
`;
