import { colors } from '../../../../constants';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: 0,
    fontSize: 14,
    marginTop: 0,
    marginBottom: 0,
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: 0,
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: 3,
    textDecoration: 'none',
  },
  headCard: {
    backgroundColor: colors.parimary,
  },
  button: {
    backgroundColor: colors.dark,
  },
  text: {
    color: '#ffffff',
    marginRight: 4,
  },
  uploadImage: {
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    padding: '0px 0px',
  },
  buttonUpload: {
    backgroundColor: colors.dark,
    margin: '0px 0px',
  },
  bigAvatar: {
    width: 140,
    height: 140,
  },
  avatar: {
    width: 120,
    height: 120,
  },
  textTitleStores: {
    color: colors.parimary,
    textAlign: 'center',
    fontSize: 40,
    minHeight: 40,
    fontWeight: 800,
    fontFamily: 'Roboto Slab',
    textDecoration: 'none',
    marginBottom: 40,
    marginTop: 40,
  },
  seeMore: {
    color: '#3C4858',
    textAlign: 'center',
    fontSize: 18,
    opacity: 0.68,
    minHeight: 28,
    fontWeight: 500,
    fontFamily: 'Roboto Slab',
    textDecoration: 'none',
    marginBottom: 40,
    '&:hover, &:visited': {
      color: colors.parimary,
    },
  },
  cardTitle: {
    color: '#3C4858',
    marginTop: '.625rem',
    minHeight: 'auto',
    fontWeight: 700,
    fontFamily: 'Roboto Slab',
    marginBottom: '0.75rem',
    textDecoration: 'none',
  },
  textStore: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#999',
    fontSize: 16,
    margin: 4,
  },
};
export default styles;
