import gql from 'graphql-tag';
import React from 'react';
import { Mutation } from 'react-apollo';

export const loginAdmin = gql`
 mutation loginAdmin($username: String!, $password: String!){
  loginAdmin(username: $username, password: $password){
    token
  }
}
`;

export const LoginAdmin = ({ children }) => (
  <Mutation mutation={loginAdmin}>
    {
      (login, loading) => {
        return children(login, loading);
      }
    }
  </Mutation>
);
