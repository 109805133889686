import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose, Query } from 'react-apollo';
import _, { truncate } from 'lodash';
import "date-fns";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import { CSVLink } from "react-csv";
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Search from '@material-ui/icons/Search';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  InlineDatePicker
} from "material-ui-pickers";

import { FaFileExport } from "react-icons/fa";

import { getAllUserActions, getDetailUserCloseDealAction, getDetailUserTransferAction } from './queries';
import { Dialog, Spiner } from '../../../../components';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import ActionDetailDialogContent from "./ActionDetailDialogContent"
import styles from './styles';
import { colors } from '../../../../constants';
import { isNonNullType } from 'graphql';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TableAction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: 'TRANSACTION DETAIL',
      actionDialogType: '',
      content: '',
      search: '',
      type: '',
      action: '',
      role: '',
      page: 0,
      limit: 10,
      data: [],
      sortByNameOrder: null,
      sortByDateOrder: null,
      fromDate: null,
      toDate: null,
      isActionDetailBoxShown: false,
      dealId: null,
      objectId: null,
      merchantId: null,
    }

    this.actionTypes = {
      CLOSEDEAL: 'CLOSEDEAL',
      TRANSFER_COINS: 'TRANSFER_COINS'      
    }
  }

  onOk = () => {
    this.setState({ open: false, objectId: null, merchantId: null, actionDialogType: isNonNullType });
  }

  handleDetailClick = ({ objectId, merchantId, actionDialogType }) => {
    this.setState({ open: true, objectId, merchantId, actionDialogType });
  }

  renderCloseDealDetail = ({ objectId, merchantId }) => {
    return (
      <Query 
        query={getDetailUserCloseDealAction} 
        variables={{ objectId, merchantId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Spiner />
          }
          if (error) {
            return error.message ? error.message : JSON.stringify(error)
          }

          data = data.getDetailUserCloseDealAction;
          return (
            <ActionDetailDialogContent data={data}>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                Offer Title: {data.deal.offer.title }
              </Typography>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                Offer Quantities: {data.deal.qty}
              </Typography>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                Deal ID: {data.deal.sku}
              </Typography>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                Consumer Username: {data.deal.consumer.username }
              </Typography>
            </ActionDetailDialogContent>
          )
        }}
      </Query>
    )
  }

  renderReservedShareOfferDetail = ({ objectId }) => {
    return (
      <Query query={getDetailUserTransferAction} variables={{ objectId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Spiner />
          }
          if (error) {
            return error.message ? error.message : JSON.stringify(error)
          }

          data = data.getDetailUserTransferAction;
          return (
            <ActionDetailDialogContent data={data}>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                From: {''}
              </Typography>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                To: {''}
              </Typography>
            </ActionDetailDialogContent>
          )
        }}
      </Query>
    )
  }

  renderTransferDetail = ({ objectId }) => {
    return (
      <Query query={getDetailUserTransferAction} variables={{ objectId }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <Spiner />
          }
          if (error) {
            return error.message ? error.message : JSON.stringify(error)
          }

          data = data.getDetailUserTransferAction;
          return (
            <ActionDetailDialogContent data={data}>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                From: {data.transfered.name}
              </Typography>
              <Typography style={{ fontSize: 14 }} variant='caption'>
                To: {data.received.name}
              </Typography>
            </ActionDetailDialogContent>
          )
        }}
      </Query>
    )
  }

  renderDialogChildren = () => {
    if (!this.state.open) return null    
    console.log(this.state)
    if (this.state.actionDialogType === this.actionTypes.CLOSEDEAL) {      
      return this.renderCloseDealDetail({
        merchantId: this.state.merchantId,
        objectId: this.state.objectId
      })
    }

    if(this.state.actionDialogType === this.actionTypes.SHARED_OFFER){
      console.log(this.state)
      return this.renderReservedShareOfferDetail({objectId: this.state.objectId})

    }


    if (this.state.actionDialogType === this.actionTypes.TRANSFER_COINS ) {
      return this.renderTransferDetail({
        objectId: this.state.objectId
      })
    }
    
    return null;
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  onSortByName = () => {
    let { data, sortByNameOrder } = this.state;
    if (data) {
      sortByNameOrder = sortByNameOrder === "asc" ? "desc" : "asc"
      const newData = _.orderBy(data, obj => obj.consumer.username, sortByNameOrder);
      this.setState({ data: newData, sortByNameOrder, sortByDateOrder: null })
    }
  }

  onSortByDate = () => {
    let { data, sortByDateOrder } = this.state;
    if (data) {
      sortByDateOrder = sortByDateOrder === "desc" ? "asc" : "desc"
      const newData = _.orderBy(data, obj => obj.createdDate, sortByDateOrder);
      this.setState({ data: newData, sortByDateOrder, sortByNameOrder: null })
    }
  }

  onExportFile = () => {
    this.csvLinkRef.link.click();
  }

  onFilterType = (e) => {
    this.setState({ type: e.target.value })
  }

  onFilterAction = (e) => {
    this.setState({ action: e.target.value })
  }

  onFilterRole = (e) => {
    this.setState({ role: e.target.value })
  }

  resetFilterByDate = () => {
    this.setState({
      fromDate: null,
      toDate: null
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }

  componentDidUpdate() {
    if (this.state.data.length === 0) {
      let data = this.props.listAllAction;
      data = _.orderBy(data, obj => obj.createdDate, "desc");
      this.setState({ data, sortByDateOrder: "desc" });
    }
  }

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    const {
      title, open, search, type, action, role, page, limit, fromDate, toDate
    } = this.state;

    // filter
    if (search) data = _.filter(data, obj => obj.username.toLowerCase().match(search.toLowerCase()));
    if (type) data = _.filter(data, obj => obj.objectType.toLowerCase().match(type.toLowerCase()));
    if (action) data = _.filter(data, obj => obj.action.toLowerCase().match(action.toLowerCase()));
    if (role) data = _.filter(data, obj => obj.role.toLowerCase().match(role.toLowerCase()));

    if (fromDate)
      data = _.filter(data, obj => {
        const actionDate = new Date(obj.createdDate);
        return moment(actionDate).isSameOrAfter(fromDate, "day");
      })

    if (toDate)
      data = _.filter(data, obj => {
        const actionDate = new Date(obj.createdDate);
        return moment(actionDate).isSameOrBefore(toDate, "day");
      })

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <Dialog
            title={title}
            listChildren={this.renderDialogChildren()}
            open={open}
            onOk={() => this.onOk()}
          />
          <div className={classes.searchWrapper}>
            <Typography variant="body1" className={classes.filterLabel}>Name Filter</Typography>
            <CustomInput
              formControlProps={{
                className: `${classes.margin} ${classes.search}`,
                style: { margin: 0 }
              }}
              inputProps={{
                value: search,
                onChange: this.onSearch,
                placeholder: 'Search',
                inputProps: {
                  'aria-label': 'Search',
                },
              }}
            />
            <Button aria-label="edit" disabled>
              <Search />
            </Button>
            <Button variant="contained" color="primary" className={classes.button} onClick={(e) => this.onExportFile()}>
              <Typography component="p" className={classes.text}>Export</Typography>
              <FaFileExport color="#ffffff" size={16} />
            </Button>
            <CSVLink
              style={{ display: "none" }}
              data={data}
              filename="merchant-action.csv"
              ref={(r) => this.csvLinkRef = r}
            >
              Export
            </CSVLink>
          </div>
          <div>
            <Typography variant="body1" className={classes.filterLabel}>Date Filter</Typography>
            <Grid key="date-filter" container alignContent="flex-start">
              <Grid key="date-input" container item xs={12} md={8}>
                <Grid key="from" item xs={12} sm={6}>
                  <InlineDatePicker
                    // keyboard
                    label="From"
                    id="date-from"
                    onChange={(date) => this.setState({ fromDate: date })}
                    format="yyyy-MM-dd"
                    value={this.state.fromDate}
                  />
                </Grid>
                <Grid key="to" item xs={12} sm={6}>
                  <InlineDatePicker
                    // keyboard
                    label="To"
                    id="date-to"
                    onChange={(date) => this.setState({ toDate: date })}
                    format="yyyy-MM-dd"
                    value={this.state.toDate}
                  />
                </Grid>
              </Grid>
              <Grid key="reset-date-filter" container item xs={12} md={4} className={classes.filterLabel}>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.resetFilterByDate}>
                  Reset
                </Button>
              </Grid>
            </Grid>
          </div>
          <div>
            <Typography variant="body1" className={classes.filterLabel}>Other Filters</Typography>
            <Grid key="type-filter" container alignItems="flex-end">
              <Grid item xs={12} md={4}>
                <CustomInput
                  formControlProps={{
                    className: `${classes.margin} ${classes.search}`,
                    style: { margin: 0 }
                  }}
                  inputProps={{
                    value: role,
                    onChange: this.onFilterRole,
                    placeholder: 'Role',
                    inputProps: {
                      'aria-label': 'Role',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  formControlProps={{
                    className: `${classes.margin} ${classes.search}`,
                    style: { margin: 0 }
                  }}
                  inputProps={{
                    value: type,
                    onChange: this.onFilterType,
                    placeholder: 'Type',
                    inputProps: {
                      'aria-label': 'Type',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  formControlProps={{
                    className: `${classes.margin} ${classes.search}`,
                    style: { margin: 0 }
                  }}
                  inputProps={{
                    value: action,
                    onChange: this.onFilterAction,
                    placeholder: 'Action',
                    inputProps: {
                      'aria-label': 'Action',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>

          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell>Username</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Role </CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Type</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Action</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Detail</CustomTableCell>
                  <CustomTableCell align="right" className={classes.columnRow}>Created Date</CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * limit, page * limit + limit).map((row) => {
                  const isViewDetailDisabled = !Object.values(this.actionTypes).some(v => row.action === v);                  

                  return (
                    <TableRow className={classes.row} key={row.id}>
                      <CustomTableCell component="th" scope="row">
                        {row.username}
                      </CustomTableCell>
                      <CustomTableCell align="right" className={classes.colTable}>{row.role}</CustomTableCell>
                      <CustomTableCell align="right" className={classes.colTable}>{row.objectType}</CustomTableCell>
                      <CustomTableCell align="right" className={classes.colTable}>{row.action}</CustomTableCell>
                      <CustomTableCell align="right" className={classes.colTable}>
                        <Button
                          disabled={isViewDetailDisabled}
                          variant="contained"
                          color="primary" 
                          style={{ margin: 0 }} 
                          className={classes.button}
                          onClick={() => {
                            const merchantId = (row.user.merchant && row.user.merchant.id) ? row.user.merchant.id : row.userId;
                            this.handleDetailClick({ objectId: row.objectId, merchantId, actionDialogType: row.action })
                          }}>
                          detail
                        </Button>
                      </CustomTableCell>
                      <CustomTableCell align="right" className={classes.colTable}>{row.createdDate}</CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={data.length}
              rowsPerPage={limit}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

// Mark: default props
TableAction.defaultProps = {
  classes: {},
  listAllAction: [],
};

// Mark: PropsType
TableAction.propTypes = {
  classes: PropTypes.object,
  listAllAction: PropTypes.array,
};

const ListAll = graphql(getAllUserActions, {
  props: ({ data }) => ({
    loading: data.loading,
    listAllAction: data.getAllUserActions,
  }),
});


export default withStyles(styles)(compose(ListAll)(TableAction));
