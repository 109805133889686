import gql from 'graphql-tag';

export const getConstantsSystems = gql`
  query getConstantsSystems{
  getConstantsSystems{
    coinShareApp
    coinShareOffer
    coinSharePromo
    coinReviewOffer
    coinReceiveShare
    walletConsumer
    walletUser
    shareOfferCoinMerchant
    shareAppCoinMerchant
    shareOfferCoinForConsumer
  }
}
`;
