import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import { FaEdit, FaPlus } from 'react-icons/fa';
import { getAllCategories } from './queries';
import { Dialog } from '../../../components';
import CustomInput from '../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../constants';
import { updateCategory } from '../DetailCategory/mutation';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TableCategory extends Component {
  state={
    open: false,
    title: '',
    content: '',
    search: '',
    page: 0,
    limit: 10,
  }

  onOk = () => {
    this.setState({ open: false });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  onUpdate = (row, archived) => {
    const { updateCategory } = this.props;
    updateCategory({
      variables: {
        input: {
          id: row.id, archived,
        },
      },
      refetchQueries: [{ query: getAllCategories }],
    })
      .then((res) => {
        if (res) {
          this.setState({
            open: true, content: 'Completed', title: 'Updated Category',
          });
        }
      }).catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({ open: true, content: graphQLErrors[0].message, title: 'Error Update' });
        }
      });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }

  renderToolBar = () => {
    const { classes } = this.props;
    const { search } = this.state;
    return (
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`,
          }}
          inputProps={{
            value: search,
            onChange: this.onSearch,
            placeholder: 'Search',
            inputProps: {
              'aria-label': 'Search',
            },
          }}
        />
        <Button aria-label="edit" disabled>
          <Search />
        </Button>
        <Link to={{ pathname: '/addCategory' }}>
          <Button aria-label="Add">
            <FaPlus color={colors.parimary} size={16} />
          </Button>
        </Link>
      </div>
    );
  }

  render() {
    const { classes, listAllCategory } = this.props;
    let data = listAllCategory || [];
    data = _.orderBy(data, obj => obj.name.toLowerCase(), 'asc');
    const {
      title, content, open, search, page, limit,
    } = this.state;
    if (search) data = _.filter(data, obj => obj.name.toLowerCase().match(search.toLowerCase()));
    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        {this.renderToolBar()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Name</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Reservation </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Bonus</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Share</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Closedeal</CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>Edit</CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>Active/Deactive</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.name}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.reservationPercentage}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.bonusPercentage}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.sharePercentage}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.closedealPercentage}</CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      <Link to={{ pathname: `/detailCategory/${row.id}` }}>
                        <Button variant="contained" color="primary" className={classes.button}>
                          <Typography component="p" className={classes.text}>Edit</Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      {
                        row.archived === true
                          ? (
                            <Button variant="contained" color="primary" className={classes.buttonActive} onClick={() => this.onUpdate(row, false)}>
                              <Typography component="p" className={classes.text}>Active</Typography>
                            </Button>
                          )
                          : (
                            <Button variant="contained" color="primary" className={classes.buttonDeactive} onClick={() => this.onUpdate(row, true)}>
                              <Typography component="p" className={classes.text}>Deactivate</Typography>
                            </Button>
                          )
                      }
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// Mark: default props
TableCategory.defaultProps = {
  classes: {},
  listAllCategory: [],
  updateCategory: () => {},
};

// Mark: PropsType
TableCategory.propTypes = {
  classes: PropTypes.object,
  listAllCategory: PropTypes.array,
  updateCategory: PropTypes.func,
};

// Mark: Map data grapql to props
const ListAll = graphql(getAllCategories, {
  props: ({ data }) => ({
    loading: data.loading,
    listAllCategory: data.getAllCategories,
  }),
});
const Upadate = graphql(updateCategory, { name: 'updateCategory' });

export default withStyles(styles)(compose(ListAll, Upadate)(TableCategory));
