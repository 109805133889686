import React from "react";
import _ from "lodash";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

export default function ActionDetailDialogContent({ data, children }) {
  const date = moment(parseInt(data.createdAt)).format("MM/DD/YYYY");
  const time = moment(parseInt(data.createdAt)).format("LT");

  return (
    <Grid container={true} direction="column">
      <Grid item container direction="row">
        <b>Date:&nbsp;&nbsp;</b>
        <span> {date}</span>
        &nbsp;&nbsp;-&nbsp;&nbsp;
        <b>Time:&nbsp;&nbsp;</b>
        <span>{time}</span>
      </Grid>
      <Grid item container direction="row">
        <b>Transaction type:&nbsp;</b>
        <b>
          <i>{_.capitalize(data.type.replace("_", " "))}</i>
        </b>
      </Grid>
      <Grid item container>
        <b>Sent:&nbsp;</b>
        <span>{data.amount}</span>
      </Grid>
      <Divider variant="middle" />
      <Grid item container direction="column">
        <b>Detail: </b>
        <div>{children}</div>
      </Grid>
    </Grid>
  );
}
