import gql from 'graphql-tag';

export const getAllOffersByAdmin = gql`
 query getAllOffersByAdmin{
  getAllOffersByAdmin{
      id
      title
      offerPrice
      saleOff
      quantities
      status
      active
    }
  }
`;
