import gql from 'graphql-tag';

export const getTransactionsOfAdmin = gql`
  query getTransactionsOfAdmin($input: inputTransactionAdmin){
  getTransactionsOfAdmin(input: $input){
    id
    amount
    createdDate
    balance
    type
    merchant{
      id
      username
    }
  }
}
`;

export const getTransactionByIdOfAdmin = gql`
  query getTransactionByIdOfAdmin($id: Int!) {
    getTransactionByIdOfAdmin(id: $id) {
      id
      amount
      isAdded
      createdDate
      balance
      type
      transfered {
        name
      }
      received {
        name
      }
      consumer {
        id
        username
      }
      merchant {
        id
        username
      }
      deal {
        id
        sku
        qty
        cancelReason
        customCancelReason
        offer {
          id
          quantities
          title
        }
        consumer {
          id
          username
        }
      }
    }
  }
`
