import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import { FaEdit } from 'react-icons/fa';
// core components
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import Button from '../../../../components/CustomButtons/Button';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { getPromotionById } from './queries';
import { getAllPromotions } from '../TablePromotionCode/queries';
import { updatePromotion } from './mutation';
import styles from './style';
import { Dialog } from '../../../../components';


class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailPromotionCode } = this.props;
    this.state = {
      edit: false,
      code: detailPromotionCode ? detailPromotionCode.code : '',
      description: detailPromotionCode ? detailPromotionCode.description : '',
      open: false,
      title: '',
      content: '',
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { detailPromotionCode } = this.props;
    if (nextProps.detailPromotionCode !== detailPromotionCode) {
      const { detailPromotionCode } = nextProps;
      this.setState({
        code: detailPromotionCode ? detailPromotionCode.code : '',
        description: detailPromotionCode ? detailPromotionCode.description : '',
      });
    }
  }

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSaveProfile = async () => {
    const { code, description } = this.state;
    const { match, updatePromotion } = this.props;
    const { id } = match.params;
    updatePromotion({
      variables: {
        input: {
          id,
          code,
          description,
        },
      },
      refetchQueries: [{ query: getPromotionById, variables: { id } }, { query: getAllPromotions }],
    }).then(() => {
      this.setState({
        edit: false, open: true, content: 'Completed', title: 'Update Promotion Code',
      });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', title: 'Erros Update Promotion Code' });
      }
    });
  }


  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      code, description, title, open, content, edit,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={title}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit Promotion Code' : 'Detail Promotion Code'}</h4>
                { edit ? <p className={classes.cardCategoryWhite}>Complete Promotion Code</p> : null }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Code"
                      id="code"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: code || '',
                        name: 'code',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Description"
                      id="description"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: description || '',
                        name: 'description',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              {
                edit
                  ? (
                    <CardFooter>
                      <Button className={classes.button} onClick={this.onSaveProfile}>Update Promotion Code</Button>
                    </CardFooter>
                  ) : null
              }
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Promotion Code</h6>

                <Button round className={classes.button} onClick={this.onEdit}>
                  <Typography component="p" className={classes.text}>{!edit ? 'Edit' : 'Cancel'}</Typography>
                  <FaEdit color="#ffffff" size={16} />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  updatePromotion: () => {},
  detailPromotionCode: {},
  match: {},
  loading: false,
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  updatePromotion: PropTypes.func,
  detailPromotionCode: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool,
};

// Mark: Map data grapql to props
const DetailPromotionCode = graphql(getPromotionById, {
  props: ({ data }) => ({
    loading: data.loading,
    detailPromotionCode: data.getPromotionById,
  }),
  options: (props) => {
    return ({ variables: { id: props.match.params.id }, fetchPolicy: 'cache-and-network' });
  },
});

const Update = graphql(updatePromotion, { name: 'updatePromotion' });

export default withStyles(styles)(compose(DetailPromotionCode, Update)(Detail));
