import gql from 'graphql-tag';

export const deacticeUser = gql`
  mutation deacticeUser($id: Int,$username: String ){
    deactivateUser( id: $id, username: $username){
      id
    }
  }
`;

