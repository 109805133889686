import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

import { updateReferralCode } from './mutation';
import { getAllReferralCodeByAdmin } from './queries';
import { Dialog } from '../../../../components';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../../constants';
import config from '../../../../config';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TableReferral extends Component {
  state={
    open: false,
    title: '',
    content: '',
    search: '',
    searchUsername: '',
    page: 0,
    limit: 10,
    showList: false,
    listConsumer: [],
  }

  onChangeStatus = (row, active) => {
    const { id } = row;
    const { updateReferralCode } = this.props;
    updateReferralCode({
      variables: {
        id, active,
      },
      refetchQueries: [{ query: getAllReferralCodeByAdmin }],
    }).then((res) => {
      if (res) this.setState({ open: true, content: 'Completed', title: `${active ? 'Active' : 'Deactive'} Referral Code` });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: graphQLErrors[0].message, title: `Erros ${active ? 'Active' : 'Deactive'} Referral Code` });
      }
    });
  }

  onOk = () => {
    this.setState({ open: false, showList: false });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  onSearchUsername = (event) => {
    this.setState({ searchUsername: event.target.value });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }

  onExportFile = () => {
    window.open(`${config.uri}/download-promo-sales`);
  }

  _rederShowListConsumer = () => {
    const { showList, listConsumer } = this.state;
    const title = 'List Consumer Used';
    const children = (
      <div>
        {
          listConsumer.length > 0
          && listConsumer.map((item, i) => (
            <div key={i}>{item.username} used: {item.usageCount}</div>
          ))
        }
      </div>
    );
    return (
      <Dialog
        title={title}
        listChildren={children}
        open={showList}
        onOk={() => this.onOk()}
      />
    );
  }

  _renderToolBar = () => {
    const { classes } = this.props;
    const { search, searchUsername } = this.state;
    return (
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`,
          }}
          inputProps={{
            value: search,
            onChange: this.onSearch,
            placeholder: 'Search',
            inputProps: {
              'aria-label': 'Search',
            },
          }}
        />
        <Button disabled>
          <Search />
        </Button>

        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`,
          }}
          inputProps={{
            value: searchUsername,
            onChange: this.onSearchUsername,
            placeholder: 'Username',
            inputProps: {
              'aria-label': 'Username',
            },
          }}
        />
        <Button disabled>
          <Search />
        </Button>        
      </div>
    );
  }

  render() {
    const { classes, getAllReferralCodeByAdmin } = this.props;
    let data = getAllReferralCodeByAdmin || [];
    data = _.orderBy(data, obj => obj.id, 'desc');
    const {
      title, content, open, search, searchUsername, page, limit,
    } = this.state;
    if (search) data = _.filter(data, obj => obj.code.toLowerCase().match(search.toLowerCase()));
    if (searchUsername) {
      data = _.filter(data, function (obj) {
        const username = (obj.consumer && obj.consumer.username) 
                          || (obj.merchant && obj.merchant.username);          
        return username ? username.toLowerCase().includes(searchUsername.toLowerCase()) : false;
      });
    }
    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        {this._rederShowListConsumer()}
        {this._renderToolBar()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Code</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Username</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Type</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Share Offer</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Count Used</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>List Used</CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>Active/Deactive</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.code}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.consumer ? row.consumer.username: row.merchant?row.merchant.username:'' }</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.referalCodeType}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {
                        row.offer !== null ? <MdCheckCircle color={colors.dark} size={16} /> : <MdRemoveCircle color={colors.red} size={16} />
                      }
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.listUserEnterCode.length > 0?row.listUserEnterCode[0].totalUsageCount:0}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonActive}
                        onClick={() => this.setState({ showList: true, listConsumer: row.listUserEnterCode })}
                      >
                        <Typography component="p" className={classes.text}>Show</Typography>
                      </Button>
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      {
                        !row.active
                          ? (
                            <Button variant="contained" color="primary" className={classes.buttonActive} onClick={() => this.onChangeStatus(row, true)}>
                              <Typography component="p" className={classes.text}>Activate</Typography>
                            </Button>
                          )
                          : (
                            <Button variant="contained" color="primary" className={classes.buttonDeactive} onClick={() => this.onChangeStatus(row, false)}>
                              <Typography component="p" className={classes.text}>Deactivate</Typography>
                            </Button>
                          )
                      }
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// Mark: default props
TableReferral.defaultProps = {
  classes: {},
  getAllReferralCodeByAdmin: [],
  updateReferralCode: () => {},
};

// Mark: PropsType
TableReferral.propTypes = {
  classes: PropTypes.object,
  getAllReferralCodeByAdmin: PropTypes.array,
  updateReferralCode: PropTypes.func,
};

// Mark: Map data grapql to props
const AllReferralCodeByAdmin = graphql(getAllReferralCodeByAdmin, {
  props: ({ data }) => ({
    loading: data.loading,
    getAllReferralCodeByAdmin: data.getAllReferralCodeByAdmin,
  }),
});

const UpdateReferralCode = graphql(updateReferralCode, { name: 'updateReferralCode' });

export default withStyles(styles)(compose(AllReferralCodeByAdmin, UpdateReferralCode)(TableReferral));
