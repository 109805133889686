import gql from 'graphql-tag';

export const getAllAdmin = gql`
 query getAllAdmin{
  getAllAdmin{
      id
      username
      email
      role
      active
    }
  }
`;
