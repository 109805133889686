import React, { Component } from "react";
import { compose, graphql } from "react-apollo";
import _ from "lodash";
import { getDealById } from "./queries";
import { Dialog, Spiner } from "../../../../components";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

class ActionDetailDialog extends Component {
  _renderContent = () => {
    const dealDetail = this.props.dealDetail;
    const loading = this.props.loading;
    
    if (loading) {
      return <Spiner />
    }

    if (dealDetail) {
      const { id, consumer, merchant, cancelReason, customCancelReason } = dealDetail;
      const consumerName = consumer ? consumer.username : "Consumer not found!";
      const merchantName = merchant ? merchant.username : "Merchant not found!";

      return (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Deal number:</TableCell>
              <TableCell>{id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Consumer name:</TableCell>
              <TableCell>{consumerName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Merchant name:</TableCell>
              <TableCell>{merchantName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cancel reason:</TableCell>
              <TableCell>{cancelReason}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Other cancel reason:</TableCell>
              <TableCell>{customCancelReason}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
  };

  render() {
    const { open, onOk, title, dealDetail } = this.props;

    return (
      <Dialog
        title={title}
        open={open}
        onOk={onOk}
        listChildren={this._renderContent()}
        // listChildren={JSON.stringify(dealDetail)}
      />
    );
  }
}

const getActionDetail = graphql(getDealById, {
  props: ({ data }) => ({
    loading: data.loading,
    dealDetail: data.getDealById,
  }),
  options: (props) => {
    return {
      variables: { id: Number(props.dealId) },
      fetchPolicy: "cache-and-network",
    };
  },
});

export default compose(getActionDetail)(ActionDetailDialog);
