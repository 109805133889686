import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
// core components
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import GridContainer from '../../../components/Grid/GridContainer';
import Error from '../../../components/Error/Error';

import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import GridItem from '../../../components/Grid/GridItem';
import { Dialog } from '../../../components';
import { signupAdmin } from './mutations';
import { getAllAdmin } from '../TableAdmin/queries';
import styles from './styles';

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      password: '',
      role: 'ADMIN',
      confirmPassword: '',
      error: false,
      registered: false,
      open: false,
      title: '',
      content: '',
    };
  }

  componentDidMount = () => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      const { password } = this.state;
      if (value !== password) {
        return false;
      }
      return true;
    });
  };

  onSubmit = () => {
    const {
      email, username, password, role,
    } = this.state;
    const { signupAdmin } = this.props;
    signupAdmin({
      variables: {
        input: {
          email,
          username,
          password,
          role,
        },
      },
      refetchQueries: [{ query: getAllAdmin }],
    }).then(async (res) => {
      if (res.data.signupAdmin) {
        this.setState({ registered: true });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: graphQLErrors[0].message, title: 'Error' });
      }
    });
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheckChange = (event) => {
    this.setState({ role: event.target.value });
  };


  render() {
    const { classes } = this.props;
    const {
      email, username, password, confirmPassword, error, role, registered, open, content, title,
    } = this.state;
    return (
      <GridContainer justify="center" alignItems="center">
        { registered ? <Redirect to="/managerAdmins" /> : null }
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <GridItem xs={10} sm={8} md={6} lg={4}>
          <Card>
            <CardHeader>Add Amin</CardHeader>
            <CardBody>
              <Error error={error} />
              <ValidatorForm
                ref="form"
                onSubmit={() => this.onSubmit()}
              >
                <fieldset style={{ border: 0 }}>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <AccountCircle className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Email"
                        fullWidth
                        onChange={this.handleChange('email')}
                        name="email"
                        value={email}
                        validators={['required', 'isEmail']}
                        errorMessages={[
                          'this field is required',
                          'email is not valid',
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <AccountCircle className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Username"
                        fullWidth
                        onChange={this.handleChange('username')}
                        name="username"
                        value={username}
                        validators={['required']}
                        errorMessages={[
                          'this field is required',
                          'name of organization is not valid',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.wrapper}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Lock className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Password"
                        fullWidth
                        onChange={this.handleChange('password')}
                        name="password"
                        type="password"
                        value={password}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.wrapper}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Lock className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Confirm password"
                        fullWidth
                        onChange={this.handleChange('confirmPassword')}
                        name="confirmPassword"
                        type="password"
                        value={confirmPassword}
                        validators={['isPasswordMatch', 'required']}
                        errorMessages={[
                          'Password not match',
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <FormControl component="fieldset" className={classes.formSelect}>
                    <FormLabel component="legend">Select Role</FormLabel>
                    <RadioGroup
                      aria-label="Role"
                      name="Role"
                      value={role}
                    >
                      <FormControlLabel
                        value="ADMIN"
                        control={(
                          <Radio
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                            value="ADMIN"
                            onChange={this.handleCheckChange}
                            validators={['required']}
                          />
                        )}
                        label="Admin"
                      />
                      <FormControlLabel
                        value="SELLER"
                        control={(
                          <Radio
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                            value="SELLER"
                            onChange={this.handleCheckChange}
                            validators={['required']}
                          />
                        )}
                        label="Seller"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Button
                        type="submit"
                        className={classes.submitButton}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </fieldset>
              </ValidatorForm>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

// Mark: default props
Register.defaultProps = {
  classes: {},
  signupAdmin: () => {},
};

// Mark: PropsType
Register.propTypes = {
  classes: PropTypes.object,
  signupAdmin: PropTypes.func,
};

export default withWidth()(withStyles(styles)(graphql(signupAdmin, { name: 'signupAdmin' })(Register)));
