import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import { getAllPaymentTransactions } from './queries';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';
import SearchButton from './SearchButton';
import { FaEdit } from 'react-icons/fa';
import { Dialog } from '../../../components';
import CustomInput from '../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../constants';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

class PaymentTable extends Component {
  state = {
    open: false,
    field: '',
    content: '',
    search: '',
    page: 0,
    limit: 10
  };

  onSearch = event => {
    this.setState({ search: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ limit: event.target.value });
  };

  onPickFieldToSearch = field => {
    console.log('field', field);
    this.setState({ field });
  };

  renderToolBar = () => {
    const { classes } = this.props;
    const { search } = this.state;
    return (
      <div style={{ display: 'flex' }} className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`
          }}
          inputProps={{
            value: search,
            onChange: this.onSearch,
            placeholder: 'Search',
            inputProps: {
              'aria-label': 'Search'
            }
          }}
        />
        <Button style={{ paddingTop: 35 }} aria-label="edit" disabled>
          <Search />         
        </Button>
        <div style={{ paddingTop: 29 }}>
          <SearchButton
            fieldUsing={this.state.field}
            onPickFieldToSearch={this.onPickFieldToSearch}
          />
        </div>
      </div>
    );
  };

  render() {
    const { classes, listAllPaymentTransactions } = this.props;
    let data = listAllPaymentTransactions || [];
    console.log('data here', data);
    data = _.orderBy(data, obj => obj.id, 'desc');
    const { search, page, limit } = this.state;
    if (search) {
      const { field } = this.state;
      if (field === '') {
        data = _.filter(data, obj =>
          obj.user && obj.user.username
            ? obj.user.username.toLowerCase().match(search.toLowerCase())
            : false
        );
      }
      if (field === 'Merchant') {
        data = _.filter(data, obj =>
          obj.user && obj.user.username
            ? obj.user.username.toLowerCase().match(search.toLowerCase())
            : false
        );
      }
      if (field === 'Consumer') {
        data = _.filter(data, obj =>
          obj.consumer && obj.consumer.username
            ? obj.consumer.username.toLowerCase().match(search.toLowerCase())
            : false
        );
      }
      if (field === 'Amount') {
        data = _.filter(data, obj =>
          obj.amount ? obj.amount.toString().match(search) : false
        );
      }
      if (field === 'Id') {
        data = _.filter(data, obj =>
          obj.braintree_transaction_id
            ? obj.braintree_transaction_id.toString().match(search)
            : false
        );
      }
    }
    return (
      <div>
        {this.renderToolBar()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell align="left" className={classes.columnRow}>
                  Merchant
                </CustomTableCell>
                <CustomTableCell align="left" className={classes.columnRow}>
                  Consumer
                </CustomTableCell>
                <CustomTableCell align="left" className={classes.columnRow}>
                  Amount ($)
                </CustomTableCell>
                <CustomTableCell align="left" className={classes.columnRow}>
                  Id
                </CustomTableCell>
                {/* <CustomTableCell align="left" className={classes.columnRow}>
                  PaymentMethod
                </CustomTableCell> */}
                <CustomTableCell align="left" className={classes.rowEdit}>
                  Status
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map(row => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell align="left" className={classes.colTable}>
                      {row.user && row.user.username ? (
                        <Link
                          style={{ color: '#007d51' }}
                          to={`/detailMerchant/${row.user && row.user.id}`}
                        >
                          {row.user && row.user.username}                     
                        </Link>
                      ) : (
                        '-'
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="left" className={classes.colTable}>
                      {row.consumer && row.consumer.username ? (
                        <Link
                          style={{ color: '#007d51' }}
                          to={`/detailMerchant/${row.consumer &&
                            row.consumer.id}`}
                        >
                          {row.consumer && row.consumer.username}
                        </Link>
                      ) : (
                        '-'
                      )}
                    </CustomTableCell>
                    <CustomTableCell align="left" className={classes.colTable}>
                      {row.amount}
                    </CustomTableCell>
                    <CustomTableCell align="left" className={classes.colTable}>
                      {row.braintree_transaction_id}
                    </CustomTableCell>
                    {/* <CustomTableCell
                      align="left"
                      className={classes.colTable}
                    ></CustomTableCell> */}
                    <CustomTableCell
                      align="left"
                      className={classes.columCenter}
                    >
                       
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonActive} //onClick={() => this.onChangeActive(row, false)}
                      >
                        <Typography component="p" className={classes.text}>
                          {row.payment_status === 'submitted_for_settlement'
                            ? 'Settled'
                            : row.payment_status}
                        </Typography>
                      </Button>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// Mark: default props
PaymentTable.defaultProps = {
  classes: {},
  listAllPaymentTransactions: []
};
// Mark: PropsType
PaymentTable.propTypes = {
  classes: PropTypes.object,
  listAllPaymentTransactions: PropTypes.array
};
// Mark: Map data grapql to props
const ListAllPaymentTransactions = graphql(getAllPaymentTransactions, {
  props: ({ data }) => ({
    loading: data.loading,
    listAllPaymentTransactions: data.getAllPaymentTransactions
  })
});
export default withStyles(styles)(
  compose(ListAllPaymentTransactions)(PaymentTable)
);
