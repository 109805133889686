import gql from 'graphql-tag';

export const getAllWishlistsByAdmin = gql`
 query getAllWishlistsByAdmin{
    getAllWishlistsByAdmin{
      id
      name
      offerId
    }
}
`;

export const getAllDeals = gql`
 query getAllDeals{
  getAllDeals{
    id
    state
  }
}
`;
