import gql from 'graphql-tag';

export const getAllCategories = gql`
  query getAllCategories{
    getAllCategories{
      id
      name
      reservationPercentage
      bonusPercentage
      sharePercentage
      closedealPercentage
      archived
    }
  }
`;
