import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';
import { FaEdit } from 'react-icons/fa';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

import { getAllPlans } from './queries';
import { Dialog } from '../../../components';
import CustomInput from '../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../constants';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TablePlan extends Component {
  state={
    open: false,
    title: '',
    content: '',
    search: '',
    page: 0,
    limit: 10,
  }

  onOk = () => {
    this.setState({ open: false });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }

  render() {
    const { classes, getAllPlans } = this.props;
    let data = getAllPlans || [];
    data = _.orderBy(data, obj => obj.id, 'desc');
    const {
      title, content, open, search, page, limit,
    } = this.state;
    if (search) data = _.filter(data, obj => obj.name.toLowerCase().match(search.toLowerCase()));
    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: `${classes.margin} ${classes.search}`,
            }}
            inputProps={{
              value: search,
              onChange: this.onSearch,
              placeholder: 'Search',
              inputProps: {
                'aria-label': 'Search',
              },
            }}
          />
          <Button aria-label="edit" disabled>
            <Search />
          </Button>
        </div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Name</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Price </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Disc</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Period</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Active</CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>Edit</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.name}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.formatted_price}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.disc}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.period}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {
                        row.active ? <MdCheckCircle color={colors.dark} size={16} /> : <MdRemoveCircle color={colors.red} size={16} />
                      }
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      <Link to={{ pathname: `/detailPlan/${row.id}` }}>
                        <Button variant="contained" color="primary" className={classes.button}>
                          <Typography component="p" className={classes.text}>Edit</Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// Mark: default props
TablePlan.defaultProps = {
  classes: {},
  getAllPlans: [],
};

// Mark: PropsType
TablePlan.propTypes = {
  classes: PropTypes.object,
  getAllPlans: PropTypes.array,
};

// Mark: Map data grapql to props
const ListAll = graphql(getAllPlans, {
  props: ({ data }) => ({
    loading: data.loading,
    getAllPlans: data.getAllPlans,
  }),
});


export default withStyles(styles)(compose(ListAll)(TablePlan));
