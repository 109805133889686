import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import { FaEdit, FaPlus } from 'react-icons/fa';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

import { activeAdmin, deactivateAdmin } from './mutation';
import { getAllAdmin } from './queries';
import { Dialog } from '../../../components';
import CustomInput from '../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../constants';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TableAdmin extends Component {
  state={
    open: false,
    title: '',
    content: '',
    search: '',
    page: 0,
    limit: 10,
  }

   onActive = (row) => {
     const { id } = row;
     const { activeAdmin } = this.props;
     activeAdmin({
       variables: {
         id,
       },
       refetchQueries: [{ query: getAllAdmin }],
     }).then((res) => {
       if (res) this.setState({ open: true, content: 'Completed', title: 'Active Admin' });
     }).catch(({ graphQLErrors }) => {
       if (graphQLErrors) {
         this.setState({ open: true, content: graphQLErrors[0].message, title: 'Active Admin' });
       }
     });
   }

  onOk = () => {
    this.setState({ open: false });
  }

  onDeactive = (row) => {
    const { deactivateAdmin } = this.props;
    const { id } = row;
    deactivateAdmin({
      variables: {
        id,
      },
      refetchQueries: [{ query: getAllAdmin }],
    }).then((res) => {
      if (res) this.setState({ open: true, content: 'Completed', title: 'Deactive Admin' });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: graphQLErrors[0].message, title: 'Deactive Admin' });
      }
    });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }

  render() {
    const { classes, listAllAdmin } = this.props;
    let data = listAllAdmin || [];
    data = _.orderBy(data, obj => obj.id, 'desc');
    const {
      title, content, open, search, page, limit,
    } = this.state;
    if (search) data = _.filter(data, obj => obj.username.toLowerCase().match(search.toLowerCase()));
    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: `${classes.margin} ${classes.search}`,
            }}
            inputProps={{
              value: search,
              onChange: this.onSearch,
              placeholder: 'Search',
              inputProps: {
                'aria-label': 'Search',
              },
            }}
          />
          <Button aria-label="edit" disabled>
            <Search />
          </Button>
          <Link to={{ pathname: '/addAdmin' }}>
            <Button aria-label="Add">
              <FaPlus color={colors.parimary} size={16} />
            </Button>
          </Link>
        </div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Username</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Email</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Role</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Active</CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>Edit</CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>Active/Deactive</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.username}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.email}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.role}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {
                        row.active ? <MdCheckCircle color={colors.dark} size={16} /> : <MdRemoveCircle color={colors.red} size={16} />
                      }
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      <Link to={{ pathname: `/detailAdmin/${row.id}` }}>
                        <Button variant="contained" color="primary" className={classes.button}>
                          <Typography component="p" className={classes.text}>Edit</Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      {
                        !row.active
                          ? (
                            <Button variant="contained" color="primary" className={classes.buttonActive} onClick={() => this.onActive(row)}>
                              <Typography component="p" className={classes.text}>Activate</Typography>
                            </Button>
                          )
                          : (
                            <Button variant="contained" color="primary" className={classes.buttonDeactive} onClick={() => this.onDeactive(row)}>
                              <Typography component="p" className={classes.text}>Deactivate</Typography>
                            </Button>
                          )
                      }
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// Mark: defaultProps
TableAdmin.defaultProps = {
  classes: {},
  listAllAdmin: [],
  activeAdmin: () => {},
  deactivateAdmin: () => {},
};

// Mark: PropsType
TableAdmin.propTypes = {
  classes: PropTypes.object,
  listAllAdmin: PropTypes.array,
  activeAdmin: PropTypes.func,
  deactivateAdmin: PropTypes.func,
};

// Mark: Map data grapql to props
const ListAllAdmin = graphql(getAllAdmin, {
  props: ({ data }) => ({
    loadingList: data.loading,
    listAllAdmin: data.getAllAdmin,
  }),
});
const Active = graphql(activeAdmin, { name: 'activeAdmin' });
const Deactive = graphql(deactivateAdmin, { name: 'deactivateAdmin' });

export default withStyles(styles)(compose(ListAllAdmin, Deactive, Active)(TableAdmin));
