const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '16px',
  },
  row: {
    display: 'flex',
    flex: 1,
    marginBottom: '16px',
  },
  col: {
    flex: 1,
    padding: '8px',
    minWidth: '100px',
  },
  filterLabel: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '8px',
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '8px 0',
  },
  button: {
    backgroundColor: '#1eb980',
    color: 'white',
    border: 'none',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#3f51b5',
    },
    '&:focus': {
      outline: 'none',
    },
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
  },
  smallButton: {
    width: '28px',
    height: '28px',
    backgroundColor: '#1eb980',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: '#3f51b5',
    },
    '&:focus': {
      outline: 'none',
    },
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
  },
  span: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: 20,
    color: 'white',
  }
};

export default styles;
