import gql from 'graphql-tag';

export const updateUserByAdmin = gql`
  mutation updateUserByAdmin($input: UpdateUserInput!){
    updateUserByAdmin(input: $input){
      id
    }
  }
`;

export const updateCreditLimit = gql`
  mutation updateCreditLimit($username: String!, $creditLimit: Int!,$balanceTDC: Int!) {
    updateCreditLimit(username: $username, creditLimit: $creditLimit,balanceTDC:$balanceTDC) {
      id
      creditLimit
      balanceTDC      
      name
    }
  }  
`;
