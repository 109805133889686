import gql from 'graphql-tag';

export const updatePromotion = gql`
  mutation updatePromotion($input: UpdatePromotionInput!) {
    updatePromotion(input: $input) {
        id
        code
    }
  }
`;
