import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import ImageUploader from 'react-images-upload';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FaEdit, FaRegEye } from 'react-icons/fa';
// core components
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import Button from '../../../../components/CustomButtons/Button';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardAvatar from '../../../../components/Card/CardAvatar';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { getDetailComsumerById } from './queries';
import { getAllConsumers } from '../TableConsumer/queries';
import { updateConsumer } from './mutation';
import styles from './styles';
import config from '../../../../config';
import { Dialog } from '../../../../components';

class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailComsumer } = this.props;
    this.state = {
      edit: false,
      id: detailComsumer ? detailComsumer.id : undefined,
      username: detailComsumer ? detailComsumer.username : '',
      avatar: detailComsumer ? detailComsumer.avatar : '',
      email: detailComsumer ? detailComsumer.email : '',
      // birthday: detailComsumer ? detailComsumer.birthday : '',
      location: detailComsumer ? detailComsumer.location : '',
      phoneNumber: detailComsumer ? detailComsumer.phoneNumber : '',
      firstName: detailComsumer ? detailComsumer.firstName : '',
      lastName: detailComsumer ? detailComsumer.lastName : '',
      wallet: detailComsumer ? detailComsumer.wallet : {},
      fileUpLoad: [],
      nameImage: '',
      open: false,
      titleDialog: '',
      content: ''
    };
  }

  componentWillReceiveProps = nextProps => {
    const { detailComsumer } = this.props;
    console.log("props: ", nextProps)
    if (nextProps.detailComsumer !== detailComsumer) {
      const { detailComsumer } = nextProps;
      this.setState({
        id: detailComsumer.id,
        username: detailComsumer.username,
        avatar: detailComsumer.avatar,
        email: detailComsumer.email,
        // birthday: detailComsumer.birthday,
        location: detailComsumer.location,
        phoneNumber: detailComsumer.phoneNumber,
        firstName: detailComsumer.firstName,
        lastName: detailComsumer.lastName,
        wallet: detailComsumer.wallet
      });
    }
  };

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChooseImage = images => {
    this.setState({ fileUpLoad: images, nameImage: images[0].name });
  };

  onSaveProfile = async () => {
    const {
      username,
      email,
      // birthday,
      location,
      phoneNumber,
      firstName,
      lastName,
      fileUpLoad
    } = this.state;
    let { avatar } = this.state;
    const { match, updateConsumer } = this.props;

    if (fileUpLoad.length > 0) {
      const files = Array.from(fileUpLoad);
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(i, file);
      });

      await fetch(`${config.upload}`, {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(images => {
          // console.log(images);
          avatar = images.file.uri;
        });
    }
    const { id } = match.params;
    updateConsumer({
      variables: {
        input: {
          username,
          email,
          // birthday,
          location,
          phoneNumber,
          firstName,
          lastName,
          avatar,
          id: Number(id)
        }
      },
      refetchQueries: [
        { query: getDetailComsumerById, variables: { id: Number(id) } },
        { query: getAllConsumers }
      ]
    })
      .then(res => {
        if (res) {
          this.setState({
            fileUpLoad: [],
            edit: false,
            open: true,
            content: 'Completed',
            titleDialog: 'Update Consumer'
          });
        }
      })
      .catch(({ graphQLErrors }) => {
        const errorMessage = graphQLErrors[0].message
        if (graphQLErrors) {
          this.setState({
            open: true,
            titleDialog: 'Error !',
            content: errorMessage
          });
        }
      });
  };

  _renderWallet = () => {
    const { classes, loading } = this.props;
    if (loading) return null;
    const { wallet, id } = this.state;
    const { balance, creditLimit } = wallet;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardHeader className={classes.headCard}>
              <h4 className={classes.cardTitleWhite}>
                Wallet And Transactions
              </h4>
            </CardHeader>
            <CardBody profile>
              <h6 className={classes.cardTitle}>Wallet</h6>
              <p className={classes.description}>
                Balance: {Math.round(balance)}
              </p>
              <p className={classes.description}>
                Credit Limit: {Math.round(creditLimit)}
              </p>
              <Link to={{ pathname: `/allTransactionConsumer/${id}` }}>
                <Button round className={classes.button}>
                  <Typography component="p" className={classes.text}>
                    View Transactions
                  </Typography>
                  <FaRegEye color="#ffffff" size={16} />
                </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  _renderTagprofile = () => {
    const { classes, loading } = this.props;
    if (loading) return null;
    const { edit, nameImage, fileUpLoad, avatar, username } = this.state;
    const ImageUpload = (
      <ImageUploader
        withIcon={false}
        buttonText="Choose images"
        label={`New Image: ${nameImage}`}
        withLabel={fileUpLoad.length > 0}
        onChange={this.onChooseImage}
        imgExtension={['.jpg', '.gif', '.png', '.gif']}
        maxFileSize={5242880}
        fileContainerStyle={styles.uploadImage}
        buttonStyles={styles.buttonUpload}
        singleImage
      />
    );
    return (
      <Card profile>
        <CardAvatar profile>
          <Avatar alt="Avartar" src={avatar} className={classes.avatar} />
        </CardAvatar>
        {edit && ImageUpload}
        <CardBody profile>
          <h6 className={classes.cardCategory}>CONSUMER</h6>
          <h4 className={classes.cardTitle}>{username.toUpperCase()}</h4>
          <Button round className={classes.button} onClick={this.onEdit}>
            <Typography component="p" className={classes.text}>
              {!edit ? 'Edit' : 'Cancel'}
            </Typography>
            <FaEdit color="#ffffff" size={16} />
          </Button>
        </CardBody>
      </Card>
    );
  };

  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit,
      username,
      email,
      location,
      phoneNumber,
      firstName,
      lastName,
      content,
      titleDialog,
      open
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={titleDialog}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>
                  {edit ? 'Edit Profile' : 'Profile'}
                </h4>
                {edit ? (
                  <p className={classes.cardCategoryWhite}>
                    Complete your profile
                  </p>
                ) : null}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: username,
                        name: 'username',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="First Name"
                      id="firstName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: firstName || '',
                        name: 'firstName',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Last Name"
                      id="lastName"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: lastName || '',
                        name: 'lastName',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: email || '',
                        name: 'email',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Phone Number"
                      id="phoneNumber"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: phoneNumber || '',
                        name: 'phoneNumber',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Location"
                      id="location"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: location || '',
                        name: 'location',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              {edit ? (
                <CardFooter>
                  <Button
                    className={classes.button}
                    onClick={this.onSaveProfile}
                  >
                    Update Profile
                  </Button>
                </CardFooter>
              ) : null}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            {this._renderTagprofile()}
            {this._renderWallet()}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  detailComsumer: {},
  match: {},
  updateConsumer: () => {},
  loading: false
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  detailComsumer: PropTypes.object,
  match: PropTypes.object,
  updateConsumer: PropTypes.func,
  loading: PropTypes.bool
};

// Mark: Map data grapql to props
const DetailComsumerById = graphql(getDetailComsumerById, {
  props: ({ data }) => ({
    loading: data.loading,
    detailComsumer: data.getDetailComsumerById
  }),
  options: props => {
    return {
      variables: { id: Number(props.match.params.id) },
      fetchPolicy: 'cache-and-network'
    };
  }
});

const Update = graphql(updateConsumer, { name: 'updateConsumer' });

export default withStyles(styles)(
  compose(
    DetailComsumerById,
    Update
  )(Detail)
);
