
const styles = {


    img: {
        width: 340,
        height: 360,
        margin: 0,
    },
    itemSlide: {
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginTop: 30,

    },
    textTitle: {
        color: '#3C4858',
        fontSize: 28,
        marginTop: 10,
        minHeight: 32,
        fontWeight: 'bold',
        fontFamily: 'Josefin Sans',
        marginBottom: 30,
        textDecoration: 'none',
    },
};
export default styles;
