import React from 'react'; //eslint-disable-line
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  wrapper: {
    marginLeft: '15px',
    paddingLeft: '20px',
    borderLeft: '5px solid red'
  },
  danger: {
    color: 'red'
  }
};


const DisplayError = ({ error, classes }) => {
  if (!error || !error.message) return null;
  if (error.networkError && error.networkError.result && error.networkError.result.errors.length) {
    return error.networkError.result.errors.map((error, i) => (
      <div key={i} className={classes.wrapper} >
        <p data-test="graphql-error" className={classes.danger}>
          <strong>Opppps! </strong>
          {error.message.replace('GraphQL error: ', '')}
        </p>
      </div>
    ));
  }
  return (
    <div className={classes.wrapper}>
      <p data-test="graphql-error" className={classes.danger}>
        <strong>Opppps! </strong>
        {error.message.replace('GraphQL error: ', '')}
      </p>
    </div>
  );
};

export default withStyles(style)(DisplayError);
