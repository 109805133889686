import gql from 'graphql-tag';

export const activeUser = gql`
  mutation activeUser($id: Int,$username: String ){
    activeUser( id: $id, username: $username){
      id
    }
  }
`;

export const deactivateUser = gql`
  mutation deactivateUser($id: Int,$username: String ){
    deactivateUser( id: $id, username: $username){
      id
    }
  }
`;

export const changeActivateListMerchants = gql`
  mutation changeActivateListMerchants($listId: [Int]!,$status: Boolean ){
    changeActivateListMerchants( listId: $listId, status: $status)
  }
`;

export const forceConfirmEmail = gql`
  mutation forceConfirmEmail($username: String!){
    forceConfirmEmail(username: $username){
      id
    }
  }
`;

export const addDealCoinsAdmin = gql`
  mutation addDealCoinsAdmin($idWallet: Int, $type: String, $amount: Int, $detail: String){
    addDealCoinsAdmin(idWallet: $idWallet, type: $type, amount: $amount, detail:$detail ){
      id
    }
  }
`;

export const forceUnconfirmEmail = gql`
  mutation forceUnconfirmEmail($username: String!){
    forceUnconfirmEmail(username: $username){
      id
    }
  }
`;
