import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import ImageUploader from 'react-images-upload';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FaEdit } from 'react-icons/fa';
// core components
import GridContainer from '../../components/Grid/GridContainer';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardAvatar from '../../components/Card/CardAvatar';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import GridItem from '../../components/Grid/GridItem';
import CustomInput from '../../components/CustomInput/CustomInput';
import { getCurrentAdmin } from './queries';
import { getAllAdmin } from '../Admins/TableAdmin/queries';
import { updateAdmin } from './mutation';
import styles from './style';
import config from '../../config';
import { Dialog } from '../../components';

class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailAdmin } = this.props;
    this.state = {
      edit: false,
      username: detailAdmin ? detailAdmin.username : '',
      avatar: detailAdmin ? detailAdmin.avatar : '',
      email: detailAdmin ? detailAdmin.email : '',
      location: detailAdmin ? detailAdmin.location : '',
      telephone: detailAdmin ? detailAdmin.telephone : '',
      firstName: detailAdmin ? detailAdmin.firstName : '',
      lastName: detailAdmin ? detailAdmin.lastName : '',
      id: detailAdmin ? detailAdmin.id : '',
      fileUpLoad: [],
      nameImage: '',
      open: false,
      titleDialog: '',
      content: '',
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { detailAdmin } = this.props;
    if (nextProps.detailAdmin !== detailAdmin) {
      const { detailAdmin } = nextProps;
      this.setState({
        username: detailAdmin.username,
        avatar: detailAdmin.avatar,
        email: detailAdmin.email,
        location: detailAdmin.location,
        telephone: detailAdmin.telephone,
        firstName: detailAdmin.firstName,
        lastName: detailAdmin.lastName,
        id: detailAdmin.id,
      });
    }
  }

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChooseImage = (images) => {
    this.setState({ fileUpLoad: images, nameImage: images[0].name });
  }

  onSaveProfile = async () => {
    const {
      username, email, location, telephone, firstName, lastName, fileUpLoad, id,
    } = this.state;
    let { avatar } = this.state;
    const { updateAdmin } = this.props;

    if (fileUpLoad.length > 0) {
      const files = Array.from(fileUpLoad);
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(i, file);
      });

      await fetch(`${config.upload}`, {
        method: 'POST',
        body: formData,
      })
        .then(res => res.json())
        .then((images) => {
          avatar = images.file.uri;
        });
    }
    updateAdmin({
      variables: {
        input: {
          username, email, location, telephone, firstName, lastName, avatar, id,
        },
      },
      refetchQueries: [{ query: getCurrentAdmin }, { query: getAllAdmin }],
    }).then((res) => {
      if (res) {
        this.setState({
          fileUpLoad: [], edit: false, open: true, content: 'Completed', titleDialog: 'Update Profile',
        });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', titleDialog: 'Erros Update Profile' });
      }
    });
  }

  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit, username, avatar, email, location, telephone, firstName, lastName, fileUpLoad, nameImage, titleDialog, content, open,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={titleDialog}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit Profile' : 'Profile'}</h4>
                { edit ? <p className={classes.cardCategoryWhite}>Complete your profile</p> : null }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: username,
                        name: 'username',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="First Name"
                      id="firstName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: firstName || '',
                        name: 'firstName',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Last Name"
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: lastName || '',
                        name: 'lastName',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: email || '',
                        name: 'email',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Telephone"
                      id="telephone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: telephone || '',
                        name: 'telephone',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Location"
                      id="location"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: location || '',
                        name: 'location',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <InputLabel style={{ color: "#AAAAAA" }}>About me</InputLabel>
                    <CustomInput
                      labelText="Lamborghini Mercy, Your chick she so thirsty, I'm in that two seat Lambo."
                      id="about-me"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5
                      }}
                    />
                  </GridItem>
                </GridContainer> */}
              </CardBody>
              {
                edit
                  ? (
                    <CardFooter>
                      <Button className={classes.button} onClick={this.onSaveProfile}>Update Profile</Button>
                    </CardFooter>
                  ) : null
              }
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <Avatar alt="Avartar" src={avatar} className={classes.avatar} />
              </CardAvatar>
              {
                  edit
                    ? (
                      <ImageUploader
                        withIcon={false}
                        buttonText="Choose images"
                        label={`New Image: ${nameImage}`}
                        withLabel={fileUpLoad.length > 0}
                        onChange={this.onChooseImage}
                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                        maxFileSize={5242880}
                        fileContainerStyle={styles.uploadImage}
                        buttonStyles={styles.buttonUpload}
                        singleImage
                      />
                    ) : null
              }
              <CardBody profile>
                <h6 className={classes.cardCategory}>ADMIN</h6>
                <h4 className={classes.cardTitle}>{username.toUpperCase()}</h4>
                {/* <p className={classes.description}>
                  Don't be scared of the truth because we need to restart the
                  human foundation in truth And I love you like Kanye loves Kanye
                  I love Rick Owens’ bed design but the back is...
                </p> */}
                <Button round className={classes.button} onClick={this.onEdit}>
                  <Typography component="p" className={classes.text}>{!edit ? 'Edit' : 'Cancel'}</Typography>
                  <FaEdit color="#ffffff" size={16} />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  detailAdmin: {},
  updateAdmin: () => {},
  loading: false,
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  detailAdmin: PropTypes.object,
  updateAdmin: PropTypes.func,
  loading: PropTypes.bool,
};

// Mark: Map data grapql to props
const DetailAdmin = graphql(getCurrentAdmin, {
  props: ({ data }) => ({
    loading: data.loading,
    detailAdmin: data.getCurrentAdmin,
  }),
});

const Update = graphql(updateAdmin, { name: 'updateAdmin' });

export default withStyles(styles)(compose(DetailAdmin, Update)(Detail));
