import gql from 'graphql-tag';

export const addCategory = gql`
    mutation addCategory($input: CreateCategoryInput!){
    addCategory(input: $input){
      id
      name
      reservationPercentage
      bonusPercentage
      sharePercentage
      closedealPercentage
    }
  }
`;
