import React from "react";
import ReactSVG from "react-svg";
import { FaUserCog, FaSignOutAlt, FaTags, FaUsers, FaLayerGroup, FaFileSignature, FaBarcode, 
  FaHandshake, FaHistory, FaDollarSign, FaCoins, FaGratipay, FaHandHoldingUsd } from "react-icons/fa";
import SettingSvg from "../../assets/img/settings.svg";
import ApplicationSvg from "../../assets/img/application-lits.svg";
import DashboradSvg from "../../assets/img/dashboard.svg";
import EmployeeSvg from "../../assets/img/employees.svg";
import NoticeSvg from "../../assets/img/manage-notice.svg";
import ReportSvg from "../../assets/img/reports.svg";
import ExportSvg from '../../assets/img/export.svg';
import { IoIosSettings } from "react-icons/io";

export default function Icon(props) {
  return <ReactSVG src={props.src} svgStyle={props.style} />;
}
const IconGen = props => {
  return (
    <Icon
      src={props.src}
      style={props.style}
    />
  );
};
export const Settings = () => {
  return <IconGen style={{ display: "inline", height: "30px", width: "30px" }} src={SettingSvg} />;
};
export const Applications = () => {
  return <IconGen style={{ display: "inline", height: "30px", width: "30px" }} src={ApplicationSvg} />;
};
export const Employees = () => {
  return <IconGen style={{ display: "inline", height: "30px", width: "30px" }} src={EmployeeSvg} />;
};
export const ManageNotice = () => {
  return <IconGen style={{ display: "inline", height: "30px", width: "30px" }} src={NoticeSvg} />;
};
export const Dashboard = () => {
  return <IconGen style={{ display: "inline", height: "30px", width: "30px" }} src={DashboradSvg} />;
};
export const Reports = () => {
  return <IconGen style={{ display: "inline", height: "30px", width: "30px" }} src={ReportSvg} />;
};
export const Export = () => {
  return <IconGen style={{ display: "inline", height: "18px", width: "18px", marginRight: '10px' }} src={ExportSvg} />;
};
export const IconUser = () => {
  return <FaUsers color='#ffffff' size={30} />;
};
export const IconAdmin = () => {
  return <FaUserCog color='#ffffff' size={30} />;
};
export const IconLogout = () => {
  return <FaSignOutAlt color='#ffffff' size={30} />;
};
export const IconOffer = () => {
  return <FaTags color='#ffffff' size={30} />;
};
export const IconCategory = () => {
  return <FaLayerGroup color='#ffffff' size={30} />;
};
export const IconPlan = () => {
  return <FaFileSignature color='#ffffff' size={30} />;
};
export const IconCode = () => {
  return <FaBarcode color='#ffffff' size={30} />;
};
export const IconAction = () => {
  return <FaHandshake color='#ffffff' size={30} />;
};
export const IconCronJob = () => {
  return <FaHistory color='#ffffff' size={30} />;
};

export const IconDolla = () => {
  return <FaDollarSign color='#ffffff' size={28} />;
};

export const IconCoin = () => {
  return <FaCoins color='#ffffff' size={28} />;
};

export const IconWishlist = () => {
  return <FaGratipay color='#ffffff' size={28} />;
};

export const IconCloseDeal = () => {
  return <FaHandHoldingUsd color='#ffffff' size={28} />;
};

export const IconConstantSystem = () => {
  return <IoIosSettings color="#ffffff" size={28} />
};
