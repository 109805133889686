import React from 'react'; // eslint-disable-line
// @material-ui/icons

// core components/views
import DashboardPage from '../views/Dashboard';

import {
  User,
  Consumer,
  ActiveUser,
  DeactiveUser,
  DetailConsumer,
  DetailMerchant,
  ConstantsSystem,
  TableTransactions
} from '../views/User';
import { Admin, DetailAdmin, AddAdmin } from '../views/Admins';
import DetailStore from '../views/Store/DetailStore';
import { Offer, DetailOffer } from '../views/Offer';
import { Category, DetailCategory, AddCategory } from '../views/Category';
import {
  PromotionCode,
  DetailPromotionCode,
  AddPromotionCode,
  TableReferral
} from '../views/Code';
import { CronJob, DetailCronJob } from '../views/CronJob';
import { Payment } from '../views/Payments';
import { Plan, DetailPlan } from '../views/Plan';
import { ActionMerchant, ActionConsumer } from '../views/Action';
import Login from '../views/Login';
import Register from '../views/Register';
import Profile from '../views/Profile';

import {
  Dashboard,
  IconUser,
  IconOffer,
  IconAdmin,
  IconCategory,
  IconPlan,
  IconCode,
  IconAction,
  IconCronJob,
  IconCloseDeal,
  IconConstantSystem
} from '../components/Icon/Icon';
import { TableSharedOffer } from '../views/Code/SharedOffer';

const dashboardRoutes = [
  {
    path: '/dashboard',
    sidebarName: 'Dashboard',
    navbarName: 'Attendance Dashboard',
    icon: Dashboard,
    component: DashboardPage
  },
  // route user
  {
    path: '/user',
    sidebarName: 'Users',
    navbarName: 'Merchant',
    icon: IconUser,
    component: User,
    subComponent: [
      {
        path: '/merchant',
        sidebarName: 'Merchant',
        navbarName: 'Merchant',
        component: User
      },
      {
        path: '/consumer',
        sidebarName: 'Consumer',
        navbarName: 'Consumer',
        component: Consumer
      }
    ]
  },
  {
    path: '/constantsSystem',
    sidebarName: 'Constants System',
    navbarName: 'Constants',
    icon: IconConstantSystem,
    component: ConstantsSystem
  },
  {
    path: '/merchant',
    navbarName: 'Merchant',
    invisible: true,
    component: User
  },
  {
    path: '/activeUser',
    invisible: true,
    navbarName: 'Active User',
    component: ActiveUser
  },
  {
    path: '/deactiveUser',
    invisible: true,
    navbarName: 'Deactive User',
    component: DeactiveUser
  },
  {
    path: '/consumer',
    navbarName: 'Consumer',
    invisible: true,
    component: Consumer
  },
  {
    path: '/detailConsumer/:id',
    navbarName: 'Detail Consumer',
    invisible: true,
    component: DetailConsumer
  },
  {
    path: '/detailMerchant/:id',
    navbarName: 'Detail Merchant',
    invisible: true,
    component: DetailMerchant
  },
  {
    path: '/constantsSystem',
    navbarName: 'Constants System',
    invisible: true,
    component: ConstantsSystem
  },
  {
    path: '/allTransactionMerchant/:merchantId',
    navbarName: 'All Transactions Merchant',
    invisible: true,
    component: TableTransactions
  },
  {
    path: '/allTransactionConsumer/:consumerId',
    navbarName: 'All Transactions Consumer',
    invisible: true,
    component: TableTransactions
  },
  // rote admin
  {
    path: '/managerAdmins',
    sidebarName: 'Manager Admins',
    navbarName: 'Manager Admins',
    icon: IconAdmin,
    component: Admin
  },
  {
    path: '/detailAdmin/:id',
    navbarName: 'Detail Admin',
    invisible: true,
    component: DetailAdmin
  },
  {
    path: '/addAdmin',
    navbarName: 'Add Admin',
    invisible: true,
    component: AddAdmin
  },
  {
    path: '/payments',
    sidebarName: 'Payments',
    navbarName: 'Payments',
    icon: IconCloseDeal,
    component: Payment
  },
  // Offer
  {
    path: '/offer',
    sidebarName: 'Offers',
    navbarName: 'Offers',
    icon: IconOffer,
    component: Offer
  },
  {
    path: '/detailOffer/:id',
    navbarName: 'Detail Offer',
    invisible: true,
    component: DetailOffer
  },
  //store
  {
    path: '/detailStore/:id',
    invisible: true,
    component: DetailStore
  },
  // category
  {
    path: '/categories',
    sidebarName: 'Categories',
    navbarName: 'Categories',
    icon: IconCategory,
    component: Category
  },
  {
    path: '/detailCategory/:id',
    navbarName: 'Detail Category',
    invisible: true,
    component: DetailCategory
  },
  {
    path: '/addCategory',
    navbarName: 'Add Category',
    invisible: true,
    component: AddCategory
  },
  // Plan
  {
    path: '/plan',
    sidebarName: 'Plans',
    navbarName: 'Plans',
    icon: IconPlan,
    component: Plan
  },
  {
    path: '/detailPlan/:id',
    navbarName: 'Detail Plan',
    invisible: true,
    component: DetailPlan
  },
  // Promotion Code
  {
    path: '/code',
    sidebarName: 'Codes',
    navbarName: 'Promotion',
    icon: IconCode,
    component: PromotionCode,
    subComponent: [
      {
        path: '/promotionCode',
        sidebarName: 'Promotion',
        navbarName: 'Promotion',
        component: PromotionCode
      },
      {
        path: '/referralCode',
        sidebarName: 'Referral',
        navbarName: 'Referral',
        component: TableReferral
      },
      {
        path: '/sharedOffer',
        sidebarName: 'Shared Offer',
        navbarName: 'Shared Offer',
        component: TableSharedOffer
      }
    ]
  },
  {
    path: '/promotionCode',
    navbarName: 'Promotion',
    invisible: true,
    component: PromotionCode
  },
  {
    path: '/referralCode',
    navbarName: 'Referral',
    invisible: true,
    component: TableReferral
  },
  {
    path: '/sharedOffer',
    navbarName: 'Shared Offer',
    invisible: true,
    component: TableSharedOffer
  },
  {
    path: '/detailPromotionCode/:id',
    navbarName: 'Detail Promotion Code',
    invisible: true,
    component: DetailPromotionCode
  },
  {
    path: '/addPromotionCode',
    navbarName: 'Add Promotion Code',
    invisible: true,
    component: AddPromotionCode
  },
  // Router Action
  {
    path: '/actions',
    sidebarName: 'Actions',
    navbarName: 'Merchant',
    icon: IconAction,
    component: ActionMerchant,
    subComponent: [
      {
        path: '/actionMerchant',
        sidebarName: 'Merchant',
        component: ActionMerchant
      },
      {
        path: '/actionConsumer',
        sidebarName: 'Consumers',
        component: ActionConsumer
      }
    ]
  },
  {
    path: '/actionMerchant',
    navbarName: 'Actions Merchant',
    invisible: true,
    component: ActionMerchant
  },
  {
    path: '/actionConsumer',
    navbarName: 'Actions Consumers',
    invisible: true,
    component: ActionConsumer
  },
  // CronJob
  {
    path: '/cronjob',
    sidebarName: 'CronJobs',
    navbarName: 'CronJobs',
    icon: IconCronJob,
    component: CronJob
  },
  {
    path: '/detailCronJob/:id',
    navbarName: 'Detail CronJob',
    invisible: true,
    component: DetailCronJob
  },

  // profile
  {
    path: '/profile',
    navbarName: 'Profile',
    invisible: true,
    component: Profile
  },
  // route login
  {
    path: '/login',
    invisible: true,
    navbarName: 'Login',
    component: Login
  },
  {
    path: '/register',
    invisible: true,
    navbarName: 'Register',
    component: Register
  },
  {
    redirect: true,
    path: '/',
    to: '/login',
    navbarName: 'Redirect'
  }
];

export default dashboardRoutes;
