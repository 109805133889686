import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, withStyles } from "@material-ui/core";
import styles from "./styles";

function FilterFromTo(props) {
  const { label, fromField, toField, resetFn, classes } = props;
  return (
    <div>
      {typeof label === "string" ? (
        <Typography variant="body1" className={classes.filterLabel}>
          {label}
        </Typography>
      ) : (
        label
      )}
      <Grid container>
        <Grid key="from" container item xs={12} md={8}>
          {fromField && (
            <Grid item xs={12} sm={6}>
              {fromField}
            </Grid>
          )}
          {toField && (
            <Grid key="to" item xs={12} sm={6}>
              {toField}
            </Grid>
          )}
        </Grid>
        {resetFn && (
          <Grid container item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => resetFn && resetFn()}
            >
              Reset
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(FilterFromTo);

FilterFromTo.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  fromField: PropTypes.element,
  toField: PropTypes.element,
  resetFn: PropTypes.func.isRequired,
};
