import gql from 'graphql-tag';

export const getPromotionById = gql`
  query getPromotionById($id: Int!){
    getPromotionById(id: $id){
      id
      code
      description
      active
    }
  }
`;
