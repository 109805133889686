import { colors } from '../../../../constants';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: colors.parimary,
    width: '100px',
  },
  buttonActive: {
    margin: theme.spacing.unit,
    backgroundColor: colors.dark,
    width: '100px',
  },
  buttonDeactive: {
    margin: theme.spacing.unit,
    backgroundColor: colors.red,
    width: '100px',
  },
  rowEdit: {
    textAlign: 'center',
  },
  text: {
    color: '#ffffff',
    marginRight: '4px',
  },
  columnRow: {
    paddingRight: '24px',
  },
  columCenter: {
    textAlign: 'center',
    maxWidth: '100px',
  },
  colTable: {
    maxWidth: '100px',
  },
  filterLabel: {
    marginTop: "2rem"
  }
});
export default styles;
