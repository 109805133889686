import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, compose, Subscription } from "react-apollo";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
// material
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import Search from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { FaEdit } from "react-icons/fa";
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md";

import { archiveConsumer, unarchiveConsumer } from "./mutation";
import { getAllConsumers } from "./queries";
import { Dialog } from "../../../../components";
import styles from "./styles";
import { colors } from "../../../../constants";
import FilterFromToV2 from "../../../../components/Filter/FilterFromToV2";
import { subConsumerEvent } from "../../Subscription";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class TableConsumer extends Component {
  state = {
    open: false,
    title: "",
    content: "",
    search: "",
    page: 0,
    limit: 10,

    // filter
    email: "",
    dateFrom: "",
    dateTo: "",
    archived: "",
    deleteAccount: false,
  };

  onDataRefetch(){
    const { listRefetch } = this.props;
    listRefetch.refetch()
  }

  componentDidMount(){
    this.onDataRefetch()
  }

  componentDidUpdate(){
    this.onDataRefetch()
  }

  onArchive = (row) => {
    const { id } = row;
    const { archiveConsumer } = this.props;
    archiveConsumer({
      variables: {
        id,
      },
      refetchQueries: [{ query: getAllConsumers }],
    })
      .then((res) => {
        if (res)
          this.setState({
            open: true,
            content: "Completed",
            title: "Active Consumer",
          });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: "Active Consumer",
          });
        }
      });
  };

  onOk = () => {
    this.setState({ open: false });
  };

  onUnarchive = (row) => {
    const { id } = row;
    const { unarchiveConsumer } = this.props;
    unarchiveConsumer({
      variables: {
        id,
      },
      refetchQueries: [{ query: getAllConsumers }],
    })
      .then((res) => {
        if (res)
          this.setState({
            open: true,
            content: "Completed",
            title: "Unactive Consumer",
          });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: "Unactive Consumer",
          });
        }
      });
  };

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  handleFilterInpChange = (event) => {
    let { name, value } = event.target;
    if (value.toLowerCase) {
      value = value.toLowerCase();
    }

    this.setState({ [name]: value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  };

  filter = (data) => {
    const { search, email, dateTo, dateFrom, archived } = this.state;
    console.log(`this.state`, this.state);
    
    if (search)
      data = _.filter(data, (obj) =>
        obj.username.toLowerCase().match(search.toLowerCase())
      );

    if (email) {
      data = _.filter(data, (v) => v.email && v.email.includes(email));
    }

    if (archived !== "") {
      data = _.filter(data, (v) => v.archived === archived);
    }   

    if (dateFrom) {
      data = _.filter(data, (v) => v.createdAt.isSameOrAfter(dateFrom, "day"));
    }

    if (dateTo) {
      data = _.filter(data, (v) => v.createdAt.isSameOrBefore(dateTo, "day"));
    }

    console.log(`data`, data);
    return data;
  };

  renderFilter = () => {
    const { classes } = this.props;
    return (
      <div key="filter">
        <div className={classes.filterRow}>
          <FilterFromToV2
            label="Username"
            fromField={
              <TextField
                name="search"
                value={this.state.search}
                onChange={this.onSearch}
                placeholder="Username"
              />
            }
            resetFn={() => this.setState({ search: "" })}
            smallButton
          />
          <FilterFromToV2
            label="Email"
            fromField={
              <TextField
                name="email"
                value={this.state.email}
                onChange={this.handleFilterInpChange}
                placeholder="Email"
              />
            }
            resetFn={() => this.setState({ email: "" })}
            smallButton
          />
          <FilterFromToV2
            label="Activation"
            fromField={
              <Select
                name="archived"
                onChange={this.handleFilterInpChange}
                value={this.state.archived}
                displayEmpty
              >
                <MenuItem value={""}>
                  <em>All</em>
                </MenuItem>
                <MenuItem value={false}>Activated</MenuItem>
                <MenuItem value={true}>Deactivated</MenuItem>
              </Select>
            }
            resetFn={() => this.setState({ archived: "" })}
            smallButton
          />
        </div>
        <div className={classes.filterRow}>
          <FilterFromToV2
            label="Created Date"
            fromField={
              <TextField
                name="dateFrom"
                type="date"
                value={this.state.dateFrom}
                onChange={this.handleFilterInpChange}
                placeholder="From"
              />
            }
            toField={
              <TextField
                name="dateTo"
                type="date"
                value={this.state.dateTo}
                onChange={this.handleFilterInpChange}
                placeholder="To"
              />
            }
            resetFn={() => this.setState({ dateFrom: "", dateTo: "" })}
            smallButton
          />
        </div>
      </div>
    );
  };

  render() {
    const { classes, ListAllConsumer } = this.props;    
    
    // let data = ListAllConsumer || [];
    let data = ListAllConsumer || [];    
    data = _.map(data, (v) => ({
      ...v,
      createdAt: moment(Number(v.createdAt)),
    }));

    data = _.orderBy(data, (obj) => obj.id, "desc");
    const { title, content, open, page, limit } = this.state;
    data = this.filter(data);

    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        {this.renderFilter()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Username</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Email
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Review
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Balance Wallet ($)
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Activated
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>
                  Edit
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  Activate/Deactivate
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  {/* Created At */}
                  Sign Up date
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.columnRow}>                  
                  Delete Account
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                console.log(row)
                let review = 0;
                if (row.reviews.length > 0) {
                  _.forEach(row.reviews, (item) => (review += item.votedStars));
                  review = Math.round(review / row.reviews.length);
                } else review = 5;
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.username}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.email}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {review}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.wallet.earnedDealCoin}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {!row.archived ? (
                        <MdCheckCircle color={colors.dark} size={16} />
                      ) : (
                        <MdRemoveCircle color={colors.red} size={16} />
                      )}
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      <Link to={{ pathname: `/detailConsumer/${row.id}` }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          <Typography component="p" className={classes.text}>
                            Edit
                          </Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      {!row.archived ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonActive}
                          onClick={() => this.onArchive(row)}
                        >
                          <Typography component="p" className={classes.text}>
                            Deactivate
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonDeactive}
                          onClick={() => this.onUnarchive(row)}
                        >
                          <Typography component="p" className={classes.text}>
                            Activate
                          </Typography>
                        </Button>
                      )}
                    </CustomTableCell>
                    <CustomTableCell component="th" scope="row">
                      {row.createdAt.format("MM/DD/YYYY")}
                    </CustomTableCell>
                    <CustomTableCell align="right" component="th" scope="row">                      
                      {!row.deleteAccount ? (
                        <MdRemoveCircle color={colors.red} size={16} />
                        ) : (
                        <MdCheckCircle color={colors.dark} size={16} />
                      )}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Subscription
            subscription={subConsumerEvent}            
            onSubscriptionData={({ subscriptionData }) => {
              this.onDataRefetch()
            }}>          
          </Subscription>
        </Paper>
      </div>
    );
  }
}

// Mark: default props
TableConsumer.defaultProps = {  
  classes: {},
  archiveConsumer: () => {},
  unarchiveConsumer: () => {},
  ListAllConsumer: [],
  listRefetch: () => {},
};

// Mark: PropsType
TableConsumer.propTypes = {
  classes: PropTypes.object,
  archiveConsumer: PropTypes.func,
  unarchiveConsumer: PropTypes.func,
  ListAllConsumer: PropTypes.array,
  listRefetch: PropTypes.func,
};
const ListAllConsumer = graphql(getAllConsumers, {
  props: ({ data }) => ({
    loadingList: data.loading,
    ListAllConsumer: data.getAllConsumers
  }),
});
const listRefetch = graphql(getAllConsumers, { name: "listRefetch"});
const Archive = graphql(archiveConsumer, { name: "archiveConsumer" });
const Unarchive = graphql(unarchiveConsumer, { name: "unarchiveConsumer" });

export default withStyles(styles)(
  compose(
    ListAllConsumer,
    listRefetch,
    Archive,
    Unarchive
  )(TableConsumer)
);
