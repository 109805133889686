/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { ApolloClient } from 'apollo-client';
//import { HttpLink } from 'apollo-link-http';
import { ApolloProvider, Query } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink, concat, split } from 'apollo-link';
import { onError } from "apollo-link-error";
import { withClientState } from 'apollo-link-state';
//import { WebSocketLink } from "apollo-link-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
//import { SubscriptionClient, addGraphQLSubscriptions } from 'subscriptions-transport-ws'
import config from './config';
import 'assets/css/material-dashboard-react.css?v=1.5.0';
import App from './App';
import { getMainDefinition } from 'apollo-utilities';

// const httpLink = createHttpLink({ uri: 'http://35.200.183.110/graphql' });

const cache = new InMemoryCache({addTypename: false});

const stateLink = withClientState({
  cache,
  defaults: {
    auth: false,
    tableData: [],
    adminName: '',
    orgId: ''
  },
  resolvers: {},
});
// const httpLink = createHttpLink({ uri: 'http://localhost:8000/graphql' });
// const wsLink = new WebSocketLink({
//   // uri: config.endpoint.subscriptions,
//   uri: config.subscription,
//   options: {
//     reconnect: true
//   }
// });
// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('token');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   }
// });

// localStorage.setItem('orgId', '5c01f88140beca146f03e776');
// const hasSubscriptionOperation = ({ query: { definitions } }) =>
//   definitions.some(
//     ({ kind, operation }) =>
//       kind === 'OperationDefinition' && operation === 'subscription'
//   );



// const linkApollo = ApolloLink.from([
//   onError(({ graphQLErrors, networkError }) => {
//     if (graphQLErrors) {
//       graphQLErrors.map(({ message, locations, path }) =>
//         console.log(
//           `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//         ),
//       );
//     }

//     if (networkError) console.log(`[Network error]: ${networkError}`);

//   }),
//   stateLink
// ]).split(
//   hasSubscriptionOperation,
//   wsLink,
//   new HttpLink({
//     // uri: config.endpoint.graphql,
//     uri: config.graphql,
//     credentials: 'include'
//   })
// );

// const middlewareLink = setContext(() => ({
//   headers: {
//     'x-token': localStorage.getItem('token'),
//     'x-refresh-token': localStorage.getItem('refreshToken')
//   }
// }));

// const afterwareLink = new ApolloLink((operation, forward) => {
//   const { headers } = operation.getContext();

//   if (headers) {
//     const token = headers.get('x-token');
//     const refreshToken = headers.get('x-refresh-token');

//     if (token) {
//       localStorage.setItem('token', token);
//     }

//     if (refreshToken) {
//       localStorage.setItem('refreshToken', refreshToken);
//     }
//   }

//   return forward(operation);
// });

// const link = authLink.concat(linkApollo);
console.log('ip-config', config.uri);
const hist = createBrowserHistory();
// ------------ @neo
const httpLink = createHttpLink({
  uri: config.graphql,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
//------- subcription
const wsLink = new GraphQLWsLink(
  createClient({
    url: config.subscription,
    retryAttempts: 'Infinity',
    keepAlive: 10000,    
    shouldRetry: () => true
  })
);
/*const wsLink = new WebSocketLink({
  uri: config.subscription,
  options: {
    reconnect: true
  }
});*/
const splitLink = split(
  // split based on operation type
  ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink,
);
// ------- main setup
let link = authLink.concat(splitLink);
link = ApolloLink.from([errorLink, link]);
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

// ------------- @neo
const Root = (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

ReactDOM.render(
  Root,
  document.getElementById('root')
);
