import { colors } from '../../../../constants';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: colors.parimary,
    width: '100px',
  },
  buttonActive: {
    margin: theme.spacing.unit,
    backgroundColor: colors.parimary,
    width: '100px',
  },
  buttonDeactive: {
    margin: theme.spacing.unit,
    backgroundColor: colors.red,
    width: '100px',
  },
  rowEdit: {
    textAlign: 'center',
  },
  text: {
    color: '#ffffff',
    marginRight: '4px',
  },
  columnRow: {
    paddingRight: '24px',
    maxWidth: 300
  },
  columCenter: {
    textAlign: 'center',
    maxWidth: '100px',
  },
  colTable: {
    maxWidth: '100px',
  },
  colTableBalance: {
    width: '90px',
    minHeight:'70px',
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  selectTypeBalance: {
    width: '100%'
  },
  filterRow: {
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
  },
  btnBalance: {
    backgroundColor: 'transparent', // Fondo transparente por defecto
    borderRadius: '50%', // Hace que sea completamente redondo
    cursor: 'pointer',
    marginLeft: '4px',
    transition: 'background-color 0.3s', // Suaviza la transición al cambiar de color
    '&:hover': {
      backgroundColor: '#D7D7D7',// Cambia el fondo a gris claro al pasar el cursor
    },
  }
});
export default styles;
