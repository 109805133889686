import { colors } from '../../../constants';

const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    headCard: {
        backgroundColor: colors.parimary,
    },
    button: {
        backgroundColor: colors.dark,
    },
    text: {
      color: '#ffffff',
      marginRight: '4px',
    },
    uploadImage: {
      backgroundColor: '#ffffff',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      padding: '0px 0px',
    },
    buttonUpload: {
      backgroundColor: colors.dark,
      margin: '0px 0px',
    },
    avatar: { 
      width: 120,
      height: 120,
    }
  };
  export default styles;