const styles = {
    container: {
        width: '80%',
    },
    root: {
        color: '#66AA72',
        '&$checked': {
        color: '#66AA72',
        },
    },
    checked: {},
    wrapper: {
        width: '100%',
    },
    inputWrapper: {
        width: '90%',
    },
    icon: {
        fontSize: '20px',
    },
    submitButton: {
        background: '#66AA72',
        color: '#fff',
        width: '150px',
        margin: '10px',
        marginTop: '8px',
        '&:hover': {
        cursor: 'pointer',
        background: '#fff',
        color: '#66AA72',
        align: 'center',
        // marginTop: '26px',
        },
    },
};
export default styles