import gql from 'graphql-tag';

export const getAllUserActions = gql`
  query getAllUserActions{
    getAllUserActions{
      id
      userId
      user{
        id
        firstName
        lastName
        merchant {
          id
        }
      }
      objectId
      objectType
      username
      action
      role
      createdDate
    }
  }
`;

export const getDetailUserCloseDealAction = gql`
  query getDetailUserCloseDealAction($objectId: Int!, $merchantId: Int!) {
    getDetailUserCloseDealAction(objectId: $objectId, merchantId: $merchantId) {
      amount
      isAdded
      type
      createdAt
      deal {
        id
        sku
        qty
        cancelReason
        customCancelReason
        offer {
          id
          quantities
          title
        }
        consumer {
          id
          username
        }
      }
    }
  }
`

export const getDetailUserTransferAction = gql`
query getDetailUserTransferAction($objectId: Int!) {
    getDetailUserTransferAction(objectId: $objectId) {
        amount
        isAdded
        type
        createdAt
        transfered {
            id
            name
        }
        received {
            id
            name
        }
    }
}
`
