import gql from 'graphql-tag';

export const getStoreByAdmin = gql`
    query getStore($id: Int!){
        getStoreByAdmin(id: $id){
            id
            name
            email
            region{
              name
            }
            phone
            location
            pictures
            openHour
            closeHour
            latitude
            longitude
            detailDay
            specialDay
            categories{
              id
              name
            }
            imagesStore {
              id
              full
            }
    }
  }
`