import gql from 'graphql-tag';

export const updateConstantsSystems = gql`
  mutation updateConstantsSystems($id: Int!, $input: UpdateConstantsSystemsInput!){
  updateConstantsSystems(id: $id, input: $input){
    id
    coinShareApp
    coinShareOffer
    walletConsumer
    coinReceiveShare
    coinSharePromo
    coinReviewOffer
    shareOfferCoinMerchant
    shareAppCoinMerchant
  }
}
`;
