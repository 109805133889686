import gql from 'graphql-tag';

export const getAllSharedOffer = gql`
  query getAllSharedOffer{
    getAllSharedOffer{
      id
      code
      consumer{
        id
        username
      }
      merchant { 
        id 
        username
      }
      referalCodeType
      offer{
        id
        title
      }
      active
      listUserEnterCode{
        id
        createdAt
        transactions { createdAt }
        username
        usageCount
        totalUsageCount
      }
      createdAt
    }
  }
`;
