import gql from 'graphql-tag';

export const getUserById = gql`
  query getUserById($id: Int!){
    getUserById(id: $id){
      id
      avatarUser
      brandingName
      brandingDetail
      username
      firstName
      lastName
      businessName
      prefix
      suffix
      taxNumber
      telephone
      mobile
      brandingImage
      password
      location
      email
      role
      active
      position
      wallet{
          id
          balance
          balanceTDC
          creditLimit
      }
      stores{
        id
        name
        location
        phone
        pictures
      }
      offers{
        id
        title
        quantities
        offerPrice
        saleOff
        status
        featureImage
      }
    }
  }
`;
