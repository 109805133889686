import { colors } from '../../../constants';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  headCard: {
    backgroundColor: colors.parimary,
  },
  button: {
    backgroundColor: colors.dark,
  },
  text: {
    color: '#ffffff',
    marginRight: '4px',
  },
  uploadImage: {
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    padding: '0px 0px',
  },
  buttonUpload: {
    backgroundColor: colors.dark,
    margin: '0px 0px',
  },
  avatar: {
    width: 120,
    height: 120,
  },
  img: {
    width: 360,
    height: 360,
    margin: 0,
  },
  itemSlide: {
    marginRight: 1,
    marginTop: 30,
  },
  textTitle: {
    color: '#3C4858',
    fontSize: 28,
    marginTop: 20,
    minHeight: 32,
    fontWeight: 'bold',
    fontFamily: 'Roboto Slab',
    marginBottom: 0,
    textDecoration: 'none',
  },
  textOfferPrice: {
    color: '#3C4858',
    fontSize: 24,
    minHeight: 28,
    fontWeight: 500,
    fontFamily: 'Roboto Slab',
    marginBottom: 0,
    textDecoration: 'none',
  },
  textOfferQuantity: {
    color: '#3C4858',
    fontSize: 18,
    minHeight: 28,
    fontWeight: 500,
    fontFamily: 'Roboto Slab',
    marginBottom: 0,
    textDecoration: 'none',
  },
  boxDescription: {
    boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0)',
    '&:before': {
      backgroundColor: '#ffffff',
    },
  },
  headDescription: {
    padding: 0,
  },
  textVisited: {
    color: '#000000',
    fontSize: 18,
    fontWeight: 'normal',
    '&:hover, &:visited': {
      color: colors.parimary,
    },
  },
  bodyDescription: {
    paddingLeft: 0,
    width: 120,
  },
  hr: {
    width: '100%',
    height: 1,
    backgroundColor: '#000000',
  },
  textEdit: {
    color: '#ffffff',
    marginRight: 4,
    fontFamily: 'Roboto Slab',
  },
  buttonEdit: {
    justifyContent: 'center',
  },
  textTitleStores: {
    color: '#3C4858',
    textAlign: 'center',
    fontSize: 24,
    minHeight: 28,
    fontWeight: 700,
    fontFamily: 'Roboto Slab',
    textDecoration: 'none',
    marginBottom: 40,
  },
  seeMore: {
    color: '#3C4858',
    textAlign: 'center',
    fontSize: 18,
    minHeight: 28,
    fontWeight: 500,
    fontFamily: 'Roboto Slab',
    textDecoration: 'none',
    marginBottom: 40,
    '&:hover, &:visited': {
      color: colors.parimary,
    },
  },
  cardTitle: {
    color: '#3C4858',
    marginTop: '.625rem',
    minHeight: 'auto',
    fontWeight: 700,
    fontFamily: 'Roboto Slab',
    marginBottom: '0.75rem',
    textDecoration: 'none',
  },
  textStore: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#999',
    fontSize: 16,
    margin: 4,
  },
  switchRoot: {
    marginTop: "-0.3rem"
  }
};
export default styles;
