import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import ImageUploader from 'react-images-upload';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FaEdit, FaRegEye } from 'react-icons/fa';
// core components
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import Button from '../../../../components/CustomButtons/Button';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardAvatar from '../../../../components/Card/CardAvatar';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { getUserById } from './queries';
import { listAllUsers } from '../TableUser/queries';
import { updateUserByAdmin, updateCreditLimit } from './mutation';
import styles from './style';
import config from '../../../../config';
import { Dialog } from '../../../../components';

class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailMerchant } = this.props;
    this.state = {
      edit: false,
      id: detailMerchant ? detailMerchant.id : undefined,
      username: detailMerchant ? detailMerchant.username : '',
      avatarUser: detailMerchant ? detailMerchant.avatarUser : '',
      brandingImage: detailMerchant ? detailMerchant.brandingImage : '',
      brandingName: detailMerchant ? detailMerchant.brandingName : '',
      brandingDetail: detailMerchant ? detailMerchant.brandingDetail : '',
      email: detailMerchant ? detailMerchant.email : '',
      location: detailMerchant ? detailMerchant.location : '',
      telephone: detailMerchant ? detailMerchant.telephone : '',
      firstName: detailMerchant ? detailMerchant.firstName : '',
      lastName: detailMerchant ? detailMerchant.lastName : '',
      prefix: detailMerchant ? detailMerchant.prefix : '',
      suffix: detailMerchant ? detailMerchant.suffix : '',
      taxNumber: detailMerchant ? detailMerchant.taxNumber : '',
      position: detailMerchant ? detailMerchant.position : '',
      stores: detailMerchant ? detailMerchant.stores : [],
      offers: detailMerchant ? detailMerchant.offers : [],
      wallet: detailMerchant ? detailMerchant.wallet : {},
      creditLimit: (detailMerchant && detailMerchant.wallet) ? detailMerchant.wallet.creditLimit : '',
      balanceTDC: 0,
      balanceTDCInfo: (detailMerchant && detailMerchant.wallet) ? detailMerchant.wallet.balanceTDC : 0,
      fileUpLoad: [],
      nameImage: '',
      fileUpLoadBrandingImage: [],
      nameBrandingImage: '',
      open: false,
      titleDialog: '',
      content: '',
      seeMoreStore: false,
      seeMoreOffer: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { detailMerchant } = this.props;
    if (nextProps.detailMerchant !== detailMerchant) {
      const { detailMerchant } = nextProps;
      this.setState({
        id: detailMerchant.id,
        username: detailMerchant.username,
        avatarUser: detailMerchant.avatarUser,
        brandingImage: detailMerchant.brandingImage,
        brandingName: detailMerchant.brandingName,
        brandingDetail: detailMerchant.brandingDetail,
        email: detailMerchant.email,
        location: detailMerchant.location,
        telephone: detailMerchant.telephone,
        firstName: detailMerchant.firstName,
        lastName: detailMerchant.lastName,
        prefix: detailMerchant.prefix,
        suffix: detailMerchant.suffix,
        taxNumber: detailMerchant.taxNumber,
        position: detailMerchant.position,
        stores: detailMerchant.stores,
        offers: detailMerchant.offers,
        wallet: detailMerchant.wallet,
        creditLimit: detailMerchant.wallet.creditLimit,
        balanceTDC: 0,
        balanceTDCInfo: detailMerchant.wallet.balanceTDC
      });
    }
  }

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleChangeNumberOnly = (event) => {
    this.setState({ [event.target.name]: Number.parseInt(event.target.value) });
  }

  onChooseImage = (images) => {
    this.setState({ fileUpLoad: images, nameImage: images[0].name });
  }

  onChooseBrandingImage = (images) => {
    this.setState({ fileUpLoadBrandingImage: images, nameBrandingImage: images[0].name });
  }

  onSaveProfile = async () => {
    const {
      username, email, location, prefix, suffix, taxNumber, position,
      telephone, firstName, lastName, fileUpLoad, fileUpLoadBrandingImage, brandingName, brandingDetail,
    } = this.state;
    let { avatarUser, brandingImage } = this.state;
    const { updateUserByAdmin, match } = this.props;

    if (fileUpLoad.length > 0) {
      const files = Array.from(fileUpLoad);
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(i, file);
      });

      await fetch(`${config.upload}`, {
        method: 'POST',
        body: formData,
      })
        .then(res => res.json())
        .then((images) => {
          avatarUser = images.file.uri;
        });
    }
    if (fileUpLoadBrandingImage.length > 0) {
      const files = Array.from(fileUpLoadBrandingImage);
      const formData = new FormData();
      files.forEach((file, i) => {
        formData.append(i, file);
      });

      await fetch(`${config.upload}`, {
        method: 'POST',
        body: formData,
      })
        .then(res => res.json())
        .then((images) => {
          brandingImage = images.file.uri;
        });
    }
    const { id } = match.params;
    updateUserByAdmin({
      variables: {
        input: {
          username,
           email, 
           location, telephone, firstName, lastName, avatarUser, id: Number(id), brandingImage, prefix, suffix, taxNumber, position, brandingName, brandingDetail,
        },
      },
      refetchQueries: [{ query: getUserById, variables: { id: Number(id) } }, { query: listAllUsers }],
    }).then((res) => {
      console.log('res la gi', res)
      if (res) {
        this.setState({
          fileUpLoad: [], edit: false, open: true, content: 'Completed', titleDialog: 'Update Merchant',
        });
      }
    }).catch(({ graphQLErrors }) => {
      console.log('loi o day', graphQLErrors)
      if (graphQLErrors) {
        console.log('loi o day',  graphQLErrors.message)
        this.setState({ open: true, content: graphQLErrors[0].message, titleDialog: 'Erros Update Merchant' });
      }
    });
  }

  onUpdateSetup = () => {
    const { username, creditLimit, balanceTDC } = this.state;
    const { match, updateCreditLimit } = this.props;
    const { id } = match.params;
    updateCreditLimit({
      variables: {
        username,
        creditLimit: parseInt(creditLimit, 0),
        balanceTDC:parseInt(balanceTDC, 0),
      },
      refetchQueries: [{ query: getUserById, variables: { id } }, { query: listAllUsers }],
    }).then((res) => {
      if (res) {
        this.setState({
          fileUpLoad: [], edit: false, open: true, content: 'Completed', titleDialog: 'Update Merchant',
        });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', titleDialog: 'Erros Update Merchant' });
      }
    });
  }

  _renderStore = () => {
    const { classes } = this.props;
    const { stores, seeMoreStore } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {
              (stores && stores.length > 0)
                ? <Typography component="p" className={classes.textTitleStores}>Stores</Typography>
                : <Typography component="p" className={classes.textTitleStores}>No Store</Typography>
            }
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            { stores && (
            <GridContainer>
              { !seeMoreStore ? stores.slice(0, 4).map(item => (
                <GridItem xs={12} sm={12} md={3} key={item.id}>
                  <Link to={{ pathname: `/detailStore/${item.id}` }}>
                  <Card profile>
                    <CardAvatar profile>
                      <Avatar alt="Avartar" src={item.pictures || ''} className={classes.avatar} />
                    </CardAvatar>
                    <CardBody profile>
                      <h6 className={classes.cardCategory}>Store</h6>
                      <Typography component="p" className={classes.cardTitle}>{item.name.toUpperCase()}</Typography>
                      <Typography component="p" className={classes.textStore}>{item.location}</Typography>
                      <Typography component="p" className={classes.textStore}>Phone: {item.phone}</Typography>
                    </CardBody>
                  </Card>
                  </Link>
                </GridItem>
              ))
                : stores.map(item => (
                  <GridItem xs={12} sm={12} md={3} key={item.id}>
                      <Link to={{ pathname: `/detailStore/${item.id}` }}>
                    <Card profile>
                      <CardAvatar profile>
                        <Avatar alt="Avartar" src={item.pictures || ''} className={classes.avatar} />
                      </CardAvatar>
                      <CardBody profile>
                        <h6 className={classes.cardCategory}>Store</h6>
                        <Typography component="p" className={classes.cardTitle}>{item.name.toUpperCase()}</Typography>
                        <Typography component="p" className={classes.textStore}>{item.location}</Typography>
                        <Typography component="p" className={classes.textStore}>
                          Phone: {item.phone}
                        </Typography>
                      </CardBody>
                    </Card>
                    </Link>
                  </GridItem>
                ))
                    }
            </GridContainer>
            )
                }
          </GridItem>
          {
            stores.length > 4
              && (
                <GridItem xs={12} sm={12} md={12}>
                  {
                    !seeMoreStore
                      ? <Typography component="p" className={classes.seeMore} onClick={() => this.setState({ seeMoreStore: !seeMoreStore })}>See More</Typography>
                      : <Typography component="p" className={classes.seeMore} onClick={() => this.setState({ seeMoreStore: !seeMoreStore })}>Show Less</Typography>
                  }
                </GridItem>
              )
          }
        </GridContainer>
      </GridItem>
    );
  }

  _renderOffer = () => {
    const { classes } = this.props;
    const { offers, seeMoreOffer } = this.state;
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {
              (offers && offers.length > 0)
                ? <Typography component="p" className={classes.textTitleStores}>Offers</Typography>
                : <Typography component="p" className={classes.textTitleStores}>No Offer</Typography>
              }
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>setup
            { offers && (
            <GridContainer>
              { !seeMoreOffer ? offers.slice(0, 4).map(item => (
                <GridItem xs={12} sm={12} md={3} key={item.id}>
                  <Link to={{ pathname: `/detailOffer/${item.id}` }}>
                    <Card profile>
                      <CardAvatar profile>
                        <Avatar alt="Avartar" src={item.featureImage || ''} className={classes.avatar} />
                      </CardAvatar>
                      <CardBody profile>
                        <h6 className={classes.cardCategory}>Offer</h6>
                        <Typography component="p" className={classes.cardTitle}>{item.title ? item.title.toUpperCase() : ''}</Typography>
                        <Typography component="p" className={classes.textStore}>{item.saleOff}</Typography>
                        <Typography component="p" className={classes.textStore}>Quantity: {item.quantities}</Typography>
                      </CardBody>
                    </Card>
                  </Link>
                </GridItem>
              ))
                : offers.map(item => (
                  <GridItem xs={12} sm={12} md={3} key={item.id}>
                    <Link to={{ pathname: `/detailOffer/${item.id}` }}>
                      <Card profile>
                        <CardAvatar profile>
                          <Avatar alt="Avartar" src={item.featureImage || ''} className={classes.avatar} />
                        </CardAvatar>
                        <CardBody profile>
                          <h6 className={classes.cardCategory}>Offer</h6>
                          <Typography component="p" className={classes.cardTitle}>{item.title ? item.title.toUpperCase() : ''}</Typography>
                          <Typography component="p" className={classes.textStore}>{item.saleOff}</Typography>
                          <Typography component="p" className={classes.textStore}>
                            Quantity: {item.quantities}
                          </Typography>
                        </CardBody>
                      </Card>
                    </Link>
                  </GridItem>
                ))
                    }
            </GridContainer>
            )
                }
          </GridItem>
          {
            offers.length > 4
              && (
                <GridItem xs={12} sm={12} md={12}>
                  {
                    !seeMoreOffer
                      ? <Typography component="p" className={classes.seeMore} onClick={() => this.setState({ seeMoreOffer: !seeMoreOffer })}>See More</Typography>
                      : <Typography component="p" className={classes.seeMore} onClick={() => this.setState({ seeMoreOffer: !seeMoreOffer })}>Show Less</Typography>
                  }
                </GridItem>
              )
          }
        </GridContainer>
      </GridItem>
    );
  }

  _renderWallet = () => {
    const { classes, loading } = this.props;
    if (loading) return null;
    const { wallet, id } = this.state;
    const { balance, creditLimit,balanceTDC } = wallet;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card profile>
            <CardHeader className={classes.headCard}>
              <h4 className={classes.cardTitleWhite}>Wallet And Transactions</h4>
            </CardHeader>
            <CardBody profile>
              <h6 className={classes.cardTitle}>Wallet</h6>
              <p className={classes.description}>
                Balance: {Math.round(balance)}
              </p>
              <p className={classes.description}>
                Credit Limit: {Math.round(creditLimit)}
              </p>
              <p className={classes.description}>
                Transaction DealCoin: {Math.round(balanceTDC)}
              </p>
              <Link to={{ pathname: `/allTransactionMerchant/${id}` }}>
                <Button round className={classes.button}>
                  <Typography component="p" className={classes.text}>View Transactions</Typography>
                  <FaRegEye color="#ffffff" size={16} />
                </Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  _renderCreditLimit = () => {
    const { classes, loading } = this.props;
    if (loading) return null;
    const { creditLimit, edit, balanceTDC, balanceTDCInfo } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className={classes.headCard}>
              <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit Setup' : 'Setup User'}</h4>
              { edit ? <p className={classes.cardCategoryWhite}>Complete Setup</p> : null }
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={10}>
                  <CustomInput
                    labelText="Credit Limit"
                    id="creditLimit"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: !edit,
                      value: creditLimit || '',
                      name: 'creditLimit',
                      onChange: this.handleChange,
                      type: 'number',
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Add or remove transaction DealCoins"
                    id="balanceTDC"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: !edit,
                      value: balanceTDC || 0,
                      name: 'balanceTDC',
                      onChange: this.handleChangeNumberOnly,
                      type: 'number',
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Transactions Deal coins available"
                      id="balanceTDCInfo"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        value: balanceTDCInfo || 0,
                        name: 'balanceTDCInfo',
                      }}
                    />
                </GridItem>
              </GridContainer>
            </CardBody>
            {
                  edit
                    ? (
                      <CardFooter>
                        <Button className={classes.button} onClick={this.onUpdateSetup}>Update Setup</Button>
                      </CardFooter>
                    ) : null
                }
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  _renderTagProfile = () => {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit, avatarUser, nameImage, username, brandingDetail, brandingName, fileUpLoad,
    } = this.state;
    return (
      <Card profile>
        <CardAvatar profile>
          <Avatar alt="Avartar" src={avatarUser} className={classes.avatar} />
        </CardAvatar>
        {
            edit
              ? (
                <ImageUploader
                  withIcon={false}
                  buttonText="Choose Avatar"
                  label={`New Avatar: ${nameImage}`}
                  withLabel={fileUpLoad.length > 0}
                  onChange={this.onChooseImage}
                  imgExtension={['.jpg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  fileContainerStyle={styles.uploadImage}
                  buttonStyles={styles.buttonUpload}
                  singleImage
                />
              ) : null
        }
        <CardBody profile>
          <h6 className={classes.cardCategory}>MERCHANT</h6>
          <h4 className={classes.cardTitle}>{username.toUpperCase()}</h4>
          <p className={classes.description}>
            Branding Name: {brandingName.toUpperCase()}
          </p>
          <p className={classes.description}>
            Branding Detail: {brandingDetail.toUpperCase()}
          </p>
          <Button round className={classes.button} onClick={this.onEdit}>
            <Typography component="p" className={classes.text}>{!edit ? 'Edit' : 'Cancel'}</Typography>
            <FaEdit color="#ffffff" size={16} />
          </Button>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit, username, email, location, prefix, suffix, taxNumber, position, titleDialog, content, open,
      telephone, firstName, lastName, fileUpLoadBrandingImage, nameBrandingImage, brandingImage, brandingName, brandingDetail,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={titleDialog}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit Profile' : 'Profile'}</h4>
                { edit ? <p className={classes.cardCategoryWhite}>Complete your profile</p> : null }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Branding Name"
                      id="brandingName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: brandingName || '',
                        name: 'brandingName',
                        onChange: this.handleChange,
                      }}
                    />
                    <CustomInput
                      labelText="Branding Detail"
                      id="brandingDetail"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: brandingDetail || '',
                        name: 'brandingDetail',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem container justify="center" alignItems="center" xs={12} sm={12} md={6}>
                    <Avatar alt="Brading Image" src={brandingImage || ''} className={classes.bigAvatar} />
                    {
                      edit
                        ? (
                          <ImageUploader
                            withIcon={false}
                            buttonText="Choose Branding Image"
                            label={`New Brangding Image: ${nameBrandingImage}`}
                            withLabel={fileUpLoadBrandingImage.length > 0}
                            onChange={this.onChooseBrandingImage}
                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                            maxFileSize={5242880}
                            fileContainerStyle={styles.uploadImage}
                            buttonStyles={styles.buttonUpload}
                            singleImage
                          />
                        ) : null
                    }
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    <CustomInput
                      labelText="Username"
                      id="username"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: username || '',
                        name: 'username',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="First Name"
                      id="firstName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: firstName || '',
                        name: 'firstName',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Last Name"
                      id="lastName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: lastName || '',
                        name: 'lastName',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Prefix"
                      id="prefix"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: prefix || '',
                        name: 'prefix',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Suffix"
                      id="suffix"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: suffix || '',
                        name: 'suffix',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Tax Number"
                      id="taxNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: taxNumber || '',
                        name: 'taxNumber',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Position"
                      id="position"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: position || '',
                        name: 'position',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: email || '',
                        name: 'email',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Telephone"
                      id="telephone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: telephone || '',
                        name: 'telephone',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8}>
                    <CustomInput
                      labelText="Location"
                      id="location"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: location || '',
                        name: 'location',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              {
                edit
                  ? (
                    <CardFooter>
                      <Button className={classes.button} onClick={this.onSaveProfile}>Update Profile</Button>
                    </CardFooter>
                  ) : null
              }
            </Card>
            {this._renderCreditLimit()}
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            {this._renderTagProfile()}
            {this._renderWallet()}
          </GridItem>
        </GridContainer>
        {this._renderStore()}
        {this._renderOffer()}
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  loading: false,
  detailMerchant: {},
  updateCreditLimit: () => {},
  updateUserByAdmin: () => {},
  match: {},
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  detailMerchant: PropTypes.object,
  updateCreditLimit: PropTypes.func,
  updateUserByAdmin: PropTypes.func,
  match: PropTypes.object,
};
const DetailMerchantById = graphql(getUserById, {
  props: ({ data }) => ({
    loading: data.loading,
    detailMerchant: data.getUserById,
  }),
  options: (props) => {
    return ({ variables: { id: Number(props.match.params.id) }, fetchPolicy: 'cache-and-network' });
  },
});

const UpdateProfile = graphql(updateUserByAdmin, { name: 'updateUserByAdmin' });
const UpdateCredit = graphql(updateCreditLimit, { name: 'updateCreditLimit' });

export default withStyles(styles)(compose(DetailMerchantById, UpdateProfile, UpdateCredit)(Detail));
