import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { FaEdit } from 'react-icons/fa';
// core components
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { getCategoryById } from './queries';
import { getAllCategories } from '../TableCategory/queries';
import { updateCategory } from './mutation';
import styles from './style';
import { Dialog } from '../../../components';

class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailCategory } = this.props;
    this.state = {
      edit: false,
      name: detailCategory ? detailCategory.name : '',
      reservationPercentage: detailCategory
        ? detailCategory.reservationPercentage
        : '',
      bonusPercentage: detailCategory ? detailCategory.bonusPercentage : '',
      sharePercentage: detailCategory ? detailCategory.sharePercentage : '',
      closedealPercentage: detailCategory
        ? detailCategory.closedealPercentage
        : '',
      open: false,
      title: '',
      content: ''
    };
  }

  componentWillReceiveProps = nextProps => {
    const { detailCategory } = this.props;
    if (nextProps.detailCategory !== detailCategory) {
      const { detailCategory } = nextProps;
      this.setState({
        name: detailCategory.name,
        reservationPercentage: detailCategory.reservationPercentage,
        bonusPercentage: detailCategory.bonusPercentage,
        sharePercentage: detailCategory.sharePercentage,
        closedealPercentage: detailCategory.closedealPercentage
      });
    }
  };

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSaveProfile = async () => {
    const {
      name,
      reservationPercentage,
      bonusPercentage,
      sharePercentage,
      closedealPercentage
    } = this.state;

    console.log('name', name);
    const { match, updateCategory } = this.props;
    const { id } = match.params;
    updateCategory({
      variables: {
        input: {
          id: Number(id),
          name: name.toString(),
          reservationPercentage: Number(reservationPercentage),
          bonusPercentage: Number(bonusPercentage),
          sharePercentage: Number(sharePercentage),
          closedealPercentage: Number(closedealPercentage)
        }
      },
      refetchQueries: [
        { query: getCategoryById, variables: { id } },
        { query: getAllCategories }
      ]
    })
      .then(res => {
        if (res) {
          this.setState({
            edit: false,
            open: true,
            content: 'Completed',
            title: 'Update Category'
          });
        }
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: 'Value invalid',
            title: 'Erros Update Category'
          });
        }
      });
  };

  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit,
      name,
      reservationPercentage,
      bonusPercentage,
      sharePercentage,
      closedealPercentage,
      title,
      open,
      content
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={title}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>
                  {edit ? 'Edit Category' : 'Detail Category'}
                </h4>
                {edit ? (
                  <p className={classes.cardCategoryWhite}>Complete Category</p>
                ) : null}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: name || '',
                        name: 'name',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Reservation Percentage *(Number)"
                      id="reservationPercentage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: reservationPercentage || '',
                        name: 'reservationPercentage',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Bonus Percentage *(Number)"
                      id="bonusPercentage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: bonusPercentage || '',
                        name: 'bonusPercentage',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Share Percentage *(Number)"
                      id="sharePercentage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: sharePercentage || '',
                        name: 'sharePercentage',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Closedeal Percentage *(Number)"
                      id="closedealPercentage"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: closedealPercentage || '',
                        name: 'closedealPercentage',
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              {edit ? (
                <CardFooter>
                  <Button
                    className={classes.button}
                    onClick={this.onSaveProfile}
                  >
                    Update Category
                  </Button>
                </CardFooter>
              ) : null}
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Category</h6>
                <h4 className={classes.cardTitle}>{name.toUpperCase()}</h4>
                <Button round className={classes.button} onClick={this.onEdit}>
                  <Typography component="p" className={classes.text}>
                    {!edit ? 'Edit' : 'Cancel'}
                  </Typography>
                  <FaEdit color="#ffffff" size={16} />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  updateCategory: () => {},
  detailCategory: {},
  match: {},
  loading: false
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  updateCategory: PropTypes.func,
  detailCategory: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool
};

// Mark: Map data grapql to props
const DetailCategory = graphql(getCategoryById, {
  props: ({ data }) => ({
    loading: data.loading,
    detailCategory: data.getCategoryById
  }),
  options: props => {
    return {
      variables: { id: Number(props.match.params.id) },
      fetchPolicy: 'cache-and-network'
    };
  }
});

const Update = graphql(updateCategory, { name: 'updateCategory' });

export default withStyles(styles)(
  compose(
    DetailCategory,
    Update
  )(Detail)
);
