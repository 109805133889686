import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Error from '../../components/Error/Error';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

import { Dialog } from '../../components';

import { loginAdmin } from './mutations';
import styles from './styles';

class Login extends Component {
  state = {
    username: '',
    password: '',
    checked: true,
    token: undefined,
    error: false,
    open: false,
    title: '',
    content: '',
  };

  componentDidMount = () => {
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    if (username && password) {
      this.setState({ username, password });
    }
    const token = localStorage.getItem('token');
    if (token) this.setState({ token });
  }


  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  }

  handleCheckChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  }

  handleSubmit = () => {
    const { username, password } = this.state;
    const { loginAdmin } = this.props;
    loginAdmin({
      variables: {
        username,
        password,
      },
    }).then((res) => {
      const { token } = res.data.loginAdmin;
      localStorage.setItem('token', token);
      this.setState({ token });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: graphQLErrors[0].message, title: 'Error Login' });
      }
    });
  }

  onOk = () => {
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;
    const {
      username, password, checked, error, open, title, content, token,
    } = this.state;
    return (
      <GridContainer justify="center" alignItems="center">
        { token && <Redirect to="/dashboard" /> }
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <GridItem xs={10} sm={8} md={6} lg={4}>
          <Card>
            <CardHeader>Login</CardHeader>
            <CardBody>
              <Error error={error} />
              <ValidatorForm
                ref="form"
                onSubmit={() => this.handleSubmit()}
              >
                <fieldset style={{ border: 0 }}>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <AccountCircle className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Username"
                        fullWidth
                        onChange={this.handleChange('username')}
                        name="username"
                        value={username}
                        validators={['required']}
                        errorMessages={[
                          'this field is required',
                          'username is not valid',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={8}
                    className={classes.wrapper}
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Lock className={classes.icon} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Password"
                        fullWidth
                        onChange={this.handleChange('password')}
                        name="password"
                        type="password"
                        value={password}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                    </Grid>
                  </Grid>

                  <FormGroup>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={checked}
                          onChange={this.handleCheckChange('checked')}
                          value="checked"
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      )}
                      label="Remember Password"
                    />
                  </FormGroup>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Button
                        type="submit"
                        className={classes.submitButton}
                      >
                            Sign In
                      </Button>
                    </Grid>

                    <Grid item>
                      <Link to={{ pathname: '/register' }}>
                            Not registerd yet ? Register here
                      </Link>
                    </Grid>
                  </Grid>
                </fieldset>
              </ValidatorForm>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

// Mark: default props
Login.defaultProps = {
  classes: {},
  loginAdmin: () => {},
};

// Mark: PropsType
Login.propTypes = {
  classes: PropTypes.object,
  loginAdmin: PropTypes.func,
};

export default withStyles(styles)(graphql(loginAdmin, { name: 'loginAdmin' })(Login));
