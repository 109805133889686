import gql from 'graphql-tag';

export const createPromotion = gql`
  mutation createPromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
        id
        code
    }
  }

`;
