import gql from 'graphql-tag';

export const getAllPromotions = gql`
  query getAllPromotions{
    getAllPromotions {
        id
        code
        description
        active
    }
  }
`;
