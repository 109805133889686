import gql from 'graphql-tag';

export const getAllCronJobs = gql`
  query getAllCronJobs{
    getAllCronJobs{
      id
      cronkey
      schedule
      interval
      active
      order
    }
  }
`;
