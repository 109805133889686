import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
// core components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FaEdit } from 'react-icons/fa';

import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import { Dialog } from '../../../../components';

import { createPromotion } from './mutations';
import { getAllPromotions } from '../TablePromotionCode/queries';
import styles from './styles';

class Add extends Component {
  state = {
    code: '',
    description: '',
    isAdded: false,
    open: false,
    titleDialog: '',
    content: '',
  };

  onSubmit = () => {
    const { code, description } = this.state;
    const { createPromotion } = this.props;
    createPromotion({
      variables: {
        input: {
          code,
          description,
        },
      },
      refetchQueries: [{ query: getAllPromotions }],
    }).then(async (res) => {
      if (res.data.createPromotion) {
        this.setState({ isAdded: true });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', titleDialog: 'Erros' });
      }
    });
  }

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      code, description, isAdded, open, content, titleDialog,
    } = this.state;
    return (
      <GridContainer justify="center" alignItems="center">
        { isAdded ? <Redirect to="/promotionCode" /> : null }
        <Dialog
          title={titleDialog}
          content={content}
          open={open}
          onOk={() => this.setState({ open: false })}
        />
        <GridItem xs={10} sm={8} md={6} lg={4}>
          <Card>
            <CardHeader>Add Promotion Code</CardHeader>
            <CardBody>
              <ValidatorForm
                ref="form"
                onSubmit={() => this.onSubmit()}
              >
                <fieldset style={{ border: 0 }}>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Code"
                        fullWidth
                        onChange={this.handleChange('code')}
                        name="code"
                        value={code}
                        validators={['required']}
                        errorMessages={[
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={8}
                    alignItems="flex-end"
                    className={classes.wrapper}
                  >
                    <Grid item>
                      <FaEdit color="#000" size={16} />
                    </Grid>
                    <Grid item className={classes.inputWrapper}>
                      <TextValidator
                        label="Description"
                        fullWidth
                        onChange={this.handleChange('description')}
                        name="description"
                        value={description}
                        validators={['required']}
                        errorMessages={[
                          'this field is required',
                        ]}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justify="space-between">
                    <Grid item>
                      <Button
                        type="submit"
                        className={classes.submitButton}
                      >
                        Add Code
                      </Button>
                    </Grid>
                  </Grid>
                </fieldset>
              </ValidatorForm>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

// Mark: default props
Add.defaultProps = {
  classes: {},
  createPromotion: () => {},
};

// Mark: PropsType
Add.propTypes = {
  classes: PropTypes.object,
  createPromotion: PropTypes.func,
};

// Mark: Map data grapql to props
export default withWidth()(withStyles(styles)(graphql(createPromotion, { name: 'createPromotion' })(Add)));
