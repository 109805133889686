const styles = {
    container: {
      width: '80%',
    },
    root: {
      color: '#66AA72',
      '&$checked': {
        color: '#66AA72',
      },
    },
    checked: {},
    wrapper: {
      width: '100%',
    },
    inputWrapper: {
      width: '90%',
    },
    icon: {
      fontSize: '20px',
    },
    note: {
      margin: '10px',
    },
    submitButton: {
      background: '#66AA72',
      color: '#fff',
      width: '150px',
      margin: '10px',
      '&:hover': {
        cursor: 'pointer',
        background: '#fff',
        color: '#66AA72',
      },
    },
    errorText: {
      color: '#D2553E',
    },
    formSelect: {
      marginTop: '10px',
    }
};
export default styles;
