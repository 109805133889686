import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// material
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import { FaEdit } from 'react-icons/fa';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

import { switchOffer, changeActive } from './mutation';
import { getAllOffersByAdmin } from './queries';
import { Dialog } from '../../../components';
import CustomInput from '../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../constants';
import classNames from 'classnames';

import { Subscription } from 'react-apollo';
import { subOfferEdited } from '../Subscription';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

class TableOffer extends Component {
  state = {
    open: false,
    title: '',
    content: '',
    search: '',
    page: 0,
    limit: 10
  };

  onDataRefetch(){
    const { listOfferRefetch } = this.props;
    listOfferRefetch.refetch()
  }

  componentDidMount(){
    this.onDataRefetch()
  }

  componentDidUpdate(){
    this.onDataRefetch()
  }

  //TODO: VER TKT CLOS-248 cancelado por violar operacion de activar o desactivar usuario
  /*changeStatus = row => {
    const offerId = row.id;
    const statusOffer = row.status === 'PUBLISHED';
    const { switchOffer } = this.props;
    switchOffer({
      variables: {
        offerId,
        statusOffer: !statusOffer
      },
      refetchQueries: [{ query: getAllOffersByAdmin }]
    })
      .then(res => {
        if (res)
          this.setState({
            open: true,
            content: 'Completed',
            title: 'Change Status Offer'
          });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: 'Error Change Status Offer'
          });
        }
      });
  };*/

  onChangeActive = (row, active) => {
    const { id } = row;
    const { changeActive } = this.props;
    changeActive({
      variables: {
        id,
        active
      },
      refetchQueries: [{ query: getAllOffersByAdmin }]
    })
      .then(res => {
        if (res)
          this.setState({
            open: true,
            content: 'Completed',
            title: 'Change Active Offer'
          });
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors) {
          this.setState({
            open: true,
            content: graphQLErrors[0].message,
            title: 'Error Change Active Offer'
          });
        }
      });
  };

  onOk = () => {
    this.setState({ open: false });
  };

  onSearch = event => {
    this.setState({ search: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ limit: event.target.value });
  };

  renderToolBar = () => {
    const { classes } = this.props;
    const { search } = this.state;
    return (
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: `${classes.margin} ${classes.search}`
          }}
          inputProps={{
            value: search,
            onChange: this.onSearch,
            placeholder: 'Search',
            inputProps: {
              'aria-label': 'Search'
            }
          }}
        />
        <Button aria-label="edit" disabled>
          <Search />
        </Button>
      </div>
    );
  };

  render() {
    const { classes, listAllOffer } = this.props;
    let data = listAllOffer || [];
    data = _.orderBy(data, obj => obj.id, 'desc');
    const { title, content, open, search, page, limit } = this.state;
    if (search)
      data = _.filter(data, obj =>
        obj.title ? obj.title.toLowerCase().match(search.toLowerCase()) : false
      );
    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        {this.renderToolBar()}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Title</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Quantities
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Offer Price
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Sale
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>
                  Online
                </CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>
                  Detail
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  Public/Pause
                </CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>
                  Active/Deactive
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map(row => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.title}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.quantities}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.offerPrice}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.saleOff}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {row.status === 'PUBLISHED' &&
                      (row.active === true || row.active == null) ? (
                        <MdCheckCircle color={colors.dark} size={16} />
                      ) : (
                        <MdRemoveCircle color={colors.red} size={16} />
                      )}
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      <Link to={{ pathname: `/detailOffer/${row.id}` }}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          <Typography component="p" className={classes.text}>
                            Detail
                          </Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      {row.status !== 'PUBLISHED' ? (
                        <Button
                          variant="contained"
                          color="primary"
                          disableRipple = {true}
                          disableElevation = {true}                          
                          className={classNames(classes.buttonDeactive, classes.noElevateDeactive)}
                        >
                          <Typography component="p" className={classes.text}>
                            Pause
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          disableRipple = {true}
                          disableElevation = {true}
                          className={classNames(classes.buttonActive, classes.noElevateActive)}
                        >
                          <Typography component="p" className={classes.text}>
                            Public
                          </Typography>
                        </Button>
                      )}
                    </CustomTableCell>

                    <CustomTableCell
                      align="center"
                      className={classes.columCenter}
                    >
                      {row.active === false ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonDeactive}
                          onClick={() => this.onChangeActive(row, true)}
                        >
                          <Typography component="p" className={classes.text}>
                            Deactive
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonActive}
                          onClick={() => this.onChangeActive(row, false)}
                        >
                          <Typography component="p" className={classes.text}>
                            Active
                          </Typography>
                        </Button>
                      )}
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />

          <Subscription
            subscription={subOfferEdited}            
            onSubscriptionData={({ subscriptionData }) => {
              this.onDataRefetch()
            }}>          
          </Subscription>

        </Paper>

      </div>
    );
  }
}
// Mark: default props
TableOffer.defaultProps = {
  classes: {},
  listAllOffer: [],
  listOfferRefetch: () => {},
  switchOffer: () => {},
  changeActive: () => {}
};

// Mark: PropsType
TableOffer.propTypes = {
  classes: PropTypes.object,
  listAllOffer: PropTypes.array,
  listOfferRefetch: PropTypes.func,
  switchOffer: PropTypes.func,
  changeActive: PropTypes.func
};

// Mark: Map data grapql to props
const ListAllOffer = graphql(getAllOffersByAdmin, {
  props: ({ data }) => ({
    loading: data.loading,
    listAllOffer: data.getAllOffersByAdmin
  })
});
const Switch = graphql(switchOffer, { name: 'switchOffer' });
const listOfferRefetch = graphql(getAllOffersByAdmin, { name: 'listOfferRefetch' });
const ChangeActive = graphql(changeActive, { name: 'changeActive' });
export default withStyles(styles)(
  compose(
    ListAllOffer,
    listOfferRefetch,
    Switch,
    ChangeActive
  )(TableOffer)
);
