import gql from 'graphql-tag';

export const updatePlan = gql`
  mutation updatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
    id
    
  }
}
`;
