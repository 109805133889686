import gql from 'graphql-tag';

export const getAllConsumers = gql`
 query getAllConsumers{
    getAllConsumers{
      id
      username
      email
      createdAt
      wallet{
        id
        money
        reservationDeposit
        reservationOnly
        earnedDealCoin
      }
      reviews{
          id
          votedStars
      }
      archived
      deleteAccount
    }
  }
`;
