import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { FaEdit } from 'react-icons/fa';
// core components
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Button from '../../../components/CustomButtons/Button';
import Card from '../../../components/Card/Card';
import CardHeader from '../../../components/Card/CardHeader';
import CardBody from '../../../components/Card/CardBody';
import CardFooter from '../../../components/Card/CardFooter';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { getPlanById } from './queries';
import { getAllPlans } from '../TablePlan/queries';
import { updatePlan } from './mutation';
import styles from './styles';
import { Dialog } from '../../../components';


class Detail extends Component {
  constructor(props) {
    super(props);
    const { detailPlan } = this.props;
    this.state = {
      edit: false,
      name: detailPlan ? detailPlan.name : '',
      price: detailPlan ? detailPlan.price : '',
      disc: detailPlan ? detailPlan.disc : '',
      active: detailPlan ? detailPlan.active : false,
      is_promotion: detailPlan ? detailPlan.is_promotion : false,
      defaultPlan: detailPlan ? detailPlan.default : false,
      period: detailPlan ? detailPlan.period : '',
      open: false,
      title: '',
      content: '',
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { detailPlan } = this.props;
    if (nextProps.detailPlan !== detailPlan) {
      const { detailPlan } = nextProps;
      this.setState({
        name: detailPlan ? detailPlan.name : '',
        price: detailPlan ? detailPlan.price : '',
        disc: detailPlan ? detailPlan.disc : '',
        active: detailPlan ? detailPlan.active : false,
        is_promotion: detailPlan ? detailPlan.is_promotion : false,
        defaultPlan: detailPlan ? detailPlan.default : false,
        period: detailPlan ? detailPlan.period : '',
      });
    }
  }

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSaveProfile = async () => {
    const {
      name,
      price,
      disc,
      active,
      is_promotion,
      defaultPlan,
    } = this.state;
    const { match, updatePlan } = this.props;
    const { id } = match.params;
    updatePlan({
      variables: {
        input: {
          id,
          name,
          price,
          disc,
          active,
          is_promotion,
          default: defaultPlan,
        },
      },
      refetchQueries: [{ query: getPlanById, variables: { id } }, { query: getAllPlans }],
    }).then(() => {
      this.setState({
        edit: false, open: true, content: 'Completed', title: 'Update Plan',
      });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', title: 'Erros Update Plan' });
      }
    });
  }


  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit,
      name,
      price,
      active,
      is_promotion,
      period,
      defaultPlan,
      title,
      content,
      open,
    } = this.state;
    let { disc } = this.state;
    disc = parseFloat(disc);
    return (
      <div>
        <GridContainer>
          <Dialog
            title={title}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit Plan' : 'Detail Plan'}</h4>
                { edit ? <p className={classes.cardCategoryWhite}>Complete Plan</p> : null }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: name || '',
                        name: 'name',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>

                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Price"
                      id="price"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: price || '',
                        name: 'price',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Period"
                      id="period"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: period || '',
                        name: 'period',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Disc (%)"
                      id="disc"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: disc || '',
                        name: 'disc',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.boxCheck}>
                      <GridContainer>
                        <Typography component="p" className={classes.textDisable}>Active</Typography>
                        <Checkbox
                          checked={active}
                          onChange={() => this.setState({ active: !active })}
                          disabled={!edit}
                        />
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.boxCheck}>
                      <GridContainer>
                        <Typography component="p" className={classes.textDisable}>Default Plan</Typography>
                        <Checkbox
                          checked={defaultPlan}
                          onChange={() => this.setState({ defaultPlan: !defaultPlan })}
                          disabled={!edit}
                        />
                      </GridContainer>
                    </div>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <div className={classes.boxCheck}>
                      <GridContainer>
                        <Typography component="p" className={classes.textDisable}>Promotion</Typography>
                        <Checkbox
                          checked={is_promotion}
                          onChange={() => this.setState({ is_promotion: !is_promotion })}
                          disabled={!edit}
                        />
                      </GridContainer>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardBody>
              {
                edit
                  ? (
                    <CardFooter>
                      <Button className={classes.button} onClick={this.onSaveProfile}>Update Plan</Button>
                    </CardFooter>
                  ) : null
              }
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Plan</h6>
                <h4 className={classes.cardTitle}>{name ? name.toUpperCase() : ''}</h4>
                <Button round className={classes.button} onClick={this.onEdit}>
                  <Typography component="p" className={classes.text}>{!edit ? 'Edit' : 'Cancel'}</Typography>
                  <FaEdit color="#ffffff" size={16} />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  detailPlan: {},
  match: {},
  loading: false,
  updatePlan: () => {},

};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  detailPlan: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool,
  updatePlan: PropTypes.func,
};

// Mark: Map data grapql to props
const DetailPlan = graphql(getPlanById, {
  props: ({ data }) => ({
    loading: data.loading,
    detailPlan: data.getPlanById,
  }),
  options: (props) => {
    return ({ variables: { id: Number(props.match.params.id) }, fetchPolicy: 'cache-and-network' });
  },
});

const Update = graphql(updatePlan, { name: 'updatePlan' });

export default withStyles(styles)(compose(DetailPlan, Update)(Detail));
