import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';
// material
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Search from '@material-ui/icons/Search';

import { FaEdit, FaPlus, FaFileExport } from 'react-icons/fa';
import { MdCheckCircle, MdRemoveCircle } from 'react-icons/md';

import { changeStatus } from './mutation';
import { getAllPromotions } from './queries';
import { Dialog } from '../../../../components';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import styles from './styles';
import { colors } from '../../../../constants';
import config from '../../../../config';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.parimary,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);


class TablePromotion extends Component {
  state={
    open: false,
    title: '',
    content: '',
    search: '',
    page: 0,
    limit: 10,
  }

  onChangeStatus = (row) => {
    const { id } = row;
    const { changeStatus } = this.props;
    changeStatus({
      variables: {
        id,
      },
      refetchQueries: [{ query: getAllPromotions }],
    }).then((res) => {
      if (res) this.setState({ open: true, content: 'Completed', title: `${row.active ? 'Active' : 'Deactive'} Promotion Code` });
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: graphQLErrors[0].message, title: `Erros ${row.active ? 'Active' : 'Deactive'} Promotion Code` });
      }
    });
  }

  onOk = () => {
    this.setState({ open: false });
  }

  onSearch = (event) => {
    this.setState({ search: event.target.value });
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ limit: event.target.value });
  }

  onExportFile = () => {
    window.open(`${config.uri}/download-promo-sales`);
  }

  render() {
    const { classes, getAllPromotions } = this.props;
    let data = getAllPromotions || [];
    data = _.orderBy(data, obj => obj.id, 'desc');
    const {
      title, content, open, search, page, limit,
    } = this.state;
    if (search) data = _.filter(data, obj => obj.code.toLowerCase().match(search.toLowerCase()));
    return (
      <div>
        <Dialog
          title={title}
          content={content}
          open={open}
          onOk={() => this.onOk()}
        />
        <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: `${classes.margin} ${classes.search}`,
            }}
            inputProps={{
              value: search,
              onChange: this.onSearch,
              placeholder: 'Search',
              inputProps: {
                'aria-label': 'Search',
              },
            }}
          />
          <Button aria-label="edit" disabled>
            <Search />
          </Button>
          <Link to={{ pathname: '/addPromotionCode' }}>
            <Button aria-label="Add">
              <FaPlus color={colors.parimary} size={16} />
            </Button>
          </Link>
          <Button variant="contained" color="primary" className={classes.button} onClick={this.onExportFile}>
            <Typography component="p" className={classes.text}>Export</Typography>
            <FaFileExport color="#ffffff" size={16} />
          </Button>
        </div>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>Code</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Description</CustomTableCell>
                <CustomTableCell align="right" className={classes.columnRow}>Active</CustomTableCell>
                <CustomTableCell align="right" className={classes.rowEdit}>Edit</CustomTableCell>
                <CustomTableCell align="center" className={classes.rowEdit}>Active/Deactive</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.slice(page * limit, page * limit + limit).map((row) => {
                return (
                  <TableRow className={classes.row} key={row.id}>
                    <CustomTableCell component="th" scope="row">
                      {row.code}
                    </CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>{row.description}</CustomTableCell>
                    <CustomTableCell align="right" className={classes.colTable}>
                      {
                        row.active ? <MdCheckCircle color={colors.dark} size={16} /> : <MdRemoveCircle color={colors.red} size={16} />
                      }
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      <Link to={{ pathname: `/detailPromotionCode/${row.id}` }}>
                        <Button variant="contained" color="primary" className={classes.button}>
                          <Typography component="p" className={classes.text}>Edit</Typography>
                          <FaEdit color="#ffffff" size={16} />
                        </Button>
                      </Link>
                    </CustomTableCell>
                    <CustomTableCell align="center" className={classes.columCenter}>
                      {
                        !row.active
                          ? (
                            <Button variant="contained" color="primary" className={classes.buttonActive} onClick={() => this.onChangeStatus(row)}>
                              <Typography component="p" className={classes.text}>Activate</Typography>
                            </Button>
                          )
                          : (
                            <Button variant="contained" color="primary" className={classes.buttonDeactive} onClick={() => this.onChangeStatus(row)}>
                              <Typography component="p" className={classes.text}>Deactivate</Typography>
                            </Button>
                          )
                      }
                    </CustomTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={data.length}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// Mark: default props
TablePromotion.defaultProps = {
  classes: {},
  getAllPromotions: [],
  changeStatus: () => {},
};

// Mark: PropsType
TablePromotion.propTypes = {
  classes: PropTypes.object,
  getAllPromotions: PropTypes.array,
  changeStatus: PropTypes.func,
};

// Mark: Map data grapql to props
const ListAll = graphql(getAllPromotions, {
  props: ({ data }) => ({
    loading: data.loading,
    getAllPromotions: data.getAllPromotions,
  }),
});

const ChangeStatus = graphql(changeStatus, { name: 'changeStatus' });

export default withStyles(styles)(compose(ListAll, ChangeStatus)(TablePromotion));
