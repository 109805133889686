import gql from 'graphql-tag';

export const getCronJobById = gql`
  query getCronJobById($id: Int!){
    getCronJobById(id: $id){
      id
      cronkey
      schedule
      testSchedule
      interval
      testInterval
      active
      order
    }
  }
`;
