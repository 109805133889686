import gql from 'graphql-tag';

export const getCurrentAdmin = gql`
  query getCurrentAdmin{
    getCurrentAdmin{
      id
      avatar
      username
      email
      location
      telephone
      firstName
      lastName
      role
    }
  }
`;
